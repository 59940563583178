import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss'],
})
export class LeftSidebarComponent implements OnInit {
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();

  constructor(
    public routes: ActivatedRoute,
    private router: Router,
    public appService: AppService
  ) {
    if (this.appService.userDetail != null) {
      // this.as.setSideBarState(window.location.href.split('/'));
    } else {
      // this.router.navigate(['/layout/course']);
    }
  }
  ngOnInit(): void {}
  gotoLogin() {
    this.closeSideForm.emit('');
    this.router.navigate(['/login'],{queryParamsHandling: 'preserve'});
  }
  gotoSignup() {
    this.closeSideForm.emit('');
    this.router.navigate(['/signup'],{queryParamsHandling: 'preserve'});
  }
  gotoMyLearning() {
    this.closeSideForm.emit('');
    this.router.navigate(['/my-course'],{queryParamsHandling: 'preserve'});
  }
  gotoMyProfile() {
    this.closeSideForm.emit('');

    this.router.navigate(['/profile'],{queryParamsHandling: 'preserve'});
  }
  gotoAllCourse() {
    this.router.navigate(['/course'],{ queryParamsHandling: 'preserve' });
  }
  gotoMyNotification() {
    this.closeSideForm.emit('');

    this.router.navigate(['/notification'],{queryParamsHandling: 'preserve'});
  }
  gotoComponent(route: any, label: any) {
    this.router.navigate([route],{queryParamsHandling: 'preserve'});
  }
  closeSideBarManually() {
    this.closeSideForm.emit('');
  }
  logoutButtonHandler(event: any) {
    //console.log('Logout Clicked');
    localStorage.removeItem('TG-USER-DASHBOARD');
    this.appService.userDetail = null;
    this.appService.listOfMyCourse = [];
    this.appService.listOfCourse.forEach((element:any) => {
      element['isEnrolled']=false;
    });
    this.router.navigate(['/course'],{ queryParamsHandling: 'preserve' });

    this.closeSideForm.emit('');
  }

  gotoLink(link: any) {
    window.open(link, '_blank');
  }
}
