import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Lightbox } from 'ngx-lightbox';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
// import { LightboxModule } from 'ngx-lightbox';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  listOfNotification: any = [];
  userDetail: any;
  isLoading: boolean = true;
  private _album: any = [];
  constructor(
    public routes: ActivatedRoute,
    private _lightbox: Lightbox,
    private router: Router,
    public appService: AppService,
    private toastr: ToastrService
  ) {
    this.userDetail = JSON.parse(localStorage.getItem('TG-USER-DASHBOARD')!);
    if (this.userDetail != null) {
    } else {
      this.router.navigate(['/course'],{queryParamsHandling: 'preserve'});
    }
  }
  ngOnInit(): void {
    this.fetchAllNotification();
  }

  async fetchAllNotification() {
    this.isLoading = true;
    this.listOfNotification = [];
    // let startDate = new Date();
    // let enddate = new Date(new Date().setDate(new Date().getDate() - 7));
    // let start = new Date(startDate.setHours(0, 0, 0, 0));
    // let end = new Date(enddate.setHours(23, 59, 59, 59));
    let param = {
      // userManualId: this.userDetail['uid'].toString(),
      // startDate: end,
      // endDate: start,
    };
    try {
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.appService.userDetail['accessToken']}`,
      });
      let URL = `${
        webApi.domain + webApi.v2EndPoint.fetchUserNotifcationForUserDashboard
      }`;
      await this.appService
        .postMethodWithToken(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.listOfNotification = data['result'];
            this.listOfNotification.forEach((notification: any) => {
              if (notification['course'] == null) {
                const album = {
                  src: notification['notification']['course']['courseImageUrl'],
                  caption: String(
                    notification['notification']['course']['courseImageUrl']
                  ).split('/')[
                    String(notification['notification']['course']['courseImageUrl']).split('/')
                      .length - 1
                  ],
                  thumb: notification['notification']['course']['courseImageUrl'],
                };

                this._album.push(album);
              } else {
                const album = {
                  src: notification['course']['courseImageUrl'],
                  caption: String(
                    notification['course']['courseImageUrl']
                  ).split('/')[
                    String(notification['course']['courseImageUrl']).split('/')
                      .length - 1
                  ],
                  thumb: notification['course']['courseImageUrl'],
                };

                this._album.push(album);
              }
            });
            //console.log(this.listOfNotification);
            this.isLoading = false;
          } else {
            this.listOfNotification = [];
            this.isLoading = false;
          }
        });
    } catch (error) {
      this.listOfNotification = [];
      this.isLoading = false;
    }
  }
  async updateNotificationSeen(notification: any) {
    let param = {
      // userManualId: this.userDetail['uid'],
      notificationId: notification['_id'],
      // type: notification['type'],
    };
    try {
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.appService.userDetail['accessToken']}`,
      });
      let URL = `${webApi.domain + webApi.v2EndPoint.updateViewState}`;
      await this.appService
        .putMethodWithToken(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.listOfNotification.forEach((not: any) => {
              if (not['_id'] == notification['_id']) {
                not['isViewed'] = true;
              }
            });
          } else {
          }
        });
    } catch (error) {}
  }
  open(index: number, notification: any): void {
    this._lightbox.open(this._album, index);
    this.updateNotificationSeen(notification);
  }

  close(): void {
    this._lightbox.close();
  }
}
