import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-course-list',
  templateUrl: './course-list.component.html',
  styleUrls: ['./course-list.component.scss'],
})
export class CourseListComponent implements OnInit {
  listOfQuiz: any = [];
  listOfMyCourse: any = [];
  listOfCourse: any = [];
  listOfCourseToDisplay: any = [];
  isLoading: boolean = true;
  isFreeCourseSeeMore: boolean = false;
  isPaidCourseSeeMore: boolean = false;
  isQuizSeeMore: boolean = false;
  imgStyle: any = {
    padding: '0.5rem',
    'border-radius': '16px',
    height: '170px',
    cursor: 'pointer',
  };
  cardStyle: any = {
    'border-radius': '16px',
    width: '290px',
    // cursor: 'pointer',
  };
  constructor(
    public routes: ActivatedRoute,
    private router: Router,
    private appService: AppService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    if (this.appService.userDetail == null) {
      this.fetchAllCourse();
    } else {
      if (this.appService.listOfMyCourse.length > 0) {
        this.listOfMyCourse = this.appService.listOfMyCourse;
        this.assignData();
      } else {
        this.fetchAllEnrolledCourse2();
      }
    }
  }
  assignData() {
    if (this.appService.listOfQuiz.length > 0) {
      this.listOfQuiz = this.appService.listOfQuiz;
    }
    if (this.appService.listOfCourse.length <= 0) {
      this.fetchAllCourse();
    } else {
      this.listOfCourse = this.appService.listOfCourse;
      this.listOfCourse.forEach((course: any) => {
        course['isEnrolled'] = false;
      });
      this.listOfCourse.forEach((course: any) => {
        this.appService.listOfMyCourse.forEach((myCourse: any) => {
          if (course['_id'] == myCourse['_id']) {
            course['isEnrolled'] = true;
          }
        });
      });
      this.listOfCourseToDisplay = this.listOfCourse;
      this.isLoading = false;
    }
  }
  ngOnChanges(changes: any) {
    if (this.appService.userDetail == null) {
      this.fetchAllCourse();
    } else {
      if (this.appService.listOfMyCourse.length > 0) {
        this.listOfMyCourse = this.appService.listOfMyCourse;
        this.assignData();
      } else {
        this.fetchAllEnrolledCourse2();
      }
    }
  }
  async fetchAllCourse() {
    let param = {};
    try {
      let URL = `${webApi.domain + webApi.v2EndPoint.fetchCoursesForUserDashBoard
        }`;
      await this.appService.postMethod(param, URL).then((data: any) => {
        if (data['success']) {
          this.listOfCourse = data['result'];
          this.listOfCourse.forEach((course: any) => {
            course['isEnrolled'] = false;
          });
          if (this.listOfMyCourse.length > 0) {
            this.listOfCourse.forEach((course: any) => {
              this.listOfMyCourse.forEach((myCourse: any) => {
                if (course['_id'] == myCourse['_id']) {
                  course['isEnrolled'] = true;
                }
              });
            });
          }
          //console.log(this.listOfCourse);
          this.listOfCourseToDisplay = this.listOfCourse;
          this.appService.listOfCourse = this.listOfCourse;
          if (
            this.appService.userDetail != null &&
            this.listOfMyCourse.length <= 0
          ) {
            this.fetchAllEnrolledCourse1();
          } else {
            this.fetchAllQuiz();
          }
        } else {
          this.listOfCourse = [];
          this.listOfCourseToDisplay = this.listOfCourse;
          if (
            this.appService.userDetail != null &&
            this.listOfMyCourse.length <= 0
          ) {
            this.fetchAllEnrolledCourse1();
          } else {
            this.fetchAllQuiz();
          }
        }
      });
    } catch (error) {
      this.listOfCourse = [];
      this.listOfCourseToDisplay = this.listOfCourse;
      if (
        this.appService.userDetail != null &&
        this.listOfMyCourse.length <= 0
      ) {
        this.fetchAllEnrolledCourse1();
      } else {
        this.fetchAllQuiz();
      }
    }
  }

  async fetchAllEnrolledCourse1() {
    this.isLoading = true;
    this.appService.fetchEnrollCalled = true;

    let param = {
      // userManualId: this.appService.userDetail['uid'],
    };
    try {
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.appService.userDetail['accessToken']}`,
      });
      let URL = `${webApi.domain + webApi.v2EndPoint.fetchEnrolledCourseForUserDashboard
        }`;
      await this.appService
        .postMethodWithToken(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.appService.listOfMyCourse = data['result'];
            this.appService.listOfMyCourse.forEach((course: any) => {
              course['isEnrolled'] = true;
            });
            //console.log(this.appService.listOfMyCourse);
            this.listOfCourse.forEach((course: any) => {
              course['isEnrolled'] = false;
            });
            if (this.listOfMyCourse.length > 0) {
              this.listOfCourse.forEach((course: any) => {
                this.listOfMyCourse.forEach((myCourse: any) => {
                  if (course['_id'] == myCourse['_id']) {
                    course['isEnrolled'] = true;
                  }
                });
              });
            }
            this.fetchAllQuiz();
          } else {
            this.appService.listOfMyCourse = [];
            this.fetchAllQuiz();
          }
        });
    } catch (error) {
      this.appService.listOfMyCourse = [];
      this.fetchAllQuiz();
    }
  }
  async fetchAllEnrolledCourse2() {
    this.isLoading = true;
    this.appService.fetchEnrollCalled = true;

    let param = {
      // userManualId: this.appService.userDetail['uid'],
    };
    try {
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.appService.userDetail['accessToken']}`,
      });
      let URL = `${webApi.domain + webApi.v2EndPoint.fetchEnrolledCourseForUserDashboard
        }`;
      await this.appService
        .postMethodWithToken(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.appService.listOfMyCourse = data['result'];
            this.appService.listOfMyCourse.forEach((course: any) => {
              course['isEnrolled'] = true;
            });
            //console.log(this.appService.listOfMyCourse);
            this.assignData();
          } else {
            this.appService.listOfMyCourse = [];
            this.assignData();
          }
        });
    } catch (error) {
      this.appService.listOfMyCourse = [];
      this.assignData();
    }
  }
  async fetchAllQuiz() {
    this.isLoading = true;
    this.appService.fetchEnrollCalled = true;

    let param = {
      quizId: '0',
    };
    try {
      let URL = `${webApi.domain + webApi.v2EndPoint.fetchAllQuizForUserDashboard
        }`;
      await this.appService.postMethod(param, URL).then((data: any) => {
        if (data['success']) {
          this.listOfQuiz = data['result'];
          this.appService.listOfQuiz = data['result'];

          this.isLoading = false;
        } else {
          this.listOfQuiz = [];
          this.appService.listOfQuiz = [];
          this.isLoading = false;
        }
      });
    } catch (error) {
      this.listOfQuiz = [];
      this.appService.listOfQuiz = [];
      this.isLoading = false;
    }
  }

  paidCourseSeeMore() {
    this.isPaidCourseSeeMore = !this.isPaidCourseSeeMore;
  }
  freeCourseSeeMore() {
    this.isFreeCourseSeeMore = !this.isFreeCourseSeeMore;
  }
  quizSeeMore() {
    this.isQuizSeeMore = !this.isQuizSeeMore;
  }

  getDuration(minutes: number) {
    let hour = 0;
    while (minutes >= 60) {
      hour++;
      minutes = minutes - 60;
    }
    if (hour > 0) {
      return `${hour}H ${minutes.toFixed(2)}M`;
    } else {
      return `${minutes.toFixed(2)}M`;
    }
  }

  getCourseList(type: string) {
    let courseList: any = [];
    if (type == 'Free') {
      this.listOfCourse.forEach((course: any) => {
        if (course['courseType'] == 'Free' && course['isActive']) {
          courseList.push(course);
        }
      });
    } else {
      // if (this.appService.userDetail != null) {
      let showAll = false;
      this.listOfMyCourse.forEach((myCourse: any) => {
        if (
          parseInt(myCourse['coursePrice']) >= 999 &&
          myCourse['courseType'] == 'Paid'
        ) {
          showAll = true;
        }
      });
      if (showAll) {
        this.listOfCourse.forEach((course: any) => {
          if (course['courseType'] == 'Paid' && course['isActive']) {
            courseList.push(course);
          }
        });
      } else {
        this.listOfCourse.forEach((course: any) => {
          if (
            (course['courseType'] == 'Paid' && course['isActive'] && 
              course['courseSlug'] == 'money-making-trading-course') || (course['courseType'] == 'Paid' &&
                course['courseSlug'] == 'mmtc-mmoc') || (course['courseType'] == 'Paid' &&
                  course['courseSlug'] == 'money-making-option-course') || (course['courseType'] == 'Paid' &&
                    course['courseSlug'] == 'mmtc-v3')
            // parseInt(course['coursePrice']) < 1000
          ) {
            courseList.push(course);
          }
        });
      }
      // } else {
      //   this.listOfCourse.forEach((course: any) => {
      //     if (course['courseType'] == 'Paid') {
      //       courseList.push(course);
      //     }
      //   });
      // }
    }
    return courseList;
  }
  gotoQuizDetail(quiz: any) {
    this.appService.selectedQuiz = quiz;
    this.router.navigate(['/quiz-detail'], {
      queryParams: { quizId: quiz['_id'] }, queryParamsHandling: 'merge'
    });
  }
  gotoCourseDetail(course: any) {
    if (course['isEnrolled']) {
      this.appService.selectedCourseToView = course;
      this.router.navigate(['/course-content'], {
        queryParams: { name: course['courseSlug'] }, queryParamsHandling: 'merge'
      });
    } else {
      this.appService.selectedCourse = course;
      this.router.navigate(['/course-detail'], {
        queryParams: { name: course['courseSlug'] }, queryParamsHandling: 'merge'
      });
    }
  }
  courseDetail(course: any) {
    this.appService.selectedCourse = course;
    this.router.navigate(['/course-detail'], {
      queryParams: { name: course['courseSlug'] }, queryParamsHandling: 'merge'
    });
  }
}
