<div *ngIf="isLoading" class="loader-div">
    <div class="loader">
    </div>
</div>
<div *ngIf="!isLoading">
    <!-- <router-outlet></router-outlet> -->
    <div>
        <app-header>

            <!-- Main Content Start-->

            <router-outlet mainContent></router-outlet>


            <!-- router-outlet -->
            <!-- Main Content End-->


            <!-- Footer Start -->

            <!-- <div>
                <app-footer footer></app-footer>
            </div> -->

            <!-- Footer End -->


        </app-header>
    </div>
</div>
<div class="whatsApp">
    <a href="http://wa.me/919152615121?text=Customer Support"  target="_blank">
        <img src="../assets/imgs/whatsapp.png" style="height: 75%;width: 75%;" alt="" srcset="">
    </a>
</div>