import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-quiz-alert-dialog',
  templateUrl: './quiz-alert-dialog.component.html',
  styleUrls: ['./quiz-alert-dialog.component.scss']
})
export class QuizAlertDialogComponent implements OnInit {

  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();
  constructor(
    public dialogRef: MatDialogRef<QuizAlertDialogComponent>,
    private toastr: ToastrService,
    public as: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    dialogRef.disableClose = true;
  }
  ngOnInit(): void {}
  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }
  changeActivity() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Change Activity',
    });
  }
  submitQuiz() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Submit Quiz',
    });
  }

}
