<div *ngIf="isLoading" class="loader-div">
    <div class="loader">
    </div>
</div>
<div class="course-play">
    <div class="course-play-wrap">
        <div class="course-play-left">
            <div class="main-video-play">
                <div class="course-video">
                    <div
                        *ngIf="!isScreenLoaded || selectedActivityDetail['type']=='Video' && !selectedActivityDetail['videoContent']['link'].includes('youtube.com')">
                        <div *ngIf="selectedActivityDetail!=null && selectedActivityDetail!=undefined">
                            <video id="networkplayer"
                                *ngIf="(selectedActivityDetail['videoContent'].path!=null && selectedActivityDetail['videoContent'].path!='' && selectedActivityDetail['videoContent'].path!=undefined) && (selectedActivityDetail['videoContent'].domain!=null && selectedActivityDetail['videoContent'].domain!=undefined &&selectedActivityDetail['videoContent'].domain!='')"
                                #videoPlayer class="video" oncontextmenu="return false;" controls
                                controlsList="nodownload"></video>
                        </div>
                        <!--  -->
                        <div *ngIf="selectedActivityDetail!=null && selectedActivityDetail!=undefined">

                            <video id="normalplayer"
                                *ngIf="(selectedActivityDetail['videoContent'].path==null || selectedActivityDetail['videoContent'].path=='' || selectedActivityDetail['videoContent'].path==undefined) || (selectedActivityDetail['videoContent'].domain==null || selectedActivityDetail['videoContent'].domain=='' || selectedActivityDetail['videoContent'].domain==undefined)"
                                class="video" oncontextmenu="return false;" controls controlsList="nodownload"
                                [src]="selectedActivityLink" autoplay>
                                Your browser does not support the video tag.
                            </video>
                        </div>

                    </div>

                    <div
                        *ngIf="!isScreenLoaded || selectedActivityDetail['type']=='Video' && selectedActivityDetail['videoContent']['link'].includes('youtube.com')">
                        <div *ngIf="selectedActivityDetail!=null && selectedActivityDetail!=undefined">
                            <!-- <video id="networkplayer"
                                *ngIf="(selectedActivityDetail['videoContent'].path!=null && selectedActivityDetail['videoContent'].path!='' && selectedActivityDetail['videoContent'].path!=undefined) && (selectedActivityDetail['videoContent'].domain!=null && selectedActivityDetail['videoContent'].domain!=undefined &&selectedActivityDetail['videoContent'].domain!='')"
                                #videoPlayer class="video" oncontextmenu="return false;" controls
                                controlsList="nodownload"></video> -->
                            <iframe class="video"
                                [src]="getYoutubeVideoSafeUrl(selectedActivityDetail['videoContent']['link'])"
                                frameborder="0" allowfullscreen
                                [title]="selectedActivityDetail['videoContent']['link']"></iframe>
                        </div>
                    </div>
                    <div *ngIf="!isLoading && selectedActivityDetail['type']!='Video'">
                        <!-- Code of QUIZ Activity START -->

                        <div class="quiz-comp">
                            <app-quiz [componentChanged]="isOtherActivityComponentCalled"
                                (componentCloseAction)="quizComponentCloseHandler($event)"
                                [activityDetail]="selectedActivityDetail" [courseDetail]="courseDetail"
                                [moduleDetail]="selectedModuleDetail"></app-quiz>
                        </div>

                        <!-- Code of QUIZ Activity END-->
                    </div>
                </div>
                <div class="video-detail" *ngIf="!isLoading">
                    <div class="course-content-wrap-mobile">
                        <mat-tab-group mat-stretch-tabs>
                            <mat-tab label="Course Content">
                                <app-side-course-content [selectedActivity]="selectedActivity"
                                    [selectedModule]="selectedModule"
                                    (activityCompleteTapHandler)="activityCompleteTapHandler($event)"
                                    (activityTapHandler)="activityTapHandler($event)"
                                    (moduleTapHandler)="moduleTapHandler($event)" postion="bottom"
                                    *ngFor="let module of courseDetail['modules']" [moduleDetail]="module">
                                </app-side-course-content>
                                <div style="height: 5em;">

                                </div>
                            </mat-tab>
                            <mat-tab label="Overview">
                                <div class="overview">
                                    <h3 style="margin-bottom: 1em;">About This Course</h3>
                                    <span> {{courseDetail['courseName']}}</span>
                                </div>
                                <div style="border-top: solid 1px grey;"></div>
                                <div class="overview">
                                    <div class="description">
                                        <h3 style="font-size: 17px;">Cerificate</h3>
                                        <p style="white-space: pre-wrap;">Get Appreciation certificate by completing
                                            entire course
                                        </p>
                                        <div style="margin-top:1em;">

                                            <app-button (callBackFunction)="getCertificate()" text="Get Certificate"
                                                shape="rectangular" type="outline" [bgColor]="'#000'"></app-button>
                                        </div>
                                    </div>
                                </div>
                                <div style="border-top: solid 1px grey;"></div>

                                <div class="overview">
                                    <h3>Instructor</h3>
                                    <div>
                                        <app-instructor [authorDetail]="courseDetail['authorId']"></app-instructor>
                                    </div>
                                </div>


                            </mat-tab>
                            <mat-tab label="Link & Attachments">
                                <div class="course-notes">
                                    <h3 style="margin-bottom: 1em;margin-top: 1em;">
                                        {{selectedActivityDetail['name']}}</h3>
                                    <div class="course-notes-wrap">
                                        <h3 style="margin-bottom: 1em;">
                                            Attachments</h3>
                                        <div
                                            *ngIf=" (selectedActivityDetail['resources']==undefined || (selectedActivityDetail['resources']==undefined?true:selectedActivityDetail['resources'].length<=0))">
                                            No Attachments available.
                                        </div>
                                        <div *ngFor="let resource of selectedActivityDetail['resources']"
                                            class="display-flex-space-between" style="margin-top: 1em;">
                                            <div class="course-notes-left">
                                                <div class="timer">
                                                    <img style="height: 20px;width: 20px; cursor: pointer;"
                                                        src="../../../assets/imgs/document.png" alt="" srcset="">
                                                </div>
                                                <div>
                                                    <span class="notes-topic">{{resource['resourceName']}}</span>
                                                </div>
                                            </div>
                                            <div class="course-notes-right">
                                                <a [href]="resource['resourceLink']" target="_blank"> <img
                                                        style="height: 20px;width: 20px; cursor: pointer;"
                                                        src="../../../assets/imgs/view.png" alt="" /></a>
                                                <img (click)="downloadResource(resource['resourceLink'])"
                                                    style="height: 20px;width: 20px; cursor: pointer;"
                                                    src="../../../assets/imgs/download.png" alt="" />
                                            </div>
                                        </div>

                                        <h3 style="margin-bottom: 1em;margin-top: 1em;">
                                            Links</h3>
                                        <div
                                            *ngIf="(selectedActivityDetail['description']==undefined || (selectedActivityDetail['description']==undefined?true:selectedActivityDetail['description'].length<=0))">
                                            No Links available.
                                        </div>
                                        <div *ngFor="let link of selectedActivityDetail['description']"
                                            class="display-flex-space-between" style="margin-top: 1em;">
                                            <div class="course-notes-left">
                                                <div class="timer">
                                                    <img style="height: 20px;width: 20px; cursor: pointer;"
                                                        src="../../../assets/imgs/link.png" alt="" srcset="">
                                                </div>
                                                <div>
                                                    <p style="white-space: pre-wrap;" [innerHtml]="link"></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                    <div class="course-content-wrap-desktop">
                        <mat-tab-group mat-stretch-tabs>

                            <mat-tab label="Overview">
                                <div class="overview">
                                    <h3 style="margin-bottom: 1em;">About This Course</h3>
                                    <span> {{courseDetail['courseName']}}</span>
                                </div>
                                <div style="border-top: solid 1px grey;"></div>
                                <div class="overview">
                                    <div class="description">
                                        <h3 style="font-size: 17px;">Cerificate</h3>
                                        <p style="white-space: pre-wrap;">Get Appreciation certificate by completing
                                            entire course
                                        </p>
                                        <div style="margin-top:1em;">

                                            <app-button (callBackFunction)="getCertificate()" text="Get Certificate"
                                                shape="rectangular" type="outline" [bgColor]="'#000'"></app-button>
                                        </div>
                                    </div>
                                </div>
                                <div style="border-top: solid 1px grey;"></div>
                                <div class="overview">
                                    <h3>Instructor</h3>
                                    <div>
                                        <app-instructor [authorDetail]="courseDetail['authorId']"></app-instructor>
                                    </div>
                                </div>
                            </mat-tab>
                            <mat-tab label="Link & Attachments">
                                <div class="course-notes">
                                    <h3 style="margin-bottom: 1em;margin-top: 1em;">
                                        {{selectedActivityDetail['name']}}</h3>
                                    <div class="course-notes-wrap">
                                        <h3 style="margin-bottom: 1em;">
                                            Attachments</h3>
                                        <div
                                            *ngIf="(selectedActivityDetail['resources']==undefined ||(selectedActivityDetail['resources']==undefined?true:selectedActivityDetail['resources'].length<=0))">
                                            No Attachments available.
                                        </div>
                                        <div *ngFor="let resource of selectedActivityDetail['resources']"
                                            class="display-flex-space-between" style="margin-top: 1em;">
                                            <div class="course-notes-left">
                                                <div class="timer">
                                                    <img style="height: 20px;width: 20px; cursor: pointer;"
                                                        src="../../../assets/imgs/document.png" alt="" srcset="">
                                                </div>
                                                <div>
                                                    <span class="notes-topic">{{resource['resourceName']}}</span>
                                                </div>
                                            </div>
                                            <div class="course-notes-right">
                                                <a [href]="resource['resourceLink']" target="_blank"> <img
                                                        style="height: 20px;width: 20px; cursor: pointer;"
                                                        src="../../../assets/imgs/view.png" alt="" /></a>
                                                <img (click)="downloadResource(resource['resourceLink'])"
                                                    style="height: 20px;width: 20px; cursor: pointer;"
                                                    src="../../../assets/imgs/download.png" alt="" />
                                            </div>
                                        </div>

                                        <h3 style="margin-bottom: 1em;margin-top: 1em;">
                                            Links</h3>
                                        <div
                                            *ngIf=" (selectedActivityDetail['description']==undefined ||(selectedActivityDetail['description']==undefined?true:selectedActivityDetail['description'].length<=0))">
                                            No Links available.
                                        </div>
                                        <div *ngFor="let link of selectedActivityDetail['description']"
                                            class="display-flex-space-between" style="margin-top: 1em;">
                                            <div class="course-notes-left">
                                                <div class="timer">
                                                    <img style="height: 20px;width: 20px; cursor: pointer;"
                                                        src="../../../assets/imgs/link.png" alt="" srcset="">
                                                </div>
                                                <div>
                                                    <p style="white-space: pre-wrap;" [innerHtml]="link"></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                    <div style="height: 5em;">

                    </div>
                </div>
            </div>
        </div>
        <div class="course-play-right" *ngIf="!isLoading">
            <div class="course-play-right-wrapper">
                <app-side-course-content [selectedActivity]="selectedActivity" [selectedModule]="selectedModule"
                    (activityTapHandler)="activityTapHandler($event)" (moduleTapHandler)="moduleTapHandler($event)"
                    postion="right" *ngFor="let module of courseDetail['modules']" [moduleDetail]="module">
                </app-side-course-content>
                <div style="height: 5em;">

                </div>
            </div>
        </div>
    </div>

</div>