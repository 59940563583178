import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppService } from './app.service';
import { LayoutModule } from './layout/layout.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AlertComponent } from './layout/component/alert/alert.component';
import { AuthFormComponent } from './layout/component/auth-form/auth-form.component';
import { AvatarComponent } from './layout/component/avatar/avatar.component';
import { BadgeComponent } from './layout/component/badge/badge.component';
import { BarChartComponent } from './layout/component/bar-chart/bar-chart.component';
import { BasicTableComponent } from './layout/component/basic-table/basic-table.component';
import { ButtonGroupComponent } from './layout/component/button-group/button-group.component';
import { ButtonComponent } from './layout/component/button/button.component';
import { CardComponent } from './layout/component/card/card.component';
import { DataTableComponent } from './layout/component/data-table/data-table.component';
import { DropdownComponent } from './layout/component/dropdown/dropdown.component';
import { FormChecksComponent } from './layout/component/form-checks/form-checks.component';
import { FormGroupComponent } from './layout/component/form-group/form-group.component';
import { IconComponent } from './layout/component/icon/icon.component';
import { InputGroupComponent } from './layout/component/input-group/input-group.component';
import { PieChartComponent } from './layout/component/pie-chart/pie-chart.component';
import { TagComponent } from './layout/component/tag/tag.component';
import { ContentComponent } from './layout/content/content.component';
import { CourseContentComponent } from './layout/course-content/course-content.component';
import { CourseDetailComponent } from './layout/course-detail/course-detail.component';
import { CourseListComponent } from './layout/course-list/course-list.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { InstructorComponent } from './layout/instructor/instructor.component';
import { LayoutComponent } from './layout/layout.component';
import { LeftSidebarComponent } from './layout/left-sidebar/left-sidebar.component';
import { LoginComponent } from './layout/login/login.component';
import { MyCourseComponent } from './layout/my-course/my-course.component';
import { NotificationComponent } from './layout/notification/notification.component';
import { PaymentSuccessDialogComponent } from './layout/payment-success-dialog/payment-success-dialog.component';
import { PlayDemoVideoDialogComponent } from './layout/play-demo-video-dialog/play-demo-video-dialog.component';
import { ProductComponent } from './layout/product/product.component';
import { ProfileComponent } from './layout/profile/profile.component';
import { QuizAlertDialogComponent } from './layout/quiz-alert-dialog/quiz-alert-dialog.component';
import { QuizDetailComponent } from './layout/quiz-detail/quiz-detail.component';
import { QuizComponent } from './layout/quiz/quiz.component';
import { SideCourseContentComponent } from './layout/side-course-content/side-course-content.component';
import { SignupComponent } from './layout/signup/signup.component';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { SidebarModule } from 'ng-sidebar';
import { LightboxModule } from 'ngx-lightbox';
import { ToastrModule } from 'ngx-toastr';
import { LayoutRoutingModule } from './layout/layout-routing.module';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    HeaderComponent,
    LayoutComponent,
    LeftSidebarComponent,
    FooterComponent,
    LoginComponent,
    SignupComponent,
    ContentComponent,
    InstructorComponent,
    CourseListComponent,
    CourseDetailComponent,
    CourseContentComponent,
    ProfileComponent,
    NotificationComponent,
    ProductComponent,

    //  UI Component Library

    AlertComponent,
    AvatarComponent,
    BadgeComponent,
    TagComponent,
    ButtonComponent,
    ButtonGroupComponent,
    FormGroupComponent,
    InputGroupComponent,
    FormChecksComponent,
    AuthFormComponent,
    DropdownComponent,
    IconComponent,
    CardComponent,
    BasicTableComponent,
    DataTableComponent,
    PieChartComponent,
    BarChartComponent,

    CourseListComponent,
    CourseDetailComponent,
    CourseContentComponent,
    ProfileComponent,
    NotificationComponent,
    ProductComponent,

    LoginComponent,
    SignupComponent,
    ContentComponent,
    InstructorComponent,
    SideCourseContentComponent,
    PlayDemoVideoDialogComponent,
    MyCourseComponent,
    PaymentSuccessDialogComponent,
    QuizComponent,
    QuizAlertDialogComponent,
    QuizDetailComponent,
    PaymentSuccessComponent,
  ],
  imports: [
    BrowserModule,
    LayoutModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,CommonModule,
    LayoutRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SidebarModule.forRoot(),
    ToastrModule.forRoot(),
    LightboxModule,
    MatExpansionModule,
    MatIconModule,
    MatDialogModule,
    MatTabsModule,
    MatMenuModule
  ],
  exports: [MatExpansionModule],
  providers: [AppService],
  bootstrap: [AppComponent],
})
export class AppModule {}
