<div *ngIf="isLoading" class="loader-div">
    <div class="loader">
    </div>
</div>
<div *ngIf="!isLoading" class="notification">
    <div class="notification-wrap">
        <h1 class="notification-title">Notification</h1>
        <div *ngIf="listOfNotification.length>0" class="notification-main-part">
            <div class="notification-main" *ngFor="let notification of listOfNotification;let i=index;" (click)="updateNotificationSeen(notification)" [ngStyle]="{'background-color':notification['isViewed']? '#f0fbff':'#d6f4ff'}">
                <img [src]="notification['course']==null?notification['notification']['course']['courseImageUrl'] :notification['course']['courseImageUrl']" alt="" (click)="open(i,notification)" />
                <div>
                    <div style="font-weight: 600;">{{notification['subject']==null?notification['notification']['subject'] :notification['subject']}}
                    </div>
                    <p style="white-space: pre-wrap;font-size: 13px;" [innerHtml]="notification['body']==null?notification['notification']['body'] :notification['body']">
                    </p>
                </div>

            </div>
        </div>
        <div *ngIf="listOfNotification.length==0" class="notification-main-part">
            <div class="no-notification">
                No notification available.
            </div>
        </div>
    </div>
</div>

<div class="spacer">

</div>