<div class="container">


    <div class="form-group-field" [ngStyle]="containerBgColor">
        <div *ngIf="!sendOtpClicked" class="form-group-field-wrap">
            <div class="form-group-top top" [ngStyle]="headStyle">
                <h1>Sign up</h1>
                <p style="margin-top: 1em;">it's free and easy to access</p>
            </div>
            <form class="form-group-bottom" [formGroup]="signupForm">
                <div class="form-group-input">
                    <label class="required">Full Name</label>
                    <input placeholder="Enter your Full Name" [ngStyle]="input" type="text" formControlName="fullName" />
                </div>
                <!-- <div class="form-group-input">
                    <label class="required">Last Name</label>
                    <input placeholder="Enter your Last Name" [ngStyle]="input" type="text" formControlName="lastName" />
                </div> -->
                <div class="form-group-input">
                    <label class="required">Email Id</label>
                    <input placeholder="Enter your Email Id" [ngStyle]="input" type="email" formControlName="email" />
                </div>
                <div class="form-group-input">
                    <label class="required">Phone Number</label>
                    <div class="display-flex-space-between">

                        <input style="width: 20%;" type="text" formControlName="countryCode" [ngStyle]="input" [readonly]="countryCode!=null"> <input style="width: 75%;" placeholder="Enter your Phone Number" [ngStyle]="input" type="text" [maxLength]="10"
                            formControlName="phone" [readonly]="mobileNo!=null" />
                    </div>
                </div>

                <div class="form-group-alert-message">
                    <input type="checkbox" [(ngModel)]="terms" [ngModelOptions]="{standalone: true}">
                    <label>By creating an account means you agree to the Teams and conditions, and our Privacy policy
                    </label>
                </div>
                <button (click)="createAndUpdateUser()" [ngClass]="{'btn-disabled-bgColor':signupForm.invalid || !terms,'btn-bgColor':signupForm.valid && terms}" [ngStyle]="btn" [disabled]="signupForm.invalid || !terms">{{mobileNo!=null? 'Update Detail':'Create\
                    User'}}</button>
            </form>

        </div>
        <div *ngIf="sendOtpClicked" class="form-group-field-wrap">
            <div class="form-group-top top" [ngStyle]="headStyle">
                <div style="color: blue;cursor: pointer;text-decoration: underline;" (click)="changeDetail()">
                    Back
                </div>
                <h1>Welcome, {{signupForm.get('firstName')!.value+' '+signupForm.get('lastName')!.value}}</h1>
                <p style="margin-top: 1em;">Enter OTP send on +91 {{signupForm.get('phone')!.value}}</p>
            </div>
            <form class="form-group-bottom" [formGroup]="verifyOtpForm">

                <div class="form-group-input">
                    <label class="required">Enter OTP</label>
                    <input placeholder="Enter OTP" [ngStyle]="input" type="text" formControlName="otp" [maxLength]="6" />

                </div>
                <!-- <button *ngIf="sendOtpClicked" (click)="verifyOtp()" [ngClass]="{'btn-disabled-bgColor':verifyOtpForm.invalid ,'btn-bgColor':verifyOtpForm.valid }" [ngStyle]="btn" [disabled]="verifyOtpForm.invalid ">{{'Verify OTP & Create account'}}</button>
                <div style="display: flex;justify-content: center;margin: 1em;align-items: center;">
                    Didn't received OTP? <span style="color: #5624d0;font-size: 12px;font-weight: 600;cursor: pointer;" (click)="reSendOtp()">{{timeLeft>0? '00:'+timeLeft:'Resend OTP'}}</span>
                </div> -->
                <div *ngIf="resendOtpCount>=2 && sendOtpClicked" style="display: flex;justify-content: center;margin: 1em;align-items: center;font-size: 12px;">
                    {{resendOtpCount}} attempts remaining
                </div>
            </form>

        </div>
    </div>
    <!-- <div class="help-txt display-flex-center">
        Already have an account ? <span style="color: #5624d0;font-size: 14px;font-weight: 600;cursor: pointer;" (click)="gotoLogin()">Log in</span>
    </div> -->
</div>