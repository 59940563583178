import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { webApi } from './api';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  // Config Variables

  isLogin: boolean = true;
  loadPaymantPage: boolean = false;
  fetchEnrollCalled: boolean = false;
  listOfEnrolledCourse: any = [];
  listOfCourse: any = [];
  listOfQuiz: any = [];
  listOfMyCourse: any = [];
  selectedCourse: any;
  selectedQuiz: any;
  selectedCourseToView: any;
  razorpayId: any;
  userDetail: any;
  //constructor
  constructor(
    private _httpClient: HttpClient,
    public routes: ActivatedRoute,
    private router: Router
  ) {
    this.userDetail = JSON.parse(localStorage.getItem('TG-USER-DASHBOARD')!);
    if (this.userDetail == null) {
      // this.router.navigate(['/layout/course']);
    }

    if (this.razorpayId == null || this.razorpayId == undefined) {
      this.getRazorPayId();
    } else {
    }
  }
  async getRazorPayId() {
    try {
      const param = { type: 'Razorpay' };
      const URL = `${webApi.domain + webApi.v2EndPoint.fetchAppConfig}`;

      await this.postMethod(param, URL).then((data: any) => {
        this.razorpayId = data['result']['value'];
      });
    } catch (error) { }
  }
  get windowRef() {
    return window;
  }
  deleteObjectElement(obj: any, prop: any) {
    if (Array.isArray(obj[prop]) && !obj[prop].length) delete obj[prop];
  }
  updatdUserDetailFromLocalStorage() {
    this.userDetail = JSON.parse(localStorage.getItem('TG-USER-DASHBOARD')!);
    if (this.userDetail == null) {
      this.router.navigate(['/login'], { queryParamsHandling: 'preserve' });
    }
  }

  putMethodWithToken(param: any, url: string, httpHeaders: any) {
    return new Promise((resolve) => {
      this._httpClient.put(url, param, { headers: httpHeaders }).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  postMethodWithToken(param: any, url: string, httpHeaders: any) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param, { headers: httpHeaders }).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  getMethodWithToken(url: string, httpHeaders: any) {
    return new Promise((resolve) => {
      this._httpClient.get(url, { headers: httpHeaders }).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  putMethod(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.put(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  postMethod(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  getMethod(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.get(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
}
