import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { QuizAlertDialogComponent } from '../quiz-alert-dialog/quiz-alert-dialog.component';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
})
export class QuizComponent implements OnInit {
  isComponentChanged: boolean = false;
  @Input()
  set componentChanged(value: boolean) {
    this.isComponentChanged = value;
    if (this.isComponentChanged) {
      this.emitResult(); // the answer is here
    }
  }

  @Input() activityDetail: any;
  @Input() courseDetail: any;
  @Input() moduleDetail: any;
  @Output() componentCloseAction: EventEmitter<any> = new EventEmitter();

  isLoading: boolean = true;

  attemptedCount: number = 0;
  attemptedDetails: any = [];

  selectedQuestionIndex: any;
  showSubmit: boolean = false;
  showResult: boolean = false;
  constructor(
    public routes: ActivatedRoute,
    private router: Router,
    private appService: AppService,
    private formBuilder: FormBuilder,
    private matDialog: MatDialog,
    private toastr: ToastrService
  ) {
    //console.log('Quiz Component');
  }

  ngOnInit(): void {
    this.activityDetail['quizContent'].forEach(
      (question: any, index: number) => {
        question['isValid'] = true;
        if (index == 0) {
          this.selectedQuestionIndex = index;
        }
      }
    );
    this.fetchAttemptedCount();
  }
  ngOnDestroy() {
    //console.log('Unattempted Quiz OnGoing');
  }
  ngOnChanges(changes: any) {
    //console.log('Change in Quiz Component', changes);
    this.isLoading = true;
    this.showResult = false;
    this.activityDetail['quizContent'].forEach(
      (question: any, index: number) => {
        question['isValid'] = true;
        if (index == 0) {
          this.selectedQuestionIndex = index;
        }
      }
    );
    this.fetchAttemptedCount();
  }
  emitResult() {
    // if (!this.showResult) {
    this.componentCloseAction.emit({
      activityId: this.moduleDetail['_id'],
      moduleId: this.moduleDetail['_id'],
    });
    // }
  }
  async fetchAttemptedCount() {
    this.isLoading = true;
    this.attemptedDetails = [];
    let param = {
      activityId: this.activityDetail['_id'],
      userManualId: this.appService.userDetail['uid'],
    };
    try {
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.appService.userDetail['accessToken']}`,
      });
      let URL = `${webApi.domain + webApi.v2EndPoint.fetchAttemptCount}`;
      await this.appService.postMethodWithToken(param, URL,httpHeaders).then((data: any) => {
        if (data['success']) {
          this.attemptedCount = data['count'];
          this.attemptedDetails = data['result'];
          if (this.attemptedDetails.length > 0) {
            this.showResult = true;
          }
          this.attemptedDetails.forEach((attempt: any) => {
            let totalMark = 0;
            let earnedMark = 0;
            attempt['quizContent'].forEach((quiz: any) => {
              totalMark = totalMark + 1;
              quiz['options'].forEach((option: any) => {
                if (option['isSelected'] && option['isCorrect']) {
                  earnedMark = earnedMark + 1;
                }
              });
            });
            attempt['totalMark'] = totalMark;
            attempt['earnedMark'] = earnedMark;
          });
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      });
    } catch (error) {
      this.isLoading = false;
    }
  }
  selectAnswerHandler(optionId: any) {
    this.activityDetail['quizContent'][this.selectedQuestionIndex]['isValid'] =
      true;
    this.activityDetail['quizContent'][this.selectedQuestionIndex][
      'options'
    ].forEach((option: any) => {
      if (option['_id'] == optionId) {
        option['isSelected'] = true;
      }
    });
  }
  gotoNextPosition() {
    let currentIndex = this.selectedQuestionIndex;
    if (currentIndex + 1 < this.activityDetail['quizContent'].length) {
      let isValidAnswer = false;
      this.activityDetail['quizContent'][this.selectedQuestionIndex][
        'options'
      ].forEach((option: any) => {
        if (option['isSelected']) {
          isValidAnswer = true;
        }
      });
      if (isValidAnswer) {
        this.selectedQuestionIndex++;
      } else {
        this.activityDetail['quizContent'][this.selectedQuestionIndex][
          'isValid'
        ] = false;
      }
    } else {
      this.showSubmit = true;
    }
  }
  gotoPreviousPosition() {
    let currentIndex = this.selectedQuestionIndex;
    if (currentIndex - 1 > 0) {
      this.selectedQuestionIndex--;
      this.showSubmit = false;
    } else {
    }
  }
  submitQuizForcefully() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      source: 'Submit Quiz',
    };

    let dialogRef = this.matDialog.open(QuizAlertDialogComponent, dialogConfig);
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        this.submitQuizHandler();
      }
    });
  }
  submitQuiz() {
    let isValidAnswer = false;
    this.activityDetail['quizContent'][this.selectedQuestionIndex][
      'options'
    ].forEach((option: any) => {
      if (option['isSelected']) {
        isValidAnswer = true;
      }
    });
    if (isValidAnswer) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        source: 'Submit Quiz',
      };

      let dialogRef = this.matDialog.open(
        QuizAlertDialogComponent,
        dialogConfig
      );
      dialogRef.updatePosition(dialogConfig.position);
      dialogRef.afterClosed().subscribe((result) => {
        if (result != undefined) {
        }
      });
      dialogRef.componentInstance.closeActivityAtDialog.subscribe(
        async (res) => {
          if (res == null) {
            return;
          } else {
            this.submitQuizHandler();
          }
        }
      );
    } else {
      this.activityDetail['quizContent'][this.selectedQuestionIndex][
        'isValid'
      ] = false;
    }
  }
  async submitQuizHandler() {
    try {
      let correct = 0;
      let earnedMark = 0;
      this.activityDetail['quizContent'].forEach(
        (quiz: any) => {
          quiz['options'].forEach((option: any) => {
            if (option['isSelected'] && option['isCorrect']) {
              earnedMark = earnedMark + 1;
            }
          });
        }
      );
      correct = earnedMark;
      let quizContentToInsert = {
        correct: correct,
        score: correct,
        totalScore:
          this.activityDetail['quizContent'] == null ||
          this.activityDetail['quizContent'] == undefined
            ? 0
            : this.activityDetail['quizContent'].length,
        quizContent: this.activityDetail['quizContent'],
      };
      let param = {
        quizActivity: JSON.stringify(quizContentToInsert),
        activity: this.activityDetail['_id'],
        module: this.moduleDetail['_id'],
        course: this.courseDetail['_id'],
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.appService.userDetail['accessToken']}`,
      });
      let URL = `${
        webApi.domain + webApi.v2EndPoint.insertCourseActivityLog
      }`;
      await this.appService
        .postMethodWithToken(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.showResult = true;
            this.attemptedCount++;
            this.attemptedDetails.push({
              userManualId: this.appService.userDetail['uid'],
              activityId: this.activityDetail['_id'],
              moduleId: this.moduleDetail['_id'],
              courseId: this.courseDetail['_id'],
              logDate: new Date(),
              type: 'Quiz',
              activityContent: this.activityDetail['quizContent'],
            });
            this.attemptedDetails.forEach((attempt: any) => {
              if (
                attempt['totalMark'] == null ||
                attempt['totalMark'] == undefined
              ) {
                let totalMark = 0;
                let earnedMark = 0;
                attempt['activityContent'].forEach((quiz: any) => {
                  totalMark = totalMark + 1;
                  quiz['options'].forEach((option: any) => {
                    if (option['isSelected'] && option['isCorrect']) {
                      earnedMark = earnedMark + 1;
                    }
                  });
                });
                attempt['totalMark'] = totalMark;
                attempt['earnedMark'] = earnedMark;
              }
            });
            //console.log('Activity Logged Successfully');
            this.showSuccessToaster('Quiz Submitted successfully.', 'Success');
          } else {
            this.showWarningToaster(
              'Unable to submit quiz at this moment.Try again',
              'Alert'
            );
          }
        });
    } catch (error) {
      this.showWarningToaster(
        'Unable to submit quiz at this moment.Try again',
        'Alert'
      );
    }
  }
  startQuizHandler() {
    this.activityDetail['quizContent'].forEach(
      (question: any, index: number) => {
        question['isValid'] = true;
        if (index == 0) {
          this.selectedQuestionIndex = index;
        }
        question['options'].forEach((option: any) => {
          option['isSelected'] = false;
        });
      }
    );
    this.showResult = false;
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
