<!-- <div>
    <div mat-dialog-content>
        <div class="display-flex-center">
            <img style="height: 80px;width: 80px;margin: 1em;" src="../../../assets/imgs/success.png" alt="" srcset="">
        </div>
        <div class="text-div">
            Course Enrolled Successfully
        </div>
    </div>

</div> -->
<div class="main-card">
    <div class="card width">
        <div style="border-radius:200px; height:200px; width:200px; background: #F8FAF5; margin:0 auto;    text-align: center;">
            <i class="checkmark">✓</i>
        </div>
        <h1>Success</h1>
        <p> <span style="font-weight: 600;"> You payment is successfully.</span><br /><br /> You can now login with same number you used while purchasing the course!</p>

        <!-- <div style="margin-top: 1em;font-size: 12px;">
            You will be automatically redirect to My Course.
        </div> -->
        <div *ngIf="this.appService.userDetail != null" style="margin-top: 1em;color: blue;cursor: pointer;" (click)="gotoMyCourse()">
            Click here to View Course
        </div>
        <div *ngIf="this.appService.userDetail == null" style="margin-top: 1em;color: blue;cursor: pointer;" (click)="gotoLogin()">
            Click here to Login
        </div>
    </div>
</div>