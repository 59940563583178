import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  sendOtpClicked: boolean = false;
  terms: boolean = true;
  containerBgColor: any = {};
  headStyle: any = {};
  btn: any = {};
  input: any = {};
  mobileNo: any;
  countryCode: any;
  email: any;
  name: any;

  myHeaders = new Headers();
  formdata = new FormData();

  timeLeft: number = 30;
  interval: any;
  resendOtpCount: number = 2;

  firstNameControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  // lastNameControl = new FormControl('', [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),]);
  emailControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  countryCodeControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  phoneControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
    Validators.minLength(10),
    Validators.maxLength(10),
    Validators.pattern('^[0-9]*$'),
  ]);
  otpControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
    Validators.minLength(6),
    Validators.maxLength(6),
    Validators.pattern('^[0-9]*$'),
  ]);

  signupForm: FormGroup = new FormGroup({
    firstName: this.firstNameControl,
    // lastName: this.lastNameControl,
    email: this.emailControl,
    phone: this.phoneControl,
    countryCode: this.countryCodeControl,
  });
  verifyOtpForm: FormGroup = new FormGroup({
    otp: this.otpControl,
  });
  userDetail: any;
  paramOnUrl: any;
  constructor(
    public routes: ActivatedRoute,
    private router: Router,
    private appService: AppService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) {
    this.userDetail = JSON.parse(localStorage.getItem('TG-USER-DASHBOARD')!);
    if (this.userDetail != null) {
      if (
        this.userDetail['name'] != null &&
        this.userDetail['name'] != undefined &&
        this.userDetail['name'] != ''
      ) {
        let paramTosend = { ...this.paramOnUrl };
        if (paramTosend['name'] != null && paramTosend['name'] != undefined) {
          paramTosend['name'] = undefined;
        }
        if (paramTosend['email'] != null && paramTosend['email'] != undefined) {
          paramTosend['email'] = undefined;
        }
        if (paramTosend['mobileNo'] != null && paramTosend['mobileNo'] != undefined) {
          paramTosend['mobileNo'] = undefined;
        }
        if (paramTosend['countryCode'] != null && paramTosend['countryCode'] != undefined) {
          paramTosend['countryCode'] = undefined;
        }
        this.router.navigate(['/course'], { replaceUrl: true, queryParams: paramTosend });
      }
    }
  }

  ngOnInit(): void {
    this.routes.queryParamMap.subscribe((params) => {
      let orderObj: any = { ...params.keys, ...params };
      this.paramOnUrl = orderObj['params'];
      this.countryCode = orderObj['params']['countryCode'];
      this.mobileNo = orderObj['params']['mobileNo'];
      this.name = orderObj['params']['name'] ?? '';
      this.email = orderObj['params']['email'] ?? '';
    });
    if (this.mobileNo == null || this.mobileNo == undefined) {
      let paramTosend = { ...this.paramOnUrl };
      if (paramTosend['name'] != null && paramTosend['name'] != undefined) {
        paramTosend['name'] = undefined;
      }
      if (paramTosend['email'] != null && paramTosend['email'] != undefined) {
        paramTosend['email'] = undefined;
      }
      if (paramTosend['mobileNo'] != null && paramTosend['mobileNo'] != undefined) {
        paramTosend['mobileNo'] = undefined;
      }
      if (paramTosend['countryCode'] != null && paramTosend['countryCode'] != undefined) {
        paramTosend['countryCode'] = undefined;
      }
      this.router.navigate(['/login'], { queryParams: paramTosend });
    }
    // //console.log(this.calledFrom);
    this.initializeCSS();
    this.signupForm = this.formBuilder.group({
      fullName: this.firstNameControl,
      // lastName: this.lastNameControl,
      email: this.emailControl,
      phone: this.phoneControl,
      countryCode: this.countryCodeControl,
    });
    if (this.countryCode != null) {
      this.signupForm.get('countryCode')?.setValue('+' + this.countryCode);
      this.signupForm.get('countryCode')?.disabled;
    }
    if (this.mobileNo != null) {
      this.signupForm.get('phone')?.setValue(this.mobileNo);
      this.signupForm.get('phone')?.disabled;
    }
    if (this.name != null) {
      this.signupForm.get('fullName')?.setValue(this.name);
      this.signupForm.get('fullName')?.disabled;
    }
    if (this.email != null) {
      this.signupForm.get('email')?.setValue(this.email);
      this.signupForm.get('email')?.disabled;
    }
  }
  initializeCSS() {
    this.containerBgColor['border-radius'] = '10px';
    this.containerBgColor['padding'] = '30px 20px';
    this.containerBgColor['box-shadow'] = '0px 0px 5px 0px rgba(0,0,0,0.15)';
    this.containerBgColor['-webkit-box-shadow'] =
      '0px 0px 5px 0px rgba(0,0,0,0.15)';
    this.containerBgColor['-moz-box-shadow'] =
      '0px 0px 5px 0px rgba(0,0,0,0.15)';
    this.headStyle['text-align'] = 'left';
    this.input['border'] = 'solid 1px grey';
    this.input['background-color'] = '#FFFFFF';
    this.input['padding'] = '10px 10px';
    this.input['border-radius'] = '5px';
    this.btn['border'] = 'none';
    this.btn['border-radius'] = '5px';
    this.btn['margin-top'] = '10px';
    this.btn['padding'] = '10px 16px';
    this.btn['font-size'] = '14px';
    this.btn['width'] = '100%';
    // this.btn['background-color'] = '#4C6FFF';
    this.btn['color'] = '#ffffff';
  }
  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      }
    }, 1000);
  }
  changeDetail() {
    this.sendOtpClicked = false;
    this.verifyOtpForm.get('otp')!.setValue('');
  }
  createAndUpdateUser() {
    try {
      // this.formdata.set('userManualId', this.userDetail['uid']);
      this.formdata.set('phone', this.mobileNo);
      this.formdata.set('countryCode', this.countryCode);
      this.formdata.set('userEmail', this.signupForm.get('email')!.value);
      this.formdata.set('userName', this.signupForm.get('fullName')!.value);
      this.formdata.set('loginSource', 'User Dashboard');
     
      let utm1: any = {};
      let utm2: any = {};

      for (const key in this.paramOnUrl) {
        if (key == 'utm_medium' || key == 'utm_source' || key == 'utm_campaign' || key == 'utm_content' || key == 'utm_term') {
          utm1[key] = this.paramOnUrl[key];
        } else {
          utm2[key] = this.paramOnUrl[key];
        }
      }
      this.formdata.set('utm_medium', utm1['utm_medium']);
      this.formdata.set('utm_source', utm1['utm_source']);
      this.formdata.set('utm_campaign', utm1['utm_campaign']);
      this.formdata.set('utm_content', utm1['utm_content']);
      this.formdata.set('utm_term', utm1['utm_term']);
      this.formdata.set('utm_params', JSON.stringify(utm2));

      var requestOptions: RequestInit = {
        method: 'POST',
        headers: this.myHeaders,
        body: this.formdata,
        redirect: 'follow',
      };

      let URL = `${webApi.domain + webApi.v2EndPoint.updateUserWhileSignin}`;

      fetch(URL, requestOptions).then((result) => {
        if (result) {
          let data = result.json();
          data.then((res) => {
            if (res['success']) {
              if (res['message'] == "User with same email but already exist") {
                this.showWarningToaster(
                  'User with same email but diffrent phone number already exist. Please contact support team.',
                  'Warning'
                );
              } else {

                localStorage.setItem(
                  'TG-USER-DASHBOARD',
                  JSON.stringify({
                    accessToken: res['accessToken'],
                    countryCode: res['result']['countryCode'] ?? 91,
                    name: res['result']['fullName'],
                    phone: res['result']['phone'],
                    email: res['result']['email'],
                    uid: res['result']['userManualId'],
                    image: res['result']['avatar'],
                  })
                );
                this.appService.updatdUserDetailFromLocalStorage();

                if (
                  this.appService.selectedCourse == null ||
                  this.appService.selectedCourse == undefined
                ) {
                  this.fetchAllEnrolledCourse();
                } else {
                  let paramTosend = { ...this.paramOnUrl };
                  if (paramTosend['name'] != null && paramTosend['name'] != undefined) {
                    paramTosend['name'] = undefined;
                  }
                  if (paramTosend['email'] != null && paramTosend['email'] != undefined) {
                    paramTosend['email'] = undefined;
                  }
                  if (paramTosend['mobileNo'] != null && paramTosend['mobileNo'] != undefined) {
                    paramTosend['mobileNo'] = undefined;
                  }
                  if (paramTosend['countryCode'] != null && paramTosend['countryCode'] != undefined) {
                    paramTosend['countryCode'] = undefined;
                  }
                  this.router.navigate(['/course-detail'], {
                    queryParams: {
                      name: this.appService.selectedCourse['courseSlug'], ...paramTosend
                    },
                  });
                }
                this.showSuccessToaster('Logged in Successfully', 'Success');
              }
            } else {
              this.showWarningToaster(
                'Unable to Signup at this moment.Please try again',
                'Alert'
              );
            }
          });
        }
      });
    } catch (error) {
      this.showWarningToaster(
        'Unable to Signup at this moment.Please try again',
        'Alert'
      );
    }
  }
  // async isUserExists() {
  //   const param = {
  //     phone: this.signupForm.get('phone')?.value,
  //   };
  //   try {
  //     const URL = `${webApi.domain + webApi.v2EndPoint.checkForUser}`;
  //     await this.appService.postMethod(param, URL).then(async (data: any) => {
  //       if (data['type']) {
  //         if (data['message'] == 'Existing User') {
  //           this.showWarningToaster(
  //             `User with same mobile number already exists`,
  //             'Alert'
  //           );
  //         } else {
  //           this.sendOtp();
  //         }
  //       } else {
  //         this.sendOtp();
  //       }
  //     });
  //   } catch (error) {
  //     this.showWarningToaster(
  //       'Unable to Signup at this moment.Please try again',
  //       'Alert'
  //     );
  //   }
  // }
  // async sendOtp() {
  //   const param = {
  //     mobileNo: this.signupForm.get('phone')!.value,
  //   };
  //   try {
  //     let URL = `${webApi.domain + webApi.v2EndPoint.sendOTPtoUser}`;
  //     await this.appService.postMethod(param, URL).then((data: any) => {
  //       if (data['success'] && data['result']['type'] == 'success') {
  //         this.sendOtpClicked = true;
  //         this.verifyOtpForm = this.formBuilder.group({
  //           otp: this.otpControl,
  //         });
  //         this.timeLeft = 30;
  //         this.startTimer();
  //       } else {
  //         this.sendOtpClicked = true;
  //         this.showWarningToaster(`Something went wrong. Try again`, 'Alert');
  //       }
  //     });
  //   } catch (error) {
  //     this.sendOtpClicked = true;
  //     this.showWarningToaster(`Something went wrong. Try again`, 'Alert');
  //   }
  // }
  // async reSendOtp() {
  //   if (this.resendOtpCount >= 1) {
  //     const param = {
  //       mobileNo: this.signupForm.get('phone')!.value,
  //     };
  //     try {
  //       let URL = `${webApi.domain + webApi.v2EndPoint.resendOTPtoUser}`;
  //       await this.appService.postMethod(param, URL).then((data: any) => {
  //         if (data['success'] && data['result']['type'] == 'success') {
  //           this.sendOtpClicked = true;
  //           this.verifyOtpForm = this.formBuilder.group({
  //             otp: this.otpControl,
  //           });
  //           this.resendOtpCount--;
  //           this.timeLeft = 30;
  //           this.startTimer();
  //         } else {
  //           this.sendOtpClicked = true;
  //           if (this.resendOtpCount == 1) {
  //             this.showWarningToaster(
  //               `3 attemtps of resend OTP has been already made.`,
  //               'Alert'
  //             );
  //           } else {
  //             this.showWarningToaster(
  //               `Something went wrong. Try again`,
  //               'Alert'
  //             );
  //           }
  //         }
  //       });
  //     } catch (error) {
  //       this.sendOtpClicked = true;
  //       this.showWarningToaster(`Something went wrong. Try again`, 'Alert');
  //     }
  //   } else {
  //     this.showWarningToaster(
  //       `3 attemtps of resend OTP has been already made.`,
  //       'Alert'
  //     );
  //   }
  // }
  // async verifyOtp() {
  //   let param = {
  //     mobileNo: this.signupForm.get('phone')!.value,
  //     otp: this.verifyOtpForm.get('otp')!.value,
  //   };
  //   let URL = `${webApi.domain + webApi.v2EndPoint.verifyOTPofUser}`;
  //   await this.appService.postMethod(param, URL).then((data: any) => {
  //     if (data['success'] && data['result']['type'] == 'success') {
  //       this.createUser();
  //     } else {
  //       this.showWarningToaster('Incorrect otp entered.', 'Alert');
  //     }
  //   });
  // }
  // async createUser() {
  //   const param = {
  //     userEmail: this.signupForm.get('email')!.value,
  //     userName: this.signupForm.get('fullName')!.value,
  //     // +
  //     // ' ' +
  //     // this.signupForm.get('lastName')!.value,
  //     countryCode: this.signupForm.get('countryCode')!.value,
  //     mobileNo: this.signupForm.get('phone')!.value,
  //     loginSource: 'User Dashboard',
  //   };
  //   try {
  //     const URL = `${
  //       webApi.domain + webApi.v2EndPoint.insertUserDetailForDashboardUser
  //     }`;
  //     await this.appService.postMethod(param, URL).then(async (data: any) => {
  //       if (data['type']) {
  //         localStorage.setItem(
  //           'TG-USER-DASHBOARD',
  //           JSON.stringify({
  //             accessToken: data['token'],
  //             name: data['user']['userName'],
  //             phone: data['user']['mobileNo'],
  //             email: data['user']['email'],
  //             uid: data['user']['userManualId'],
  //             image: data['user']['userImage'],
  //           })
  //         );
  //         this.appService.updatdUserDetailFromLocalStorage();

  //         if (
  //           this.appService.selectedCourse == null ||
  //           this.appService.selectedCourse == undefined
  //         ) {
  //           // this.router.navigate(['/course'], { replaceUrl: true });
  //           this.fetchAllEnrolledCourse();
  //         } else {
  //           this.router.navigate(['/course-detail'], {
  //             queryParams: {
  //               name: this.appService.selectedCourse['courseSlug'],
  //             },
  //           });
  //         }
  //       } else {
  //         this.showWarningToaster(
  //           'Unable to Signup at this moment.Please try again',
  //           'Alert'
  //         );
  //       }
  //     });
  //   } catch (error) {
  //     //console.log(error);
  //     this.showWarningToaster(
  //       'Unable to Signup at this moment.Please try again',
  //       'Alert'
  //     );
  //   }
  // }
  async fetchAllEnrolledCourse() {
    this.appService.fetchEnrollCalled = true;
    let param = {};
    try {
      let URL = `${webApi.domain + webApi.v2EndPoint.fetchEnrolledCourseForUserDashboard
        }`;

      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.appService.userDetail['accessToken']}`,
      });
      await this.appService
        .postMethodWithToken(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.appService.listOfMyCourse = data['result'];
            this.appService.listOfMyCourse.forEach((course: any) => {
              course['isEnrolled'] = true;
            });
            let paramTosend = { ...this.paramOnUrl };
            if (paramTosend['name'] != null && paramTosend['name'] != undefined) {
              paramTosend['name'] = undefined;
            }
            if (paramTosend['email'] != null && paramTosend['email'] != undefined) {
              paramTosend['email'] = undefined;
            }
            if (paramTosend['mobileNo'] != null && paramTosend['mobileNo'] != undefined) {
              paramTosend['mobileNo'] = undefined;
            }
            if (paramTosend['countryCode'] != null && paramTosend['countryCode'] != undefined) {
              paramTosend['countryCode'] = undefined;
            }
            this.router.navigate(['/course'], { replaceUrl: true, queryParams: paramTosend });
          } else {
            this.appService.listOfMyCourse = [];
            let paramTosend = { ...this.paramOnUrl };
            if (paramTosend['name'] != null && paramTosend['name'] != undefined) {
              paramTosend['name'] = undefined;
            }
            if (paramTosend['email'] != null && paramTosend['email'] != undefined) {
              paramTosend['email'] = undefined;
            }
            if (paramTosend['mobileNo'] != null && paramTosend['mobileNo'] != undefined) {
              paramTosend['mobileNo'] = undefined;
            }
            if (paramTosend['countryCode'] != null && paramTosend['countryCode'] != undefined) {
              paramTosend['countryCode'] = undefined;
            }
            this.router.navigate(['/course'], { replaceUrl: true, queryParams: paramTosend });
          }
        });
    } catch (error) {
      this.appService.listOfMyCourse = [];
      let paramTosend = { ...this.paramOnUrl };
      if (paramTosend['name'] != null && paramTosend['name'] != undefined) {
        paramTosend['name'] = undefined;
      }
      if (paramTosend['email'] != null && paramTosend['email'] != undefined) {
        paramTosend['email'] = undefined;
      }
      if (paramTosend['mobileNo'] != null && paramTosend['mobileNo'] != undefined) {
        paramTosend['mobileNo'] = undefined;
      }
      if (paramTosend['countryCode'] != null && paramTosend['countryCode'] != undefined) {
        paramTosend['countryCode'] = undefined;
      }
      this.router.navigate(['/course'], { replaceUrl: true, queryParams: paramTosend });
    }
  }
  gotoLogin() {
    let paramTosend = { ...this.paramOnUrl };
    if (paramTosend['name'] != null && paramTosend['name'] != undefined) {
      paramTosend['name'] = undefined;
    }
    if (paramTosend['email'] != null && paramTosend['email'] != undefined) {
      paramTosend['email'] = undefined;
    }
    if (paramTosend['mobileNo'] != null && paramTosend['mobileNo'] != undefined) {
      paramTosend['mobileNo'] = undefined;
    }
    if (paramTosend['countryCode'] != null && paramTosend['countryCode'] != undefined) {
      paramTosend['countryCode'] = undefined;
    }
    this.router.navigate(['/login'], { queryParams: paramTosend });
  }
  gotoSignup() {
    let paramTosend = { ...this.paramOnUrl };
    if (paramTosend['name'] != null && paramTosend['name'] != undefined) {
      paramTosend['name'] = undefined;
    }
    if (paramTosend['email'] != null && paramTosend['email'] != undefined) {
      paramTosend['email'] = undefined;
    }
    if (paramTosend['mobileNo'] != null && paramTosend['mobileNo'] != undefined) {
      paramTosend['mobileNo'] = undefined;
    }
    if (paramTosend['countryCode'] != null && paramTosend['countryCode'] != undefined) {
      paramTosend['countryCode'] = undefined;
    }
    this.router.navigate(['/signup'], { queryParams: paramTosend });
  }

  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
