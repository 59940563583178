import { HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from './api';
import { AppService } from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'components';

  isLoading: boolean = true;
  userDetail: any;
  constructor(
    public routes: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private appService: AppService
  ) {
    this.userDetail = JSON.parse(localStorage.getItem('TG-USER-DASHBOARD')!);
    if (this.userDetail != null) {
      this.fetchAllCourse();
    } else {
      this.isLoading = false;

      // this.router.navigate(['/layout/course']);
    }
  }

  ngOnInit(): void { }

  async fetchAllCourse() {
    this.isLoading = true;
    this.appService.fetchEnrollCalled = true;

    let param = {
      // userManualId: this.userDetail['uid'],
    };
    try {
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.appService.userDetail['accessToken']}`,
      });
      let URL = `${webApi.domain + webApi.v2EndPoint.fetchEnrolledCourseForUserDashboard
        }`;
      await this.appService
        .postMethodWithToken(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.appService.listOfMyCourse = data['result'];
            this.appService.listOfMyCourse.forEach((course: any) => {
              course['isEnrolled'] = true;
            });
            //console.log(this.appService.listOfMyCourse);
            this.isLoading = false;
          } else {
            this.appService.listOfMyCourse = [];
            this.isLoading = false;
            if (data['error']['message'] == 'You are not authenticated.') {
              localStorage.removeItem('TG-USER-DASHBOARD');
              this.appService.userDetail = null;
              this.appService.listOfMyCourse = [];
              this.appService.listOfCourse.forEach((element: any) => {
                element['isEnrolled'] = false;
              });
              this.router.navigate(['/course'], { queryParamsHandling: 'preserve' });
              this.showWarningToaster('Login Session has been expired!', 'Warning');
            }
          }
        });
    } catch (error) {
      this.appService.listOfMyCourse = [];
      this.isLoading = false;
    }
  }

  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
}
