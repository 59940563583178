import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { LayoutRoutingModule } from './layout-routing.module';
import { LayoutComponent } from './layout.component';
import { LeftSidebarComponent } from './left-sidebar/left-sidebar.component';
import { FooterComponent } from './footer/footer.component';

import { AvatarComponent } from './component/avatar/avatar.component';
import { BadgeComponent } from './component/badge/badge.component';
import { TagComponent } from './component/tag/tag.component';
import { ButtonComponent } from './component/button/button.component';
import { ButtonGroupComponent } from './component/button-group/button-group.component';
import { FormGroupComponent } from './component/form-group/form-group.component';
import { InputGroupComponent } from './component/input-group/input-group.component';
import { FormChecksComponent } from './component/form-checks/form-checks.component';
import { AuthFormComponent } from './component/auth-form/auth-form.component';
import { DropdownComponent } from './component/dropdown/dropdown.component';
import { IconComponent } from './component/icon/icon.component';
import { CardComponent } from './component/card/card.component';
import { AlertComponent } from './component/alert/alert.component';
import { BasicTableComponent } from './component/basic-table/basic-table.component';
import { DataTableComponent } from './component/data-table/data-table.component';
import { PieChartComponent } from './component/pie-chart/pie-chart.component';
import { BarChartComponent } from './component/bar-chart/bar-chart.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CourseListComponent } from './course-list/course-list.component';
import { CourseDetailComponent } from './course-detail/course-detail.component';
import { CourseContentComponent } from './course-content/course-content.component';
import { ProfileComponent } from './profile/profile.component';
import { NotificationComponent } from './notification/notification.component';
import { ProductComponent } from './product/product.component';
import { SidebarModule } from 'ng-sidebar';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { ToastrModule } from 'ngx-toastr';
import { ContentComponent } from './content/content.component';
import { InstructorComponent } from './instructor/instructor.component';
import { SideCourseContentComponent } from './side-course-content/side-course-content.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import {  MatTabsModule} from '@angular/material/tabs';
import {  MatMenuModule} from '@angular/material/menu';
import { LightboxModule } from 'ngx-lightbox';
import { PlayDemoVideoDialogComponent } from './play-demo-video-dialog/play-demo-video-dialog.component';
import { MyCourseComponent } from './my-course/my-course.component';
import { PaymentSuccessDialogComponent } from './payment-success-dialog/payment-success-dialog.component';
import { QuizComponent } from './quiz/quiz.component';
import { QuizAlertDialogComponent } from './quiz-alert-dialog/quiz-alert-dialog.component';
import { QuizDetailComponent } from './quiz-detail/quiz-detail.component';
import { PasswordLoginComponent } from './password-login/password-login.component';
import { PaymentSuccessForFreeComponent } from './payment-success-for-free/payment-success-for-free.component';

@NgModule({
  declarations: [
    // HeaderComponent,
    // LayoutComponent,
    // LeftSidebarComponent,
    // FooterComponent,
    // LoginComponent,
    // SignupComponent,
    // ContentComponent,
    // InstructorComponent,
    // CourseListComponent,
    // CourseDetailComponent,
    // CourseContentComponent,
    // ProfileComponent,
    // NotificationComponent,
    // ProductComponent,

    // //  UI Component Library

    // AlertComponent,
    // AvatarComponent,
    // BadgeComponent,
    // TagComponent,
    // ButtonComponent,
    // ButtonGroupComponent,
    // FormGroupComponent,
    // InputGroupComponent,
    // FormChecksComponent,
    // AuthFormComponent,
    // DropdownComponent,
    // IconComponent,
    // CardComponent,
    // BasicTableComponent,
    // DataTableComponent,
    // PieChartComponent,
    // BarChartComponent,

    // CourseListComponent,
    // CourseDetailComponent,
    // CourseContentComponent,
    // ProfileComponent,
    // NotificationComponent,
    // ProductComponent,

    // LoginComponent,
    // SignupComponent,
    // ContentComponent,
    // InstructorComponent,
    // SideCourseContentComponent,
    // PlayDemoVideoDialogComponent,
    // MyCourseComponent,
    // PaymentSuccessDialogComponent,
    // QuizComponent,
    // QuizAlertDialogComponent,
    // QuizDetailComponent,
  
    PasswordLoginComponent,
    PaymentSuccessForFreeComponent
  ],
  imports: [
    CommonModule,
    LayoutRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SidebarModule.forRoot(),
    ToastrModule.forRoot(),
    LightboxModule,
    MatExpansionModule,
    MatIconModule,
    MatDialogModule,
    MatTabsModule,
    MatMenuModule
  ],
  exports: [MatExpansionModule],
})
export class LayoutModule {}
