<div *ngIf="isLoading" class="loader-div">
    <div class="loader">
    </div>
</div>
<div *ngIf="!isLoading" class="coursecarts">
    <div *ngIf="listOfMyCourse.length==0" class="empty-val">
        <img style="height: 15em;" src="../../../assets/imgs/empty-bg.png" alt="" srcset=""> <span class="no-data">No
            course available.</span>
        <app-button (callBackFunction)="gotoExploreCourse()" text="Explore" size="medium" shape="rectangular" type="outline" bgColor="#3754DB"></app-button>
    </div>
    <div *ngIf="listOfMyCourse.length>0">
        <div class="course-type-div padding-left-right">
            Enrolled Course
        </div>
        <div class="coursecarts-list padding-left-right">
            <div *ngFor="let course of listOfMyCourse">

                <app-card (callBackFunction)="gotoCourseDetail(course)" cardType="course" [title]="course['courseName']" [subTitle]="course['createdAt'] | date" [showPositionTopRightButton]="course['isEnrolled']?true:false" [img]="course['courseImageUrl']" [imgStyle]="imgStyle"
                    [cardStyle]="cardStyle">
                    <app-button [matMenuTriggerFor]="menu" *ngIf="course['isEnrolled']" positionTopRightButtonCourse [onlyIcon]="true" mainIcon="../../../assets/imgs/more.png" bgColor="rgb(218, 218, 218)" size="medium" shape="circular" type="solid"></app-button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="courseDetail(course)">View Detail</button>
                    </mat-menu>
                    <div descriptionCourse (click)="gotoCourseDetail(course)" style="cursor: pointer;">
                        <div class="display-flex-space-between duration">
                            <span> {{course['authorId']['name']}}</span>
                            <span> {{course['levels']}}</span>
                        </div>
                        <div class="display-flex-space-between">
                            <span [ngStyle]="{'display':course['courseType']=='Free'?'none':'block'}">{{'\u20b9\
                                '+course['coursePrice']}}</span>
                            <span *ngIf="!course['isEnrolled']" [ngClass]="{'courseType-paid':course['courseType']=='Paid','courseType-free':course['courseType']=='Free'}">{{course['courseType']}}</span>
                            <span *ngIf="course['isEnrolled']" class="course-enrolled">Enrolled</span>
                        </div>
                        <div class="display-flex-space-between duration" *ngIf="!course['isEnrolled']">
                            <span>Duration: {{getDuration(course['totalHour'])}}</span> <span>Lessons:
                                {{course['totalLesson']}}</span>
                        </div>
                        <div class="display-flex-space-between duration" >
                            <span >Total Enrolled: <b>{{course['totalEnrolled']}}</b> Student</span> 
                        </div>
                        <div *ngIf="!course['isEnrolled']" style="height: 1.5em;"></div>
                        <div class="display-flex-space-between duration" *ngIf="course['isEnrolled']">
                            <div  class="continue-learning">
                                Continue Learning
                            </div>
                        </div>
                    </div>
                </app-card>
            </div>

        </div>

    </div>
    <div style="height: 10em;">

    </div>
</div>