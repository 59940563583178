import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '../app.service';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss'],
})
export class PaymentSuccessComponent implements OnInit {
  constructor(private ngZone: NgZone, 
    public dialogRef: MatDialogRef<PaymentSuccessComponent>,
    private toastr: ToastrService,
    public appService: AppService,
    protected _sanitizer: DomSanitizer,
    public routes: ActivatedRoute,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    this.dialogRef.disableClose = true;
    //console.log('dialog called');
    //console.log(this.data);
  }
  ngOnInit(): void {}
  gotoLogin() {
    this.dialogRef.close();
    this.ngZone.run(() => this.router.navigate(['/login'],{queryParamsHandling: 'preserve'})).then();
    
  }
}
