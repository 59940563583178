import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
@Component({
  selector: 'app-password-login',
  templateUrl: './password-login.component.html',
  styleUrls: ['./password-login.component.scss'],
})
export class PasswordLoginComponent implements OnInit {
  terms: boolean = true;
  isLoading: boolean = false;
  containerBgColor: any = {};
  headStyle: any = {};
  btn: any = {};
  input: any = {};
  userDetail: any;

  constructor(
    public routes: ActivatedRoute,
    private router: Router,
    private appService: AppService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) {}

  emailControl = new FormControl('', [
    Validators.required,
    Validators.pattern(
      '^([\\w-]+(?:\\.[\\w-]+)*)@((?:[\\w-]+\\.)*\\w[\\w-]{0,66})\\.([A-Za-z]{2,6}(?:\\.[A-Za-z]{2,6})?)$'
    ),
  ]);
  passwordControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);

  loginForm: FormGroup = new FormGroup({
    password: this.passwordControl,
    email: this.emailControl,
  });

  initializeCSS() {
    this.containerBgColor['border-radius'] = '5px';
    this.containerBgColor['padding'] = '30px 20px';
    this.containerBgColor['box-shadow'] = '0px 0px 5px 0px rgba(0,0,0,0.15)';
    this.containerBgColor['-webkit-box-shadow'] =
      '0px 0px 5px 0px rgba(0,0,0,0.15)';
    this.containerBgColor['-moz-box-shadow'] =
      '0px 0px 5px 0px rgba(0,0,0,0.15)';
    this.headStyle['text-align'] = 'left';
    this.input['border'] = 'solid 1px grey';
    this.input['background-color'] = '#FFFFFF';
    this.input['padding'] = '10px 10px';
    this.input['border-radius'] = '5px';
    this.btn['border'] = 'none';
    // this.btn['cursor'] = 'pointer';
    this.btn['border-radius'] = '5px';
    this.btn['margin-top'] = '10px';
    this.btn['padding'] = '10px 16px';
    this.btn['font-size'] = '14px';
    this.btn['width'] = '100%';
    // this.btn['background-color'] = '#4C6FFF';
    this.btn['color'] = '#ffffff';
  }

  ngOnInit(): void {
    this.initializeCSS();
    this.loginForm = this.formBuilder.group({
      password: this.passwordControl,
      email: this.emailControl,
    });
  }

  async login() {
    if (!this.isLoading) {
      this.isLoading = true;
      let param = {
        email: this.loginForm.get('email')?.value,
        password: this.loginForm.get('password')?.value,
      };
      let URL = `${webApi.domain + webApi.endPoint.loginWithPassword}`;
      try {
        await this.appService.postMethod(param, URL).then((data: any) => {
          if (data['success']) {
            this.isUserExists(data['result']['_id']);
            // this.showSuccessToaster('Success', 'Success');
          } else {
            if (data['message'] == 'NOT_ALLOWED') {
              this.showErrorToaster(
                'Password login is not allowed. Please contact support team.',
                'Alert'
              );
            } else if (
              data['message'] == 'NOT_FOUND' ||
              data['message'] == 'NOT_MATCHED'
            ) {
              this.showWarningToaster(
                'Entered credential is invalid.',
                'Alert'
              );
            } else {
              this.showErrorToaster('Somethig went wrong.', 'Alert');
            }
            this.isLoading = false;
          }
        });
      } catch (error) {
        this.showErrorToaster('Somethig went wrong.', 'Alert');
        this.isLoading = false;
      }
    }
  }

  async isUserExists(id: any) {
    const param = {
      id: id,
    };
    try {
      const URL = `${webApi.domain + webApi.v2EndPoint.checkForUserWithEmailInDashBoard}`;
      await this.appService.postMethod(param, URL).then(async (data: any) => {
        if (data['success']) {
          if (data['message'] == 'Existing User') {
            localStorage.setItem(
              'TG-USER-DASHBOARD',
              JSON.stringify({
                accessToken: data['accessToken'],
                name: data['result']['fullName'],
                countryCode: data['result']['countryCode'] ?? 91,
                phone: data['result']['phone'],
                email: data['result']['email'],
                uid: data['result']['userManualId'],
                image: data['result']['avatar'],
              })
            );
            this.appService.updatdUserDetailFromLocalStorage();
            if (
              data['result']['fullName'] == null ||
              data['result']['fullName'] == undefined ||
              data['result']['fullName'] == ''
            ) {
              this.router.navigate(['/signup'], {
                queryParams: {
                  countryCode: this.loginForm.get('countryCode')?.value,
                  mobileNo: this.loginForm.get('phone')?.value,
                  email: data['result']['email'],
                },queryParamsHandling: 'merge'
              });
            } else {
              if (
                this.appService.selectedCourse == null ||
                this.appService.selectedCourse == undefined
              ) {
                this.fetchAllEnrolledCourse2();
              } else {
                this.router.navigate(['/course-detail'], {
                  queryParams: {
                    name: this.appService.selectedCourse['courseSlug'],
                  },queryParamsHandling: 'merge'
                });
              }
            }
          } else {
            this.router.navigate(['/signup'], {
              queryParams: {
                countryCode: this.loginForm.get('countryCode')?.value,
                mobileNo: this.loginForm.get('phone')?.value,
              },queryParamsHandling: 'merge'
            });
          }
        } else {
          this.showWarningToaster(
            `Unable to login at this moment.Please try again`,
            'Alert'
          );
        }
      });
    } catch (error) {
      this.showWarningToaster(
        'Unable to login at this moment.Please try again',
        'Alert'
      );
    }
  }
  async fetchAllEnrolledCourse2() {
    this.appService.fetchEnrollCalled = true;

    let param = {
      // userManualId: this.appService.userDetail['uid'],
    };
    try {
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.appService.userDetail['accessToken']}`,
      });
      let URL = `${
        webApi.domain + webApi.v2EndPoint.fetchEnrolledCourseForUserDashboard
      }`;
      await this.appService
        .postMethodWithToken(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.appService.listOfMyCourse = data['result'];
            this.appService.listOfMyCourse.forEach((course: any) => {
              course['isEnrolled'] = true;
            });
            this.router.navigate(['/course'], { replaceUrl: true,queryParamsHandling: 'preserve' });
          } else {
            this.appService.listOfMyCourse = [];
            this.router.navigate(['/course'], { replaceUrl: true,queryParamsHandling: 'preserve' });
          }
        });
    } catch (error) {
      this.appService.listOfMyCourse = [];
      this.router.navigate(['/course'], { replaceUrl: true,queryParamsHandling: 'preserve' });
    }
  }

  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
