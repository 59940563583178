<div *ngIf="data['source']=='Component Change'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div class="mat-cntr" mat-dialog-content>
        <div>
            Are you sure you want to switch the activity ? Any changes will be lost.
        </div>
    </div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="changeActivity()">Yes</button>
    </div>
</div>
<div *ngIf="data['source']=='Submit Quiz'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div class="mat-cntr" mat-dialog-content>
        <div>
            Are you sure you want to submit this quiz?
        </div>
    </div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="changeActivity()">Yes</button>
    </div>
</div>