import { DOCUMENT } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { PaymentSuccessComponent } from 'src/app/payment-success/payment-success.component';
import { PaymentSuccessDialogComponent } from '../payment-success-dialog/payment-success-dialog.component';

@Component({
  selector: 'app-course-detail',
  templateUrl: './course-detail.component.html',
  styleUrls: ['./course-detail.component.scss'],
})
export class CourseDetailComponent implements OnInit {
  courseSlug?: string;
  windowRef: any;

  courseDetail: any;
  isLoading: boolean = true;
  isBuyLoading: boolean = false;
  paramOnUrl: any;
  constructor(
    public routes: ActivatedRoute,
    private router: Router,
    private appService: AppService,
    private matDialog: MatDialog,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
  ) {
    this.windowRef = this.appService.windowRef;
  }


  ngOnInit(): void {
    this.routes.queryParamMap.subscribe((params) => {
      let allParam: any = { ...params.keys, ...params };
      this.paramOnUrl = allParam['params'];
      this.courseSlug = allParam['params']['name'];
    });

    // console.log(this.paramOnUrl);

    if (this.courseSlug == 'money-making-trading-course') {
      let script = this._renderer2.createElement('script');
      script.type = `application/ld+json`;
      script.text = `
      <!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-D3R2NB4RKK"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-D3R2NB4RKK');
</script>

<!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-WKTHM8W');</script>
<!-- End Google Tag Manager -->


<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WKTHM8W"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->

<script src="https://www.googleoptimize.com/optimize.js?id=OPT-TJN9TQ7"></script>

    `;

      this._renderer2.appendChild(this._document.body, script);
    }

    if (
      this.appService.userDetail != null &&
      this.appService.listOfMyCourse.length == 0
    ) {
      this.fetchAllEnrolledCourse();
    } else {
    }
    this.assignData();
  }
  assignData() {
    // if (this.appService.selectedCourse != null) {
    //   this.courseDetail = this.appService.selectedCourse;
    //   this.courseDetail['modules'].forEach((module: any) => {
    //     module['activity'] = [];
    //   });
    //   this.courseDetail['modules'].forEach((module: any) => {
    //     this.courseDetail['activities'].forEach((activity: any) => {
    //       if (module['id'] == activity['moduleId']) {
    //         module['activity'].push(activity);
    //       }
    //     });
    //   });
    //   this.courseDetail['modules'].sort(
    //     (a: any, b: any) => a['position'] > b['position']
    //   );
    //   this.courseDetail['modules'].forEach((module: any) => {
    //     module['activity'].sort(
    //       (a: any, b: any) => a['position'] > b['position']
    //     );
    //   });
    //   this.isLoading = false;
    // } else {
    this.appService.selectedCourse = null;
    this.fetchCourseBySlug();
    // }
  }
  async fetchCourseBySlug() {
    this.isLoading = true;

    let param = {
      courseSlug: this.courseSlug,
    };
    try {
      let URL = `${webApi.domain + webApi.v2EndPoint.fetchCourseBySlug}`;
      await this.appService.postMethod(param, URL).then((data: any) => {
        if (data['success']) {
          this.courseDetail = data['result'];
          this.courseDetail['isEnrolled'] = false;

          if (this.appService.listOfMyCourse.length > 0) {
            this.appService.listOfMyCourse.forEach((course: any) => {
              if (course['_id'] == this.courseDetail['_id']) {
                this.courseDetail['isEnrolled'] = true;
              }
            });
          }
          this.courseDetail['modules'].forEach((module: any) => {
            module['activity'] = [];
          });
          this.courseDetail['modules'].forEach((module: any) => {
            this.courseDetail['activities'].forEach((activity: any) => {
              if (module['_id'] == activity['module']) {
                module['activity'].push(activity);
              }
            });
          });
          this.courseDetail['modules'].sort(
            (a: any, b: any) => a['position'] - b['position']
          );
          this.courseDetail['modules'].forEach((module: any) => {
            module['activity'].sort(
              (a: any, b: any) => a['position'] - b['position']
            );
          });
          this.appService.selectedCourse = this.courseDetail;

          this.isLoading = false;
        } else {
          let paramTosend = { ...this.paramOnUrl };
          if (paramTosend['name'] != null && paramTosend['name'] != undefined) {
            paramTosend['name'] = undefined;
          }
          this.router.navigate(['/course'], { queryParams: paramTosend });
          this.isLoading = false;
        }
      });
    } catch (error) {
      let paramTosend = { ...this.paramOnUrl };
      if (paramTosend['name'] != null && paramTosend['name'] != undefined) {
        paramTosend['name'] = undefined;
      }
      this.router.navigate(['/course'], { queryParams: paramTosend });
      this.isLoading = false;
    }
  }
  async fetchAllEnrolledCourse() {
    let param = {
      // userManualId: this.appService.userDetail['uid'],
    };
    try {
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.appService.userDetail['accessToken']}`,
      });
      let URL = `${webApi.domain + webApi.v2EndPoint.fetchEnrolledCourseForUserDashboard
        }`;
      await this.appService
        .postMethodWithToken(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.appService.listOfMyCourse = data['result'];
            this.appService.listOfMyCourse.forEach((element: any) => {
              element['isEnrolled'] = true;
            });

            this.assignData();
          } else {
            this.appService.listOfMyCourse = [];
            this.assignData();
          }
        });
    } catch (error) {
      this.appService.listOfMyCourse = [];
      this.assignData();
    }
  }

  getDuration(minutes: number) {
    let hour = 0;
    while (minutes >= 60) {
      hour++;
      minutes = minutes - 60;
    }
    if (hour > 0) {
      return `${hour}H ${minutes.toFixed(2)} Min`;
    } else {
      return `${minutes.toFixed(2)} Min`;
    }
  }
  gotoCourse(course: any) {
    this.appService.selectedCourseToView = course;
    let paramTosend = { ...this.paramOnUrl };
    if (paramTosend['name'] != null && paramTosend['name'] != undefined) {
      delete paramTosend.name;
    }
    this.router.navigate(['/course-content'], {
      queryParams: { name: course['courseSlug'], ...paramTosend },
    });
  }

  async buyCourse(course: any) {
    if (this.appService.userDetail == null) {
      let sgst = 0;
      let cgst = 0;
      let paymentAmount = 0;
      let courseAmount = parseInt(course['coursePrice']);
      // sgst = (9 * courseAmount) / 100;
      // cgst = (9 * courseAmount) / 100;
      paymentAmount = parseFloat((courseAmount + sgst + cgst).toFixed(2));
      if (this.courseDetail['courseType'] == 'Free') {
        let paramTosend = { ...this.paramOnUrl };
        if (paramTosend['name'] != null && paramTosend['name'] != undefined) {
          paramTosend['name'] = undefined;
        }
        this.router.navigate(['/login'], { queryParams: paramTosend });
      } else {
        this.createTransactionWithoutLogin(course);
      }
      // this.router.navigate(['/login']);
    } else {
      if (this.courseDetail['courseType'] == 'Free') {
        this.enrollCourse(course);
      } else {
        if (
          this.appService.razorpayId == null ||
          this.appService.razorpayId == undefined
        ) {
          this.isBuyLoading = true;
          try {
            const param = { type: 'Razorpay' };

            const URL = `${webApi.domain + webApi.v2EndPoint.fetchAppConfig}`;

            await this.appService.postMethod(param, URL).then((data: any) => {
              this.appService.razorpayId = data['result']['value'];
              this.isBuyLoading = false;
              this.createTransaction(course);
            });
          } catch (error) {
            this.showWarningToaster(
              'Some Error occured. Please try after sometime or contact support team.',
              'Error'
            );
            this.isBuyLoading = false;
          }
        } else {
          this.createTransaction(course);
        }
      }
    }
  }
  async createTransactionWithoutLogin(course: any) {
    try {
      let sgst = 0;
      let cgst = 0;
      let paymentAmount = 0;
      let courseAmount = parseInt(course['coursePrice']);
      // sgst = (9 * courseAmount) / 100;
      // cgst = (9 * courseAmount) / 100;
      paymentAmount = parseFloat((courseAmount + sgst + cgst).toFixed(2));
      let utm1: any = {};
      let utm2: any = {};
      for (const key in this.paramOnUrl) {
        if (key == 'utm_medium' || key == 'utm_source' || key == 'utm_campaign' || key == 'utm_content' || key == 'utm_term') {
          utm1[key] = this.paramOnUrl[key];
        } else {
          utm2[key] = this.paramOnUrl[key];
        }
      }
      var param = {
        // userManualId: this.appService['userDetail']['uid'],
        transactionMode: 'ONLINE',
        transactionSource: 'WEB',
        courses: JSON.stringify([
          { courseId: course['_id'], coursePeriod: course['coursePeriod'] },
        ]),
        walletAmount: 0,
        basicAmount: courseAmount,
        paymentAmount: paymentAmount,
        discountedAmount: 0,
        ...utm1, utm_params: utm2
      };
      let URL = `${webApi.domain + webApi.v2EndPoint.enrolPaidCourseWithoutLogin
        }`;
      await this.appService.postMethod(param, URL).then((data: any) => {
        if (data['success']) {
          this.payToRazorPayWithoutLogin(
            course,
            paymentAmount,
            data['transaction']['_id'],
            data['transaction']['rzp_orderId']
          );
        } else {
          this.showWarningToaster(
            'Some Error occured while processing.Please try again',
            'Alert'
          );
        }
      });
    } catch (error) { }
  }
  async createTransaction(course: any) {
    try {
      let sgst = 0;
      let cgst = 0;
      let paymentAmount = 0;
      let courseAmount = parseInt(course['coursePrice']);
      // sgst = (9 * courseAmount) / 100;
      // cgst = (9 * courseAmount) / 100;
      paymentAmount = parseFloat((courseAmount + sgst + cgst).toFixed(2));
      let utm1: any = {};
      let utm2: any = {};
      for (const key in this.paramOnUrl) {
        if (key == 'utm_medium' || key == 'utm_source' || key == 'utm_campaign' || key == 'utm_content' || key == 'utm_term') {
          utm1[key] = this.paramOnUrl[key];
        } else {
          utm2[key] = this.paramOnUrl[key];
        }
      }
      var param = {
        // userManualId: this.appService['userDetail']['uid'],
        transactionMode: 'ONLINE',
        transactionSource: 'WEB',
        courses: JSON.stringify([
          { courseId: course['_id'], coursePeriod: course['coursePeriod'] },
        ]),
        walletAmount: 0,
        basicAmount: courseAmount,
        paymentAmount: paymentAmount,
        discountedAmount: 0,
        ...utm1, utm_params: utm2
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.appService.userDetail['accessToken']}`,
      });
      let URL = `${webApi.domain + webApi.v2EndPoint.enrolPaidCourse}`;
      await this.appService
        .postMethodWithToken(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.payToRazorPay(
              course,
              paymentAmount,
              this.appService['userDetail']['name'],
              this.appService['userDetail']['email'],
              this.appService['userDetail']['phone'],
              data['transaction']['_id'],
              data['transaction']['rzp_orderId']
            );
          } else {
            this.showWarningToaster(
              'Some Error occured while processing.Please try again',
              'Alert'
            );
          }
        });
    } catch (error) { }
  }
  payToRazorPayWithoutLogin(
    course: any,
    paymentAmount: any,
    transactionId: any,
    orderId: any
  ) {
    let options = {
      key: this.appService.razorpayId,
      // key: 'rzp_test_hgSUOMgqR7dw6K',
      amount: paymentAmount * 100,
      currency: 'INR',
      name: course['courseName'],
      order_id: orderId,
      description: course['courseSlug'],
      timeout: 300,
      handler: (res: any) => {
        if (
          res['razorpay_payment_id'] != null &&
          res['razorpay_payment_id'] != undefined
        ) {
          rzp1.close();
          this.openSuccessMsgWithoutLogin(course);
        }
      },
      modal: {
        ondismiss: () => {
          this.updatFailTransaction(transactionId);
          console.log('Checkout form closed');
        },
      },
    };
    var rzp1 = new this.windowRef.Razorpay(options);
    rzp1.open();
    rzp1.on('payment.failed', this._handlePaymentError);
    // //console.log('works');
  }
  payToRazorPay(
    course: any,
    paymentAmount: any,
    name: any,
    email: any,
    phone: any,
    transactionId: any,
    orderId: any
  ) {
    let options = {
      key: this.appService.razorpayId,
      // key: 'rzp_test_hgSUOMgqR7dw6K',
      amount: paymentAmount * 100,
      currency: 'INR',
      name: course['courseName'],
      order_id: orderId,
      description: course['courseSlug'],
      timeout: 300,
      prefill: {
        name: name,
        email: email,
        contact: phone,
      },
      handler: (res: any) => {
        //console.log(res);
        if (
          res['razorpay_payment_id'] != null &&
          res['razorpay_payment_id'] != undefined
        ) {
          rzp1.close();
          this.openSuccessMsg(course);
        }
      },
      modal: {
        ondismiss: () => {
          this.updatFailTransaction(transactionId);
          console.log('Checkout form closed');
        },
      },
    };
    var rzp1 = new this.windowRef.Razorpay(options);
    rzp1.open();
    rzp1.on('payment.failed', this._handlePaymentError);
    // //console.log('works');
  }
  _handlePaymentError(res: any) {
    //console.log(res);
  }
  openSuccessMsgWithoutLogin(course: any) {
    this.appService.loadPaymantPage = true;
    let paramTosend = { ...this.paramOnUrl };
    if (paramTosend['name'] != null && paramTosend['name'] != undefined) {
      paramTosend['name'] = undefined;
    }
    if (course['courseType'] == 'Free') {
      this.router.navigate(['/free-course-confirmation'], { queryParams: paramTosend });
    } else {
      this.router.navigate(['/payment-success'], { queryParams: paramTosend });
    }


    // const dialogConfig = new MatDialogConfig();
    // // dialogConfig.maxHeight = '20em';
    // // dialogConfig.width = '25em';
    // // let dialogRef = this.matDialog.open(PaymentSuccessComponent, dialogConfig);
    // let dialogRef = this.matDialog.open(PaymentSuccessDialogComponent, dialogConfig);
    // dialogRef.updatePosition(dialogConfig.position);
    // dialogRef.afterClosed().subscribe((result) => {
    //   if (result != undefined) {
    //   }
    // });
  }
  openSuccessMsg(course: any) {
    this.appService.loadPaymantPage = true;

    let paramTosend = { ...this.paramOnUrl };
    if (paramTosend['name'] != null && paramTosend['name'] != undefined) {
      paramTosend['name'] = undefined;
    }
    if (course['courseType'] == 'Free') {
      this.router.navigate(['/free-course-confirmation'], { queryParams: paramTosend });
    } else {
      this.router.navigate(['/payment-success'], { queryParams: paramTosend });
    }
    // const dialogConfig = new MatDialogConfig();
    // // dialogConfig.maxHeight = '20em';
    // // dialogConfig.width = '25em';
    // let dialogRef = this.matDialog.open(
    //   PaymentSuccessDialogComponent,
    //   dialogConfig
    // );
    // dialogRef.updatePosition(dialogConfig.position);
    // dialogRef.afterClosed().subscribe((result) => {
    //   if (result != undefined) {
    //   }
    // });
  }
  async updatFailTransaction(transactionId: any) {
    try {
      var param = {
        transactionId: transactionId,
      };

      let URL = `${webApi.domain + webApi.v2EndPoint.enrolmentFailedWithoutLogin
        }`;
      await this.appService.putMethod(param, URL).then((data: any) => {
        if (data['success']) {
          window.location.reload();
        } else {
          window.location.reload();
        }
      });
    } catch (error) {
      console.log(error);
      window.location.reload();
    }
  }
  async enrollCourse(course: any) {
    try {
      var param = {
        courseId: course['_id'],
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.appService.userDetail['accessToken']}`,
      });
      let URL = `${webApi.domain + webApi.v2EndPoint.enrolFreeCourse}`;
      await this.appService
        .postMethodWithToken(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.openSuccessMsg(course);
          } else {
            this.showWarningToaster(
              'Unable to enroll course at this moment.Please try again',
              'Alert'
            );
          }
        });
    } catch (error) {
      this.showWarningToaster(
        'Unable to enroll course at this moment.Please try again',
        'Alert'
      );
    }
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
