import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PlayDemoVideoDialogComponent } from '../play-demo-video-dialog/play-demo-video-dialog.component';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent implements OnInit {
  @Input() moduleDetail: any;
  isChecked: boolean = false;
  constructor(private matDialog: MatDialog) {}

  ngOnInit(): void {}

  openIntroVideo(activity: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { activity: activity };
    dialogConfig.maxHeight="40em";
    dialogConfig.width="80%";
    let dialogRef = this.matDialog.open(
      PlayDemoVideoDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {});
  }

  getLectureCount() {
    let lctrCount: number = 0;
    this.moduleDetail['activity'].forEach((act: any) => {
      if (act['type'] == 'Video') {
        lctrCount++;
      }
    });
    return lctrCount;
  }
  getLectureDuration() {
    let lctrDuration: number = 0;

    this.moduleDetail['activity'].forEach((act: any) => {
      if (act['type'] == 'Video') {
        lctrDuration =
          lctrDuration + parseInt(act['videoContent']['duration']);
      }
    });
    let hour = 0;
    while (lctrDuration >= 60) {
      hour++;
      lctrDuration = lctrDuration - 60;
    }
    if (hour > 0) {
      return `${hour}H ${lctrDuration} Min`;
    } else {
      return `${lctrDuration} Min`;
    }
  }
  getDuration(minutes: number) {
    let hour = 0;
    while (minutes >= 60) {
      hour++;
      minutes = minutes - 60;
    }
    if (hour > 0) {
      return `${hour}H ${minutes.toFixed(2)} Min`;
    } else {
      return `${minutes.toFixed(2)} Min`;
    }
  }
}
