<div class="quiz">
    <div *ngIf="isLoading" class="loader-div">
        <div class="loader">
        </div>
    </div>
    <div *ngIf="!isLoading" class="quiz-wrap">


        <div class="quiz-wrap-title display-flex-space-between-flex-start">
            <div>{{activityDetail['activityName']}}</div>
            <div>
                <button *ngIf="!showResult" class="submit-btn" (click)="submitQuizForcefully()">{{'Submit\
                    Quiz'}}</button>
            </div>
        </div>
        <p>Course: <span> {{courseDetail['courseName']}}</span></p>
        <div class="Quiz-Detail">
            <div class=""><span class="detail">Questions: </span><span>
                    {{activityDetail['quizContent'].length}}</span></div>
            <div class=""><span class="detail">Attempts Allowed: </span><span> N.A.</span></div>
            <div class=""><span class="detail">Attempted: </span><span> {{attemptedCount}}</span></div>
            <div class=""><span class="detail">Attempts Remaining: </span><span> N.A.</span></div>
            <div class=""><span class="detail">Passing Grade: </span><span> 35%</span></div>
        </div>
        <div *ngIf="!showResult" class="quiz-question">
            <div class="quiz-question-wrap">
                <p class="questions" [innerHtml]="activityDetail['quizContent'][selectedQuestionIndex]['question']">
                </p>
                <div class="answer-options">
                    <div class="option" *ngFor="let option of activityDetail['quizContent'][selectedQuestionIndex]['options']" (click)="selectAnswerHandler(option['_id'])">
                        <input type="radio" [id]="activityDetail['_id']+'-'+activityDetail['quizContent'][selectedQuestionIndex]['localActivityQuizId']+'-'+option['_id']" [name]="activityDetail['_id']+'-'+activityDetail['quizContent'][selectedQuestionIndex]['localActivityQuizId']"
                            [checked]="option['isSelected']" />
                        <p [innerHtml]="option['value']"></p>
                    </div>

                </div>
                <span *ngIf="!activityDetail['quizContent'][this.selectedQuestionIndex]['isValid']" class="helper-text">Please at least select one option to answer</span>
            </div>
            <div class="quiz-btn">
                <button class="btn-back" (click)="gotoPreviousPosition()">Previous</button>
                <button class="next-btn" (click)="selectedQuestionIndex+1
                >= activityDetail['quizContent'].length? submitQuiz(): gotoNextPosition()">{{selectedQuestionIndex+1
                    >= activityDetail['quizContent'].length ? 'Submit' :'Save & Next'}}</button>
            </div>
        </div>
        <div *ngIf="showResult">
            <div class="quiz-btn">
                <button class="next-btn" (click)="startQuizHandler()">{{'Re-Attempt'}}</button>
            </div>
            <div>
                <div class="Quiz-attempt-header-Detail">

                    <div style="width: 15%;align-items: center;"><span class="span-detail">#: </span> </div>
                    <div style="width: 17%;align-items: center;"><span class="span-detail">Date: </span> </div>
                    <div style="width: 15%;align-items: center;"><span class="span-detail">Questions: </span></div>
                    <div style="width: 17%;align-items: center;"><span class="span-detail">Total Mark: </span></div>
                    <div style="width: 20%;align-items: center;"><span class="span-detail">Earned Marks: </span>
                    </div>
                </div>

                <div class="Quiz-attempt-content-Detail" *ngFor="let attempt of attemptedDetails;let i=index;">
                    <div style="width: 15%;align-items: center;"> <span class="m-10px">
                            {{i+1}}</span></div>
                    <div style="width: 17%;align-items: center;"> <span class="m-10px"> {{attempt['logDate'] |
                            date}}</span></div>
                    <div style="width: 15%;align-items: center;"> <span class="m-10px">
                            {{attempt['activityContent'].length}}</span></div>
                    <div style="width: 17%;align-items: center;"> <span class="m-10px">
                            {{attempt['totalMark']}}</span></div>
                    <div style="width: 20%;align-items: center;"><span class="m-10px">
                            {{attempt['earnedMark']}}</span></div>
                </div>
                <div style="border-top: 1px solid rgb(228, 228, 228);">

                </div>
            </div>
        </div>


    </div>

</div>