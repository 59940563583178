import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-play-demo-video-dialog',
  templateUrl: './play-demo-video-dialog.component.html',
  styleUrls: ['./play-demo-video-dialog.component.scss']
})
export class PlayDemoVideoDialogComponent implements OnInit {

  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();
  isLoading:boolean=true;
  constructor(
    public dialogRef: MatDialogRef<PlayDemoVideoDialogComponent>,
    private toastr: ToastrService,
    public as: AppService,
    protected _sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    // dialogRef.disableClose = true;
    //console.log('dialog called');
    //console.log(this.data);

    // this.data['activity']['activityContent']['link']=_sanitizer.bypassSecurityTrustUrl( this.data['activity']['activityContent']['link']);
    // //console.log(this.data['activity']['activityContent']['link']);
    this.isLoading=false;

  }
  ngOnInit(): void {}
  getUrl() {
    return this._sanitizer.bypassSecurityTrustResourceUrl(this.data['activity']['videoContent']['webLink']??'https://player.vimeo.com/video/661560753?h=0f54afc604');
  }
  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }
 
}
