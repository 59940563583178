import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CourseDetailComponent } from './course-detail/course-detail.component';
import { CourseListComponent } from './course-list/course-list.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { MyCourseComponent } from './my-course/my-course.component';
import { CourseContentComponent } from './course-content/course-content.component';
import { ProfileComponent } from './profile/profile.component';
import { NotificationComponent } from './notification/notification.component';
import { QuizDetailComponent } from './quiz-detail/quiz-detail.component';
import { PasswordLoginComponent } from './password-login/password-login.component';
import { PaymentSuccessDialogComponent } from './payment-success-dialog/payment-success-dialog.component';
import { PaymentSuccessForFreeComponent } from './payment-success-for-free/payment-success-for-free.component';

const routes: Routes = [
  {
    path: 'course',
    component: CourseListComponent,
  },
  {
    path: 'course-detail',
    component: CourseDetailComponent,
  },
  {
    path: 'quiz-detail',
    component: QuizDetailComponent,
  },
  {
    path: 'course-content',
    component: CourseContentComponent,
  },
  {
    path: 'payment-success',
    component: PaymentSuccessDialogComponent,
  },
  {
    path: 'free-course-confirmation',
    component: PaymentSuccessForFreeComponent,
  },
  {
    path: 'my-course',
    component: MyCourseComponent,
  },
  {
    path: 'profile',
    component: ProfileComponent,
  },
  {
    path: 'notification',
    component: NotificationComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'password',
    component: PasswordLoginComponent,
  },

  {
    path: 'signup',
    component: SignupComponent,
  },
  { path: '', pathMatch: 'full', redirectTo: 'layout/course' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LayoutRoutingModule {}
