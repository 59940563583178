import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  saveEnabled: boolean = false;

  // Profile Image
  selectedProfileImage: any = null;
  selectedProfileImageName: any = null;
  defaultProfileImage: any;

  userName: string = '';
  countryCode: string = '';
  phone: string = '';
  email: string = '';
  userDetail: any;

  myHeaders = new Headers();
  formdata = new FormData();
  constructor(
    public routes: ActivatedRoute,
    private router: Router,
    public appService: AppService,
    private toastr: ToastrService
  ) {
    this.userDetail = JSON.parse(localStorage.getItem('TG-USER-DASHBOARD')!);
    if (this.userDetail != null) {
      this.userName = this.userDetail['name'];
      this.email = this.userDetail['email'];
      this.countryCode =
        this.userDetail['countryCode'] != null &&
        this.userDetail['countryCode'] != undefined
          ? '+' + this.userDetail['countryCode']
          : '+91';
      this.phone = this.userDetail['phone'];
      this.defaultProfileImage = this.userDetail['image']
        ? this.userDetail['image']
        : null;
      if (this.userName != '' && this.email != '' && this.phone != '') {
        this.saveEnabled = true;
      }
    } else {
      this.router.navigate(['/course'],{queryParamsHandling: 'preserve'});
    }
  }

  ngOnInit(): void {}
  nameChange(event: any) {
    if (this.userName != '' && this.email != '' && this.phone != '') {
      this.saveEnabled = true;
    } else {
      this.saveEnabled = false;
    }
  }
  displayProfilePicture(event: any) {
    this.selectedProfileImage = event.target.files[0];
    this.selectedProfileImageName = this.selectedProfileImage['name'];
    var reader = new FileReader();
    reader.onload = (event1: any) => {
      this.defaultProfileImage = event1.target.result;
    };
    reader.readAsDataURL(this.selectedProfileImage);
  }
  async saveButtonHandler() {
    try {
      // this.formdata.set('userManualId', this.userDetail['uid']);
      this.formdata.set('phone', this.phone);
      this.formdata.set('email', this.email);
      this.formdata.set('fullName', this.userName);
      if (this.selectedProfileImage) {
        this.formdata.set('image', this.selectedProfileImage);
      }
      this.myHeaders.set(
        'Authorization',
        `Bearer ${this.appService.userDetail['accessToken']}`
      );
      var requestOptions: RequestInit = {
        method: 'PUT',
        headers: this.myHeaders,
        body: this.formdata,
        redirect: 'follow',
      };

      let URL = `${webApi.domain + webApi.v2EndPoint.updateProfileFromUserDashboard}`;

      fetch(URL, requestOptions).then((result) => {
        if (result) {
          let data = result.json();
          data.then((res) => {
            if (res['success']) {
              localStorage.setItem(
                'TG-USER-DASHBOARD',
                JSON.stringify({
                  accessToken: this.appService.userDetail['accessToken'],
                  name: res['result']['fullName'],
                  phone: res['result']['phone'],
                  email: res['result']['email'],
                  uid: this.userDetail['uid'],
                  image: res['result']['avatar'],
                })
              );
              this.appService.updatdUserDetailFromLocalStorage();

              this.userName = res['result']['fullName'];
              this.email = res['result']['email'];
              this.phone = res['result']['phone'];
              this.defaultProfileImage = res['result']['avatar']
                ? res['result']['avatar']
                : null;

              this.showSuccessToaster(
                'Profile Updated Successfully',
                'Success'
              );
            } else {
              this.showWarningToaster(
                'Unable to update profile.Try again',
                'Warning'
              );
            }
          });
        }
      });
    } catch (error) {
      this.showWarningToaster('Unable to update profile.Try again', 'Warning');
    }
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
