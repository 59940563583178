<div *ngIf="appService.userDetail==null" class="padding-set">
    <p class="login-signup" (click)="gotoLogin()">Login / Signup</p>
    <!-- <p class="login-signup" (click)="gotoSignup()">Sign up</p> -->
</div>
<div *ngIf="appService.userDetail!=null" style="background-color: #e8e8e8;">

    <div class="padding-set display-flex-align-center">
        <div style="width: 4.5em;">
            <app-avatar size="extra-medium" [showStatusIndicator]="true" shape="circular"
                [url]="appService.userDetail['image']==null||appService.userDetail['image']==undefined||appService.userDetail['image']==''? '../../../assets/imgs/avatar-1.jpg':appService.userDetail['image']">
            </app-avatar>
        </div>
        <div>
            <div class="name">Hi, {{appService.userDetail['name']}}</div>
            <div class="welcome-back">Welcome back</div>
        </div>
    </div>
</div>
<div *ngIf="appService.userDetail==null" style="border-top: solid 1px grey;"></div>
<div *ngIf="appService.userDetail!=null" class="padding-set">
    <div class="tag">Activity</div>
    <div class="text" (click)="gotoMyLearning()"><span>My Courses</span> <img style="height: 20px;width: 20px;"
            src="../../../assets/imgs/next.png" alt="" srcset=""></div>
    <div class="text" (click)="gotoMyNotification()"><span>Notification</span> <img style="height: 20px;width: 20px;"
            src="../../../assets/imgs/next.png" alt="" srcset=""></div>
    <div class="text" (click)="gotoAllCourse()"><span>All Course</span> <img style="height: 20px;width: 20px;"
            src="../../../assets/imgs/next.png" alt="" srcset=""></div>
    <div class="text" (click)="gotoMyProfile()"><span>Manage Profile</span> <img style="height: 20px;width: 20px;"
            src="../../../assets/imgs/next.png" alt="" srcset=""></div>
</div>
<div *ngIf="appService.userDetail!=null" style="border-top: solid 1px grey;"></div>
<div class="padding-set">
    <div class="tag">Activity</div>
    <div class="text" (click)="gotoLink('https://play.google.com/store/apps/details?id=com.tusharghone.app')"><span>Get
            the App</span> <img style="height: 20px;width: 20px;" src="../../../assets/imgs/next.png" alt="" srcset="">
    </div>
    <div class="text" (click)="gotoLink('https://play.google.com/store/apps/details?id=com.tusharghone.app')">
        <span>Invite Friends</span> <img style="height: 20px;width: 20px;" src="../../../assets/imgs/next.png" alt=""
            srcset=""></div>
    <div class="text" (click)="gotoLink('https://wa.me/+919152615121')"><span>Contact us</span> <img
            style="height: 20px;width: 20px;" src="../../../assets/imgs/next.png" alt="" srcset=""></div>
    <div class="text" (click)="gotoLink('mailto:contact@tusharghone.com')"><span>Help</span><img
            style="height: 20px;width: 20px;" src="../../../assets/imgs/next.png" alt="" srcset=""></div>
    <div class="text"><span style="margin-right: 1em;">Customer Care:- <a href="https://wa.me/+919152615121">9152615121</a> </span></div>
</div>

<div *ngIf="appService.userDetail!=null" class="padding-set">
    <app-button (callBackFunction)="logoutButtonHandler($event)" margin="0px 10px 0px 0px" size="medium" fontSize="15px"
        shape="rectangular" type="outline" text="Log out" bgColor="#030303">
    </app-button>
</div>