<div class="page-content">
    <div class="container-fluid">

        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 class="mb-sm-0">Alerts</h4>
                </div>
            </div>
        </div>
        <!-- end page title -->


        <div class="row">
            <div class="col-xl-6">
                <div class="card1">
                    <div class="card1-body">
                        <h4 class="card1-title">Main Alerts</h4>
                        <p class="card1-title-desc">Alerts are available for any length of text, as well as an optional dismiss button. For proper styling, use one of the four <strong>required</strong> contextual classes (e.g., <code class="highlighter-rouge">.alert, .alert-title</code>).
                        </p>
                        <div class="alert">
                            <div class="display-flex align-items-center">
                                <div class="alert-prefix-icon-div">
                                    <img class="alert-prefix-icon" src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e0/SNice.svg/1200px-SNice.svg.png">
                                </div>
                                <div class="width-75-percent">
                                    <div class="alert-title">
                                        Text For the Alert! </div>
                                </div>
                            </div>
                            <i class="mdi mdi-arrow-right me-2" src=""></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-6">
                <div class="card1">
                    <div class="card1-body">
                        <h4 class="card1-title">Main Text Alerts</h4>
                        <p class="card1-title-desc">Alerts are available for any length of text, as well as an optional dismiss button. For proper styling, use one of the four <strong>required</strong> contextual classes (e.g., <code class="highlighter-rouge">.alert, .alert-title, .alert-desc</code>).
                        </p>
                        <div class="alert">
                            <div class="display-flex align-items-center">
                                <div class="alert-prefix-icon-div">
                                    <img class="alert-prefix-icon" src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e0/SNice.svg/1200px-SNice.svg.png">
                                </div>
                                <div class="width-75-percent">
                                    <div class="alert-title">
                                        Text For the Alert! </div>
                                    <div class="alert-desc">
                                        Looks that you exceeded your limit.
                                    </div>
                                </div>
                            </div>
                            <i class="mdi mdi-arrow-right me-2" src=""></i>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-xl-6">
                <div class="card1">
                    <div class="card1-body">
                        <h4 class="card1-title">Main Solid Alerts</h4>
                        <p class="card1-title-desc">Alerts are available for any length of text, as well as an optional dismiss button. For proper styling, use one of the four <strong>required</strong> contextual classes (e.g., <code class="highlighter-rouge">.alert-solid-primary</code>).
                        </p>
                        <div class="alert-solid-primary">
                            <div class="display-flex align-items-center">
                                <div class="alert-prefix-icon-div">
                                    <img class="alert-prefix-icon" src="../../../assets/Icons/png/gift.png">
                                </div>
                                <div class="width-75-percent">
                                    <div class="alert-title">
                                        A short alert message here… </div>
                                    <div class="alert-desc">
                                        Looks that you exceeded your limit.
                                    </div>
                                </div>

                            </div>
                            <i class="mdi mdi-arrow-right me-2" src=""></i>
                        </div>
                        <div class="alert-solid-success">
                            <div class="display-flex align-items-center">
                                <div class="alert-prefix-icon-div">
                                    <img class="alert-prefix-icon" src="../../../assets/Icons/png/gift.png">
                                </div>
                                <div class="width-75-percent">
                                    <div class="alert-title">
                                        A short alert message here… </div>
                                </div>
                            </div>
                            <i class="mdi mdi-arrow-right me-2" src=""></i>
                        </div>
                        <div class="alert-solid-warning">
                            <div class="display-flex align-items-center">
                                <div class="alert-prefix-icon-div">
                                    <img class="alert-prefix-icon" src="../../../assets/Icons/png/gift.png">
                                </div>
                                <div class="width-75-percent">
                                    <div class="alert-title">
                                        A short alert message here… </div>
                                </div>
                            </div>
                            <i class="mdi mdi-arrow-right me-2" src=""></i>
                        </div>
                        <div class="alert-solid-danger">
                            <div class="display-flex align-items-center">
                                <div class="alert-prefix-icon-div">
                                    <img class="alert-prefix-icon" src="../../../assets/Icons/png/gift.png">
                                </div>
                                <div class="width-75-percent">
                                    <div class="alert-title">
                                        A short alert message here… </div>
                                </div>
                            </div>
                            <i class="mdi mdi-arrow-right me-2" src=""></i>
                        </div>
                        <div class="alert-solid-secondary">
                            <div class="display-flex align-items-center">
                                <div class="alert-prefix-icon-div">
                                    <img class="alert-prefix-icon" src="../../../assets/Icons/png/gift.png">
                                </div>
                                <div class="width-75-percent">
                                    <div class="alert-title">
                                        A short alert message here… </div>
                                </div>
                            </div>
                            <i class="mdi mdi-arrow-right me-2" src=""></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-6">
                <div class="card1">
                    <div class="card1-body">
                        <h4 class="card1-title">Main Gradient Alerts</h4>
                        <p class="card1-title-desc">Alerts are available for any length of text, as well as an optional dismiss button. For proper styling, use one of the four <strong>required</strong> contextual classes (e.g., <code class="highlighter-rouge">.alert-gradient-primary</code>).
                        </p>
                        <div class="alert-gradient-primary">
                            <div class="display-flex align-items-center">
                                <div class="alert-prefix-icon-div">
                                    <img class="alert-prefix-icon" src="../../../assets/Icons/png/check.png">
                                </div>
                                <div class="width-75-percent">

                                    <div class="alert-title">
                                        A short alert message here.A short alert A short alert message here.A short alert al alert alert message here… </div>
                                    <div class="alert-desc">
                                        Looks that you exceeded your limit,Looks that that that you exceeded your Looks that you exceeded your limit,Looks that ur limit,Looks that that that you exceeded your limit.
                                    </div>
                                </div>

                            </div>
                            <i class="mdi mdi-arrow-right me-2" src=""></i>
                        </div>
                        <div class="alert-gradient-success">
                            <div class="display-flex align-items-center">
                                <div class="alert-prefix-icon-div">
                                    <img class="alert-prefix-icon" src="../../../assets/Icons/png/check.png">
                                </div>
                                <div class="width-75-percent">

                                    <div class="alert-title">
                                        A short alert message here… </div>
                                </div>

                            </div>
                            <i class="mdi mdi-arrow-right me-2" src=""></i>
                        </div>
                        <div class="alert-gradient-warning">
                            <div class="display-flex align-items-center">
                                <div class="alert-prefix-icon-div">
                                    <img class="alert-prefix-icon" src="../../../assets/Icons/png/check.png">
                                </div>
                                <div class="width-75-percent">
                                    <div class="alert-title">
                                        A short alert message here… </div>
                                </div>
                            </div>
                            <i class="mdi mdi-arrow-right me-2" src=""></i>
                        </div>
                        <div class="alert-gradient-danger">
                            <div class="display-flex align-items-center">
                                <div class="alert-prefix-icon-div">
                                    <img class="alert-prefix-icon" src="../../../assets/Icons/png/check.png">
                                </div>
                                <div class="width-75-percent">
                                    <div class="alert-title">
                                        A short alert message here… </div>
                                </div>
                            </div>
                            <i class="mdi mdi-arrow-right me-2" src=""></i>
                        </div>
                        <div class="alert-gradient-secondary">
                            <div class="display-flex align-items-center">
                                <div class="alert-prefix-icon-div">
                                    <img class="alert-prefix-icon" src="../../../assets/Icons/png/check.png">
                                </div>
                                <div class="width-75-percent">
                                    <div class="alert-title">
                                        A short alert message here… </div>
                                </div>
                            </div>
                            <i class="mdi mdi-arrow-right me-2" src=""></i>
                        </div>
                    </div>
                </div>
            </div>

        </div>



    </div>
    <!-- container-fluid -->
</div>
<div class="alert-div">
    <div class="alert">
        <div class="display-flex align-items-center">
            <div class="alert-prefix-icon-div">
                <img class="alert-prefix-icon" src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e0/SNice.svg/1200px-SNice.svg.png">
            </div>
            <div class="width-75-percent">
                <div class="alert-title">
                    Text For the Alert! </div>
            </div>
        </div>
        <i class="mdi mdi-arrow-right me-2" src=""></i>
    </div>
</div>