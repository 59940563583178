<div class="profile">
    <div class="profile-wrapper">
        <h1 class="profile-title">Profile & settings</h1>
        <div class="profile-edit">
            <form class="profile-edit-form">
                <div class="profile-edit-form-wrap">
                    <div class="profile-edit-form-left">
                        <div class="input-field">
                            <label for="username">Name:</label>
                            <input (input)="nameChange($event)" placeholder="Enter your Name" type="text" id="username" [(ngModel)]="userName" [ngModelOptions]="{standalone: true}" />
                        </div>

                        <div class="input-field">
                            <label for="email">Email:</label>
                            <input placeholder="Enter your Email" type="email" id="email" [(ngModel)]="email" readonly [ngModelOptions]="{standalone: true}" />
                        </div>
                        <div class="input-field">
                            <label for="PhoneNumber">Phone Number:</label>
                            <div class="display-flex-space-between">
                                <input class="input-cls" style="width: 20%;" type="text" [(ngModel)]="countryCode" readonly [ngModelOptions]="{standalone: true}"> <input class="input-cls" style="width: 75%;" placeholder="Enter your Headline" type="number"
                                    id="PhoneNumber" [(ngModel)]="phone" readonly [ngModelOptions]="{standalone: true}" />
                            </div>
                        </div>
                    </div>
                    <div class="profile-edit-form-right">
                        <div class="profiledefault-img">
                            <div class="profiledefault-img-wrapper">
                                <img *ngIf="defaultProfileImage==null" src="https://img-c.udemycdn.com/user/200_H/anonymous_3.png" alt="" class="profiledefault-picture" />
                                <img *ngIf="defaultProfileImage!=null" [src]="defaultProfileImage" alt="" class="profiledefault-picture" />
                            </div>
                        </div>
                        <div>
                            <label for="input-file" class="profile-img">Upload Image</label>
                            <input (change)="displayProfilePicture($event)" type="file" style="display: none;" id="input-file" />
                        </div>
                    </div>
                </div>
                <button (click)="(saveEnabled)? saveButtonHandler():null" class="savebtn" [ngStyle]="{'background-color':(saveEnabled)?'#482ff7':'#978af1'}">Save</button>

            </form>
        </div>


    </div>

</div>