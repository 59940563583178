<div class="container">
    <div class="help-txt display-flex-center">
        Log in and Start Learning!
    </div>

    <div class="form-group-field" [ngStyle]="containerBgColor">
        <div class="form-group-field-wrap">
            <div class="form-group-top top" [ngStyle]="headStyle">
                <h1>Login</h1>
                <p style="margin-top: 1em;">it's free and easy to access</p>
            </div>
            <form class="form-group-bottom" [formGroup]="loginForm">

                <div class="form-group-input">
                    <label class="required">Enter Email</label>
                    <input placeholder="Email" [ngStyle]="input" type="email" formControlName="email" />
                </div>
                <div class="form-group-input">
                    <label class="required">Enter Password</label>
                    <input placeholder="Password" [ngStyle]="input" type="password" formControlName="password" />
                </div>

                <div class="form-group-alert-message">
                    <input type="checkbox" [(ngModel)]="terms" [ngModelOptions]="{standalone: true}">
                    <label>By creating an account means you agree to the Teams and conditions, and our Privacy policy
                    </label>
                </div>
                <button (click)="login()"
                    [ngClass]="{'btn-disabled-bgColor':loginForm.invalid || !terms,'btn-bgColor':loginForm.valid&& terms}"
                    [disabled]="loginForm.invalid || !terms" [ngStyle]="btn">{{'Login'}}</button>

            </form>

        </div>
    </div>

</div>
