export const webApi = {
  // domain: 'http://localhost:7040', // development
  // domain: 'http://api.tusharghone.com:7046', // production initial release
  domain: 'https://apiv2.tusharghone.com', //production

  endPoint: {
    //OTP
    sendOTPtoUser: '/api/auth/sendOTPtoUser',
    verifyOTPofUser: '/api/auth/verifyOTPofUser',
    resendOTPtoUser: '/api/auth/resendOTPtoUser',
    loginWithPassword:"/api/user/loginWithPassword",


    // Transaction
    createTransaction: '/api/appUser/transaction/createTransaction',
    fetchRazorpay: '/api/admin/course/fetchRazorpay',
    enrollCourse: '/api/appUser/user/enrollCourse',

    // User

    checkForUser: '/api/appUser/user/checkForUser',
    insertUserDetail: '/api/appUser/user/insertUserDetail',
    insertUserDetailForDashboardUser:
      '/api/appUser/user/insertUserDetailForDashboardUser',
    updateUserWhileSignin: '/api/appUser/user/updateUserWhileSignin',
    updateUser: '/api/appUser/user/updateUser',

    //Fetch Course
    fetchCourse: '/api/appUser/course/fetchCourse',
    fetchCourseById: '/api/appUser/course/fetchCourseById',
    fetchCourseBySlug: '/api/appUser/course/fetchCourseBySlug',
    fetchEnrolledCourse: '/api/appUser/course/fetchEnrolledCourse',
    fetchCourseForUserDashboard:
      '/api/appUser/course/fetchCourseForUserDashboard',
    fetchCourseForUserDashboardForTest:
      '/api/appUser/course/fetchCourseForUserDashboardForTest',
    createCourseCertificate: '/api/appUser/course/createCourseCertificate',

    //Review

    //Fetch Author
    fetchAuthor: '/api/appUser/author/fetchAuthor',
    fetchCourseByAuthorId: '/api/appUser/course/fetchCourseByAuthorId',

    //QUIZ
    fetchQuiz: '/api/appUser/quiz/fetchQuiz',
    insertQuizAnalytic: '/api/appUser/activityLog/insertQuizAnalytic',
    fetchQuizAnalyticForUserDashboard:
      '/api/appUser/activityLog/fetchQuizAnalyticForUserDashboard',

    //NOTIFICATION
    fetchNotification: '/api/appUser/notification/fetchNotification',
    updateNotification: '/api/appUser/notification/updateNotification',

    //ACTIVITY LOG
    insertActivityLog: '/api/appUser/activityLog/insertActivityLog',
    insertVideoActivityLogFromUserDashboard:
      '/api/appUser/activityLog/insertVideoActivityLogFromUserDashboard',
    insertActivityLogFromUserDashboard:
      '/api/appUser/activityLog/insertActivityLogFromUserDashboard',
    fetchActivityLogForUserDashboard:
      '/api/appUser/activityLog/fetchActivityLogForUserDashboard',
    fetchAttemptCount: '/api/appUser/activityLog/fetchAttemptCount',
    insertCurrentActivity: '/api/appUser/activityLog/insertCurrentActivity',
    fetchCurrentActivity: '/api/appUser/activityLog/fetchCurrentActivity',
  },
  v2EndPoint: {
    //OTP
    sendOTPtoUser: '/api/auth/sendOTPtoUser',
    verifyOTPofUser: '/api/auth/verifyOTPofUser',
    resendOTPtoUser: '/api/auth/resendOTPtoUser',

    // App Config
    fetchAppConfig: '/api/appConfig/fetchAppConfig',

    // Enrolment
    enrolFreeCourse: '/api/enrolment/enrolFreeCourse',
    enrolPaidCourse: '/api/enrolment/enrolPaidCourse',
    enrolPaidCourseWithoutLogin: '/api/enrolment/enrolPaidCourseWithoutLogin',
    enrolmentFailedWithoutLogin: '/api/enrolment/enrolmentFailedWithoutLogin',
    createCourseCertificateFromUserDashboard: '/api/enrolment/createCourseCertificateFromUserDashboard',

    // Transaction
    createTransaction: '/api/appUser/transaction/createTransaction',
    fetchRazorpay: '/api/admin/course/fetchRazorpay',

    // User

    checkForUser: '/api/user/checkForUserDashBoard',
    checkForUserWithEmailInDashBoard: '/api/user/checkForUserWithEmailInDashBoard',
    updateUserWhileSignin: '/api/user/updateUserWhileSignin',
    updateProfileFromUserDashboard: '/api/user/updateProfileFromUserDashboard',

    //Fetch Course
    fetchCourseBySlug: '/api/course/fetchCourseBySlug',
    fetchEnrolledCourseForUserDashboard:
      '/api/course/fetchEnrolledCourseForUserDashboard',
    fetchCoursesForUserDashBoard: '/api/course/fetchCoursesForUserDashBoard',
    fetchCoursesForUserDashBoardForTest:
      '/api/course/fetchCoursesForUserDashBoardForTest',

    //Review

    //Fetch Author
    fetchAuthor: '/api/appUser/author/fetchAuthor',
    fetchCourseByAuthorId: '/api/appUser/course/fetchCourseByAuthorId',

    //QUIZ
    fetchAllQuizForUserDashboard: '/api/quiz/fetchAllQuizForUserDashboard',
    insertQuizAnalytics: '/api/quizAnalytics/insertQuizAnalytics',
    fetchQuizAnalyticForUserDashboard:
      '/api/quizAnalytics/fetchQuizAnalyticForUserDashboard',

    //NOTIFICATION
    fetchUserNotifcationForUserDashboard: '/api/userNotification/fetchUserNotifcationForUserDashboard',
    updateViewState: '/api/userNotification/updateViewState',

    //ACTIVITY LOG
    insertCourseActivityLog: '/api/courseActivityLog/insertCourseActivityLog',
    insertVideoActivityLogFromUserDashboard:
      '/api/courseActivityLog/insertVideoActivityLogFromUserDashboard',
    insertActivityLogFromUserDashboard:
      '/api/courseActivityLog/insertActivityLogFromUserDashboard',
    fetchAttemptCount: '/api/courseActivityLog/fetchAttemptCount',

    fetchActivityLogForUserDashboard:
      '/api/courseActivityLog/fetchActivityLogForUserDashboard',

    // Current Activity

    insertCurrentActivityLog: '/api/currentActivity/insertCurrentActivityLog',
    fetchCurrentActivity: '/api/currentActivity/fetchCurrentActivity',
  },
};
