<div class="side-course-content">
  <input
    type="checkbox"
    id="{{ moduleDetail['_id'] + postion }}"
    class="side-course-content-checkbox"
    [(ngModel)]="isChecked"
  />
  <label
    for="{{ moduleDetail['_id'] + postion }}"
    (click)="onModuleTap(moduleDetail['_id'])"
  >
    <div class="side-course-content-wrap">
      <div class="course-topic">
        <h3>{{ moduleDetail["name"] }}</h3>
        <div>
          <span>{{ getLectureCount() }} lectures </span
          ><span>{{ getLectureDuration() }}</span>
        </div>
      </div>
      <img
        style="height: 15px; width: 15px"
        [src]="
          !isChecked
            ? '../../../assets/imgs/down-arrow.png'
            : '../../../assets/imgs/up-arrow.png'
        "
        alt=""
      />
    </div>
  </label>
  <div
    class="side-course-sub-content"
    [ngStyle]="{ display: isChecked ? 'block' : 'none' }"
  >
    <div
      class="main-side-course-content"
      *ngFor="let activity of moduleDetail['activity']; let i = index"
      [ngStyle]="{
        'background-color':
          selectedActivity == activity['_id'] ? '#d1d7dc' : 'transparent'
      }"
    >
      <input
        style="margin-top: 3px; margin-right: 10px; cursor: pointer"
        (change)="onActivityCompleteTap($event, activity)"
        type="checkbox"
        [checked]="activity['isCompleted']"
        [title]="
          activity['type'] == 'Quiz'
            ? activity['isCompleted']
              ? ''
              : 'Progress Cannot be changes for this item'
            : ''
        "
        [disabled]="activity['type'] == 'Quiz' || activity['isCompleted']"
        [ngStyle]="{
          cursor: activity['type'] == 'Quiz' ? 'not-allowed' : 'pointer'
        }"
        [name]="activity['_id'] + postion"
        [id]="activity['_id'] + postion"
      />
      <div (click)="onActivityTap(moduleDetail['_id'], activity['_id'])">
        <div class="course-topic-topic-list" style="cursor: pointer">
          <div>{{ activity["name"] }}</div>
        </div>
        <div
          class="course-topic-topic-list"
          style="align-items: inherit; margin-top: 5px"
        >
          <label>
            <img
              class="icon-style"
              [src]="
                activity['type'] == 'Video'
                  ? '../../../assets/imgs/play-button.png'
                  : '../../../assets/imgs/file.png'
              "
              alt=""
            />
          </label>
          <div
            class="duration"
            style="margin-left: 10px"
            *ngIf="activity['type'] == 'Video'"
          >
            <span style="font-size: 10px">{{
              getDuration(activity["videoContent"]["duration"])
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
