import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-checks',
  templateUrl: './form-checks.component.html',
  styleUrls: ['./form-checks.component.scss']
})
export class FormChecksComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
