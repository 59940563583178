import { HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { QuizAlertDialogComponent } from '../quiz-alert-dialog/quiz-alert-dialog.component';
import * as Hls from 'hls.js';

// declare let Hls: any;

@Component({
  selector: 'app-course-content',
  templateUrl: './course-content.component.html',
  styleUrls: ['./course-content.component.scss'],
})
export class CourseContentComponent implements OnInit {
  @ViewChild('videoPlayer', { static: false }) videoPlayerElementRef: | ElementRef
    | undefined;
  // @ViewChild('certificatContent', { static: false }) certificatContent:
  //   | ElementRef
  //   | undefined;
  isCourseCompleted: boolean = false;
  courseDetail: any;
  courseSlug: any;
  selectedModule: any;
  selectedModuleDetail: any;
  selectedActivity: any;
  selectedActivityDetail: any;
  selectedActivityLink: any;
  activityEvent: any;
  isOtherActivityComponentCalled: boolean = false;
  isLoading: boolean = true;
  htmlContent: string = '';

  isScreenLoaded: boolean = false;
  paramOnUrl: any;
  videoPlayer?: HTMLVideoElement;
  constructor(
    public routes: ActivatedRoute,
    private router: Router,
    private appService: AppService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private matDialog: MatDialog,
    protected _sanitizer: DomSanitizer
  ) {

  }

  ngOnInit(): void {
    this.routes.queryParamMap.subscribe((params) => {
      let allParam: any = { ...params.keys, ...params };
      this.paramOnUrl = allParam['params'];
      this.courseSlug = allParam['params']['name'];
    });

    console.log(this.paramOnUrl['params']);
    this.appService.selectedCourseToView = null;
    this.fetchCourseBySlug();
  }




  async initializeVideoPlayer(str: string) {
    setTimeout(async () => {
      this.videoPlayer = this.videoPlayerElementRef?.nativeElement;

      if (this.videoPlayer) {

        if (typeof window !== 'undefined' && (<any> window).Hls) {
          const Hls = (<any> window).Hls;
          if (Hls.isSupported()) {
            const hls = new Hls();
            hls.loadSource(str); // Replace with your HLS playlist URL
            hls.attachMedia(this.videoPlayer);
          }
        } else {
          try {
            const { default: Hls } = await import('hls.js');
            if (Hls.isSupported()) {
              const hls = new Hls();
              hls.loadSource(str); // Replace with your HLS playlist URL
              hls.attachMedia(this.videoPlayer);
            }
          } catch (error) {
            console.error('Failed to load Hls.js', error);
          }
        }
      }
    }, 1000);

  }

  async fetchCourseBySlug() {
    this.isLoading = true;

    let param = {
      courseSlug: this.courseSlug,
    };
    try {
      let URL = `${webApi.domain + webApi.v2EndPoint.fetchCourseBySlug}`;
      await this.appService.postMethod(param, URL).then((data: any) => {
        if (data['success']) {
          this.courseDetail = data['result'];
          this.courseDetail['isEnrolled'] = false;

          if (this.appService.listOfMyCourse.length > 0) {
            this.appService.listOfMyCourse.forEach((course: any) => {
              if (course['_id'] == this.courseDetail['_id']) {
                this.courseDetail['isEnrolled'] = true;
              }
            });
          }
          if (this.courseDetail['isEnrolled']) {
            this.courseDetail['modules'].forEach((module: any) => {
              module['activity'] = [];
              module['isSelected'] = false;
            });
            this.courseDetail['modules'].forEach((module: any) => {
              this.courseDetail['activities'].forEach((activity: any) => {
                activity['isCompleted'] = false;
                if (module['_id'] == activity['module']) {
                  module['activity'].push(activity);
                  activity['isSelected'] = false;
                }
              });
            });
            this.courseDetail['modules'].sort(
              (a: any, b: any) => a['position'] - b['position']
            );
            this.courseDetail['modules'].forEach((module: any) => {
              module['activity'].sort(
                (a: any, b: any) => a['position'] - b['position']
              );
            });
            this.fetchActivityLogForUserDashboard(
              this.courseDetail['_id'],
              this.appService.userDetail['uid']
            );
          } else {
            this.appService.selectedCourse = this.courseDetail;
            let paramTosend = { ...this.paramOnUrl };
            if (paramTosend['name'] != null && paramTosend['name'] != undefined) {
              paramTosend['name'] = undefined;
            }
            this.router.navigate(['/course-detail'], {
              queryParams: { name: this.courseDetail['courseSlug'], ...paramTosend },
            });
            this.isLoading = false;
          }
        } else {
          let paramTosend = { ...this.paramOnUrl };
          if (paramTosend['name'] != null && paramTosend['name'] != undefined) {
            paramTosend['name'] = undefined;
          }
          this.router.navigate(['/course'], { queryParams: paramTosend });
          this.isLoading = false;
        }

      });
    } catch (error) {
      let paramTosend = { ...this.paramOnUrl };
      if (paramTosend['name'] != null && paramTosend['name'] != undefined) {
        paramTosend['name'] = undefined;
      }
      this.router.navigate(['/course'], { queryParams: paramTosend });
      this.isLoading = false;
    }
  }
  async fetchActivityLogForUserDashboard(courseId: any, userManualId: any) {
    this.isLoading = true;
    try {
      let param = {
        course: courseId,
        // userManualId: userManualId,
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.appService.userDetail['accessToken']}`,
      });
      let URL = `${webApi.domain + webApi.v2EndPoint.fetchActivityLogForUserDashboard
        }`;
      await this.appService
        .postMethodWithToken(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.isCourseCompleted = data['isComplete'];
            if (data['result'] != null && data['result'] != undefined) {
              data['result'].forEach((activityLog: any) => {
                this.courseDetail['modules'].forEach((module: any) => {
                  module['activity'].forEach((activity: any) => {
                    if (activity['_id'] == activityLog['activity']) {
                      activity['isCompleted'] = true;
                    }
                  });
                });
              });
            }
            this.fetchCurrentActivity(
              this.courseDetail['_id'],
              this.appService.userDetail['uid']
            );
          } else {
            this.fetchCurrentActivity(
              this.courseDetail['_id'],
              this.appService.userDetail['uid']
            );
          }
        });
    } catch (error) {
      this.fetchCurrentActivity(
        this.courseDetail['_id'],
        this.appService.userDetail['uid']
      );
    }
  }
  async insertCurrentActivity(
    activityId: any,
    moduleId: any,
    courseId: any,
    userManualId: any
  ) {
    try {
      let param = {
        activity: activityId,
        module: moduleId,
        course: courseId,
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.appService.userDetail['accessToken']}`,
      });
      let URL = `${webApi.domain + webApi.v2EndPoint.insertCurrentActivityLog}`;
      await this.appService
        .postMethodWithToken(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            //console.log('Activity Logged Successfully');
          } else {
          }
        });
    } catch (error) { }
  }

  async fetchCurrentActivity(courseId: any, userManualId: any) {
    this.isLoading = true;
    try {
      let param = {
        course: courseId,
        userManualId: userManualId,
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.appService.userDetail['accessToken']}`,
      });
      let URL = `${webApi.domain + webApi.v2EndPoint.fetchCurrentActivity}`;
      await this.appService
        .postMethodWithToken(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            if (data['result'] != null && data['result'] != undefined) {
              this.selectedActivity = data['result']['activity']['_id'];
              this.selectedModule = data['result']['module'];
              this.courseDetail['modules'].forEach((module: any) => {
                if (module['_id'] == this.selectedModule) {
                  this.selectedModuleDetail = module;

                  module['isSelected'] = true;
                  module['activity'].forEach((activity: any) => {
                    if (activity['_id'] == this.selectedActivity) {
                      this.selectedActivityDetail = activity;

                      activity['isSelected'] = true;
                      if (activity['type'] == 'Video') {
                        this.initializeVideoPlayer(activity['videoContent']['domain'] + activity['videoContent']['path']);
                        this.selectedActivityLink =
                          activity['videoContent']['webLink'] ??
                          'https://player.vimeo.com/video/661560753?h=0f54afc604';
                        this.selectedActivityLink =
                          this._sanitizer.bypassSecurityTrustResourceUrl(
                            this.selectedActivityLink
                          );
                      }
                    }
                  });
                  if (this.selectedActivityDetail == null) {
                    this.selectedActivityDetail = module['activity'][0];
                  }
                }
              });
            } else {
              this.selectedActivity =
                this.courseDetail['modules'][0]['activity'][0];
              this.courseDetail['modules'].forEach(
                (module: any, index: number) => {
                  if (index == 0) {
                    module['isSelected'] = true;
                    this.selectedModuleDetail = module;

                    module['activity'].forEach((activity: any) => {
                      this.selectedActivityDetail = activity;

                      if (activity['type'] == 'Video') {
                        activity['isSelected'] = true;
                        this.initializeVideoPlayer(activity['videoContent']['domain'] + activity['videoContent']['path']);
                        this.selectedActivityLink =
                          activity['videoContent']['webLink'] ??
                          'https://player.vimeo.com/video/661560753?h=0f54afc604';
                        this.selectedActivityLink =
                          this._sanitizer.bypassSecurityTrustResourceUrl(
                            this.selectedActivityLink
                          );
                      }
                    });
                  }
                }
              );
            }
            this.isLoading = false;
            this.isScreenLoaded = true;
          } else {
            this.selectedActivity =
              this.courseDetail['modules'][0]['activity'][0];
            this.courseDetail['modules'].forEach(
              (module: any, index: number) => {
                if (index == 0) {
                  module['isSelected'] = true;
                  this.selectedModuleDetail = module;

                  module['activity'].forEach((activity: any) => {
                    this.selectedActivityDetail = activity;

                    if (activity['type'] == 'Video') {
                      activity['isSelected'] = true;
                      this.initializeVideoPlayer(activity['videoContent']['domain'] + activity['videoContent']['path']);
                      this.selectedActivityLink =
                        activity['videoContent']['webLink'] ??
                        'https://player.vimeo.com/video/661560753?h=0f54afc604';
                      this.selectedActivityLink =
                        this._sanitizer.bypassSecurityTrustResourceUrl(
                          this.selectedActivityLink
                        );
                    }
                  });
                }
              }
            );
            this.isLoading = false;
            this.isScreenLoaded = true;
          }
        });
    } catch (error) {
      this.selectedActivity = this.courseDetail['modules'][0]['activity'][0];
      this.courseDetail['modules'].forEach((module: any, index: number) => {
        if (index == 0) {
          module['isSelected'] = true;
          this.selectedModuleDetail = module;

          module['activity'].forEach((activity: any) => {
            this.selectedActivityDetail = activity;

            if (activity['type'] == 'Video') {
              activity['isSelected'] = true;
              this.initializeVideoPlayer(activity['videoContent']['domain'] + activity['videoContent']['path']);
              this.selectedActivityLink =
                activity['videoContent']['webLink'] ??
                'https://player.vimeo.com/video/661560753?h=0f54afc604';
              this.selectedActivityLink =
                this._sanitizer.bypassSecurityTrustResourceUrl(
                  this.selectedActivityLink
                );
            }
          });
        }
      });
      this.isLoading = false;
      this.isScreenLoaded = true;
    }
  }
  getUrl() {
    return this._sanitizer.bypassSecurityTrustResourceUrl(
      this.selectedActivityLink
    );
  }
  async activityCompleteTapHandler(event: any) {
    try {
      let param = {
        quizActivity: null,
        activity: event['activityId'],
        module: event['moduleId'],
        course: this.courseDetail['_id'],
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.appService.userDetail['accessToken']}`,
      });
      let URL = `${webApi.domain + webApi.v2EndPoint.insertCourseActivityLog}`;
      await this.appService
        .postMethodWithToken(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
          } else {
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
  async activityTapHandler(event: any) {
    if (this.selectedActivityDetail['type'] == 'Video') {
      let activityId = event['activityId'];
      let moduleId = event['moduleId'];
      let userManualId = this.appService.userDetail['uid'];
      let courseId = this.courseDetail['_id'];

      this.selectedActivity = event['activityId'];
      this.selectedModule = event['moduleId'];
      this.courseDetail['modules'].forEach((module: any) => {
        if (module['_id'] == this.selectedModule) {
          this.selectedModuleDetail = module;
          module['isSelected'] = true;
          module['activity'].forEach((activity: any) => {
            if (activity['_id'] == this.selectedActivity) {
              this.selectedActivityDetail = activity;
              activity['isSelected'] = true;
              if (activity['type'] == 'Video') {
                this.initializeVideoPlayer(activity['videoContent']['domain'] + activity['videoContent']['path']);
                this.selectedActivityLink =
                  activity['videoContent']['webLink'] ??
                  'https://player.vimeo.com/video/661560753?h=0f54afc604';
                this.selectedActivityLink =
                  this._sanitizer.bypassSecurityTrustResourceUrl(
                    this.selectedActivityLink
                  );
              }
            }
          });
        }
      });

      await this.insertCurrentActivity(
        activityId,
        moduleId,
        courseId,
        userManualId
      );
    } else {
      this.activityEvent = event;
      this.isOtherActivityComponentCalled = true;
    }
  }
  moduleTapHandler(event: any) {
    this.selectedModule = event['moduleId'];
    this.courseDetail['modules'].forEach((module: any) => {
      if (module['_id'] == this.selectedModule) {
        this.selectedModuleDetail = module;
        module['isSelected'] = true;
      }
    });
  }

  async activityTapForQuiz(event: any) {
    let activityId = event['activityId'];
    let moduleId = event['moduleId'];
    let userManualId = this.appService.userDetail['uid'];
    let courseId = this.courseDetail['_id'];

    this.selectedActivity = event['activityId'];
    this.selectedModule = event['moduleId'];
    this.courseDetail['modules'].forEach((module: any) => {
      if (module['_id'] == this.selectedModule) {
        this.selectedModuleDetail = module;
        module['isSelected'] = true;
        module['activity'].forEach((activity: any) => {
          if (activity['_id'] == this.selectedActivity) {
            this.selectedActivityDetail = activity;
            activity['isSelected'] = true;
            if (activity['type'] == 'Video') {
              this.initializeVideoPlayer(activity['videoContent']['domain'] + activity['videoContent']['path']);

              // setTimeout(() => {
              //   this.initializeVideoPlayer(activity['videoContent']['domain'] + activity['videoContent']['path']);
              // }, 1000);
              this.selectedActivityLink =
                activity['videoContent']['webLink'] ??
                'https://player.vimeo.com/video/661560753?h=0f54afc604';
              this.selectedActivityLink =
                this._sanitizer.bypassSecurityTrustResourceUrl(
                  this.selectedActivityLink
                );
            }
          }
        });
      }
    });

    await this.insertCurrentActivity(
      activityId,
      moduleId,
      courseId,
      userManualId
    );
  }
  quizComponentCloseHandler(event: any) {
    this.isOtherActivityComponentCalled = false;

    if (event == null || event == undefined) {
      this.activityTapForQuiz(this.activityEvent);
    } else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = { source: 'Component Change' };

      let dialogRef = this.matDialog.open(
        QuizAlertDialogComponent,
        dialogConfig
      );
      dialogRef.updatePosition(dialogConfig.position);
      dialogRef.afterClosed().subscribe((result) => {
        if (result != undefined) {
        }
      });
      dialogRef.componentInstance.closeActivityAtDialog.subscribe(
        async (res) => {
          if (res == null) {
            return;
          } else {
            this.activityTapForQuiz(this.activityEvent);
          }
        }
      );
    }
  }

  downloadResource(link: any) {
    window.open(link, '_blank');
  }
  async getCertificate() {
    // if (!this.isCourseCompleted) {
    //   this.showWarningToaster('Course is not completed. Please complete course.', 'Warning');
    //   return;
    // }
    try {
      let param = {
        course: this.courseDetail['_id'],
        totalActivity: 0,
        // userManualId: this.appService.userDetail['uid'],
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.appService.userDetail['accessToken']}`,
      });
      let URL = `${webApi.domain + webApi.v2EndPoint.createCourseCertificateFromUserDashboard}`;
      await this.appService
        .postMethodWithToken(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            window.open(data['data'], '_blank');
          } else {
            this.showWarningToaster('Course is not completed. Please complete course.', 'Warning');
            return;
          }
        });
    } catch (error) { }
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
  getSafeUrl(url: string) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(
      url
    );
  }

  getYoutubeVideoSafeUrl(link:any) {
    let id = (link.split('=')[1]);
    return this._sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${id}`);
  }
}
