import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  sendOtpClicked: boolean = false;
  terms: boolean = true;
  enableResendOtp: boolean = false;
  request_id: any;

  containerBgColor: any = {};
  headStyle: any = {};
  btn: any = {};
  input: any = {};
  userDetail: any;

  resendOtpCount: number = 2;
  timeLeft: number = 30;
  interval: any;

  countryCodeControl = new FormControl('91', [Validators.required]);
  phoneControl = new FormControl('', [
    Validators.required,
    Validators.minLength(10),
    Validators.maxLength(10),
    Validators.pattern('^[0-9]*$'),
  ]);
  otpControl = new FormControl('', [
    Validators.required,
    Validators.minLength(6),
    Validators.maxLength(6),
    Validators.pattern('^[0-9]*$'),
  ]);

  loginForm: FormGroup = new FormGroup({
    phone: this.phoneControl,
  });
  constructor(
    public routes: ActivatedRoute,
    private router: Router,
    private appService: AppService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) {
    this.userDetail = JSON.parse(localStorage.getItem('TG-USER-DASHBOARD')!);
    if (this.userDetail != null) {
      this.router.navigate(['/course'], { queryParamsHandling: 'preserve' });
    }
  }

  ngOnInit(): void {
    this.initializeCSS();
    // this.routes.queryParamMap.subscribe((params) => {
    //   let orderObj: any = { ...params.keys, ...params };
    //   this.calledFrom = orderObj['params']['callFrom'];
    // });

    this.loginForm = this.formBuilder.group({
      countryCode: this.countryCodeControl,
      phone: this.phoneControl,
    });
  }
  initializeCSS() {
    this.containerBgColor['border-radius'] = '5px';
    this.containerBgColor['padding'] = '30px 20px';
    this.containerBgColor['box-shadow'] = '0px 0px 5px 0px rgba(0,0,0,0.15)';
    this.containerBgColor['-webkit-box-shadow'] =
      '0px 0px 5px 0px rgba(0,0,0,0.15)';
    this.containerBgColor['-moz-box-shadow'] =
      '0px 0px 5px 0px rgba(0,0,0,0.15)';
    this.headStyle['text-align'] = 'left';
    this.input['border'] = 'solid 1px grey';
    this.input['background-color'] = '#FFFFFF';
    this.input['padding'] = '10px 10px';
    this.input['border-radius'] = '5px';
    this.btn['border'] = 'none';
    // this.btn['cursor'] = 'pointer';
    this.btn['border-radius'] = '5px';
    this.btn['margin-top'] = '10px';
    this.btn['padding'] = '10px 16px';
    this.btn['font-size'] = '14px';
    this.btn['width'] = '100%';
    // this.btn['background-color'] = '#4C6FFF';
    this.btn['color'] = '#ffffff';
  }
  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      }
    }, 1000);
  }
  phoneInputHandler(event: any) {
    let data: string = event.target.value;
    if (data.length < 10) {
      this.loginForm.removeControl('otp');
      this.sendOtpClicked = false;
      this.timeLeft = 30;
    } else {
    }
  }
  async sendOtp() {
    const param = {
      mobileNo: this.loginForm.get('phone')?.value,
      cc: this.loginForm.get('countryCode')?.value,
    };
    try {
      let URL = `${webApi.domain + webApi.v2EndPoint.sendOTPtoUser}`;
      await this.appService.postMethod(param, URL).then((data: any) => {
        if (data['success'] && data['result']['type'] == 'success') {
          this.request_id = data['result']['result']['request_id'];
          this.sendOtpClicked = true;
          this.loginForm.addControl('otp', this.otpControl);
          this.timeLeft = 30;
          this.startTimer();
        } else {
          this.sendOtpClicked = false;
          this.showWarningToaster(`Something went wrong. Try again`, 'Alert');
        }
      });
    } catch (error) {
      this.sendOtpClicked = false;
      this.showWarningToaster(`Something went wrong. Try again`, 'Alert');
    }
  }
  async reSendOtp() {
    if (this.resendOtpCount >= 1) {
      const param = {
        mobileNo: this.loginForm.get('phone')?.value,
        cc: this.loginForm.get('countryCode')?.value,
      };
      try {
        let URL = `${webApi.domain + webApi.v2EndPoint.resendOTPtoUser}`;
        await this.appService.postMethod(param, URL).then((data: any) => {
          if (data['success'] && data['result']['type'] == 'success') {
            this.request_id = data['result']['request_id'];
            this.sendOtpClicked = true;
            this.loginForm.get('otp')?.setValue('');
            this.resendOtpCount--;
            this.timeLeft = 30;
            this.startTimer();
          } else {
            this.sendOtpClicked = true;
            if (this.resendOtpCount == 1) {
              this.showWarningToaster(
                `3 attemtps of resend OTP has been already made.`,
                'Alert'
              );
            } else {
              this.showWarningToaster(
                `Something went wrong. Try again`,
                'Alert'
              );
            }
          }
        });
      } catch (error) {
        this.sendOtpClicked = true;
        this.showWarningToaster(`Something went wrong. Try again`, 'Alert');
      }
    } else {
      this.showWarningToaster(
        `3 attemtps of resend OTP has been already made.`,
        'Alert'
      );
    }
  }
  async verifyOtp() {
    let param = {
      mobileNo: this.loginForm.get('phone')?.value,
      otp: this.loginForm.get('otp')?.value,
      cc: this.loginForm.get('countryCode')?.value,
    };
    let URL = `${webApi.domain + webApi.v2EndPoint.verifyOTPofUser}`;
    await this.appService.postMethod(param, URL).then((data: any) => {
      if (data['success'] && data['result']['type'] == 'success') {
        this.isUserExists();
      } else {
        this.showWarningToaster('Incorrect otp entered.', 'Alert');
      }
    });
  }
  async isUserExists() {
    const param = {
      request_id: this.request_id, mobileNo: this.loginForm.get('phone')?.value,
    };
    try {
      const URL = `${webApi.domain + webApi.v2EndPoint.checkForUser}`;
      await this.appService.postMethod(param, URL).then(async (data: any) => {
        if (data['success']) {
          if (data['message'] == 'Existing User') {
            localStorage.setItem(
              'TG-USER-DASHBOARD',
              JSON.stringify({
                accessToken: data['accessToken'],
                name: data['result']['fullName'],
                countryCode: data['result']['countryCode'] ?? 91,
                phone: data['result']['phone'],
                email: data['result']['email'],
                uid: data['result']['userManualId'],
                image: data['result']['avatar'],
              })
            );
            this.appService.updatdUserDetailFromLocalStorage();
            if (
              data['result']['fullName'] == null ||
              data['result']['fullName'] == undefined ||
              data['result']['fullName'] == ''
            ) {
              this.router.navigate(['/signup'], {
                queryParams: {
                  countryCode: this.loginForm.get('countryCode')?.value,
                  mobileNo: this.loginForm.get('phone')?.value,
                  email: data['result']['email'],
                }, queryParamsHandling: 'merge'
              });
            } else {
              if (
                this.appService.selectedCourse == null ||
                this.appService.selectedCourse == undefined
              ) {
                this.fetchAllEnrolledCourse2();
              } else {
                this.router.navigate(['/course-detail'], {
                  queryParams: {
                    name: this.appService.selectedCourse['courseSlug'],
                  }, queryParamsHandling: 'merge'
                });
              }
            }
          } else {
            this.router.navigate(['/signup'], {
              queryParams: {
                countryCode: this.loginForm.get('countryCode')?.value,
                mobileNo: this.loginForm.get('phone')?.value,
              }, queryParamsHandling: 'merge',
            });
          }
        } else {
          this.showWarningToaster(
            `Unable to login at this moment.Please try again`,
            'Alert'
          );
        }
      });
    } catch (error) {
      this.showWarningToaster(
        'Unable to login at this moment.Please try again',
        'Alert'
      );
    }
  }
  async fetchAllEnrolledCourse2() {
    this.appService.fetchEnrollCalled = true;

    let param = {
      // userManualId: this.appService.userDetail['uid'],
    };
    try {
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.appService.userDetail['accessToken']}`,
      });
      let URL = `${webApi.domain + webApi.v2EndPoint.fetchEnrolledCourseForUserDashboard
        }`;
      await this.appService
        .postMethodWithToken(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.appService.listOfMyCourse = data['result'];
            this.appService.listOfMyCourse.forEach((course: any) => {
              course['isEnrolled'] = true;
            });
            this.router.navigate(['/my-course'], { replaceUrl: true });
            // this.router.navigate(['/course'], { replaceUrl: true });
          } else {
            this.appService.listOfMyCourse = [];
            this.router.navigate(['/my-course'], { replaceUrl: true, queryParamsHandling: 'preserve' });
            // this.router.navigate(['/course'], { replaceUrl: true });
          }
        });
    } catch (error) {
      this.appService.listOfMyCourse = [];
      this.router.navigate(['/my-course'], { replaceUrl: true, queryParamsHandling: 'preserve' });
      // this.router.navigate(['/course'], { replaceUrl: true });
    }
  }
  gotoLogin() {
    this.router.navigate(['/login'], { queryParamsHandling: 'preserve' });
  }
  gotoSignup() {
    this.router.navigate(['/signup'], { queryParamsHandling: 'preserve' });
  }

  goToLoginWithPassword() {
    this.router.navigate(['/password'], { queryParamsHandling: 'preserve' });
  }


  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
