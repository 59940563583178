import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../app.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  userDetail: any;
  constructor( public routes: ActivatedRoute,
    private router: Router,
    private appService: AppService) {
    this.userDetail = JSON.parse(localStorage.getItem('TG-USER-DASHBOARD')!);
    if (this.userDetail != null) {
      // this.as.setSideBarState(window.location.href.split('/'));
    } else {
      // this.router.navigate(['/layout/course']);
    }
  }

  ngOnInit(): void {}
}
