<div class="Cart">
    <div class="Cart-wrap">
        <div class="Cart-wrap-field">
            <img 
              src="https://m.media-amazon.com/images/I/81lNJUVpMhL._SL1500_.jpg"
              alt="" 
              class="leccture-img"
            />
        </div>
        <div class="">
            <div class="lecture-disc">MMOC Mini Options Course</div>
            <div class="lecturer-name">Tushar R.Ghone</div>
            <span class="price">Price: ₹3000</span>
            <div class="rating">4.7</div>
            <div class="date">24 Nov 2021</div>
        </div>
    </div>
</div>
