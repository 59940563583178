import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { QuizAlertDialogComponent } from '../quiz-alert-dialog/quiz-alert-dialog.component';

@Component({
  selector: 'app-quiz-detail',
  templateUrl: './quiz-detail.component.html',
  styleUrls: ['./quiz-detail.component.scss'],
})
export class QuizDetailComponent implements OnInit {
  windowRef: any;

  quizDetail: any;
  quizId: any;
  isLoading: boolean = true;

  attemptedCount: number = 0;
  attemptedDetails: any = [];

  selectedQuestionIndex: any;
  showSubmit: boolean = false;
  showResult: boolean = false;

  paramOnUrl:any;
  constructor(
    public routes: ActivatedRoute,
    private router: Router,
    public appService: AppService,
    private matDialog: MatDialog,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) {
    this.windowRef = this.appService.windowRef;
  }

  ngOnInit(): void {
    
    this.routes.queryParamMap.subscribe((params) => {
      let allParam: any = { ...params.keys, ...params };
      this.paramOnUrl = allParam['params'];
      this.quizId = allParam['params']['quizId'];
    });

    console.log(this.paramOnUrl['params']);
    //console.log(this.quizId);
    if (this.appService.selectedQuiz != null) {
      this.quizDetail = this.appService.selectedQuiz;
      this.quizDetail['quizContent'].forEach((question: any, index: number) => {
        question['isValid'] = true;
        if (index == 0) {
          this.selectedQuestionIndex = index;
        }
      });
      if (this.appService.userDetail != null) {
        this.fetchAttemptedCount();
      } else {
        this.isLoading = false;
      }
      //console.log(this.quizDetail);
    } else {
      this.appService.selectedCourse = null;
      this.fetchQuizById();
    }
  }
  async fetchQuizById() {
    this.isLoading = true;

    let param = {
      quizId: this.quizId,
    };
    try {
      let URL = `${
        webApi.domain + webApi.v2EndPoint.fetchAllQuizForUserDashboard
      }`;
      await this.appService.postMethod(param, URL).then((data: any) => {
        if (data['success']) {
          this.quizDetail = data['result'];
          this.quizDetail['quizContent'].forEach(
            (question: any, index: number) => {
              question['isValid'] = true;
              if (index == 0) {
                this.selectedQuestionIndex = index;
              }
            }
          );
          if (this.appService.userDetail != null) {
            this.fetchAttemptedCount();
          } else {
            this.isLoading = false;
          }
        } else {
          let paramTosend = {...this.paramOnUrl};
          if (paramTosend['quizId'] != null && paramTosend['quizId'] != undefined) {
            paramTosend['quizId']=undefined;
          }
          this.router.navigate(['/course'], { queryParams: paramTosend });
          if (this.appService.userDetail != null) {
            this.fetchAttemptedCount();
          } else {
            this.isLoading = false;
          }
        }
      });
    } catch (error) {
      let paramTosend = {...this.paramOnUrl};
      if (paramTosend['quizId'] != null && paramTosend['quizId'] != undefined) {
        paramTosend['quizId']=undefined;
      }
      this.router.navigate(['/course'],{queryParamsHandling: 'preserve'});
      if (this.appService.userDetail != null) {
        this.fetchAttemptedCount();
      } else {
        this.isLoading = false;
      }
    }
  }

  async fetchAttemptedCount() {
    this.isLoading = true;

    let param = {
      quiz: this.quizId,
    };
    try {
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.appService.userDetail['accessToken']}`,
      });
      let URL = `${
        webApi.domain + webApi.v2EndPoint.fetchQuizAnalyticForUserDashboard
      }`;
      await this.appService
        .postMethodWithToken(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.attemptedCount = data['count'];
            this.attemptedDetails = data['result'];
            if (this.attemptedDetails.length > 0) {
              this.showResult = true;
            }
            this.attemptedDetails.forEach((attempt: any) => {
              let totalMark = 0;
              let earnedMark = 0;
              attempt['quizContent'].forEach((quiz: any) => {
                totalMark = totalMark + 1;
                quiz['options'].forEach((option: any) => {
                  if (option['isSelected'] && option['isCorrect']) {
                    earnedMark = earnedMark + 1;
                  }
                });
              });
              attempt['totalMark'] = totalMark;
              attempt['earnedMark'] = earnedMark;
            });
            this.isLoading = false;
          } else {
            this.isLoading = false;
          }
        });
    } catch (error) {
      this.isLoading = false;
    }
  }
  selectAnswerHandler(optionId: any) {
    this.quizDetail['quizContent'][this.selectedQuestionIndex]['isValid'] =
      true;
    this.quizDetail['quizContent'][this.selectedQuestionIndex][
      'options'
    ].forEach((option: any) => {
      if (option['_id'] == optionId) {
        option['isSelected'] = true;
      }
    });
  }
  gotoNextPosition() {
    let currentIndex = this.selectedQuestionIndex;
    if (currentIndex + 1 < this.quizDetail['quizContent'].length) {
      let isValidAnswer = false;
      this.quizDetail['quizContent'][this.selectedQuestionIndex][
        'options'
      ].forEach((option: any) => {
        if (option['isSelected']) {
          isValidAnswer = true;
        }
      });
      if (isValidAnswer) {
        this.selectedQuestionIndex++;
      } else {
        this.quizDetail['quizContent'][this.selectedQuestionIndex]['isValid'] =
          false;
      }
    } else {
      this.showSubmit = true;
    }
  }
  gotoPreviousPosition() {
    let currentIndex = this.selectedQuestionIndex;
    if (currentIndex - 1 > 0) {
      this.selectedQuestionIndex--;
      this.showSubmit = false;
    } else {
    }
  }

  submitQuiz() {
    let isValidAnswer = false;
    this.quizDetail['quizContent'][this.selectedQuestionIndex][
      'options'
    ].forEach((option: any) => {
      if (option['isSelected']) {
        isValidAnswer = true;
      }
    });
    if (isValidAnswer) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        source: 'Submit Quiz',
      };

      let dialogRef = this.matDialog.open(
        QuizAlertDialogComponent,
        dialogConfig
      );
      dialogRef.updatePosition(dialogConfig.position);
      dialogRef.afterClosed().subscribe((result) => {
        if (result != undefined) {
        }
      });
      dialogRef.componentInstance.closeActivityAtDialog.subscribe(
        async (res) => {
          if (res == null) {
            return;
          } else {
            this.submitQuizHandler();
          }
        }
      );
    } else {
      this.quizDetail['quizContent'][this.selectedQuestionIndex]['isValid'] =
        false;
    }
  }
  submitQuizForcefully() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      source: 'Submit Quiz',
    };

    let dialogRef = this.matDialog.open(QuizAlertDialogComponent, dialogConfig);
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        this.submitQuizHandler();
      }
    });
  }
  async submitQuizHandler() {
    try {
      let correct = 0;
      this.quizDetail['quizContent'].forEach((quiz: any) => {
        quiz['options'].forEach((option: any) => {
          if (option['isSelected'] && option['isCorrect']) {
            correct = correct + 1;
          }
        });
      });
      let param = {
        correct: correct,
        score: correct,
        totalScore: this.quizDetail['quizContent'].length,
        // courseRecommendation: this.quizDetail['courseRecommendation'],
        quizContent: JSON.stringify(this.quizDetail['quizContent']),
        quiz: this.quizDetail['_id'],
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.appService.userDetail['accessToken']}`,
      });
      let URL = `${webApi.domain + webApi.v2EndPoint.insertQuizAnalytics}`;
      await this.appService
        .postMethodWithToken(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.showResult = true;
            this.attemptedCount++;
            this.attemptedDetails.push({
              userManualId: this.appService.userDetail['uid'],
              quizId: this.quizDetail['_id'],
              isActive: true,
              isDeleted: false,
              createdAt: new Date(),
              image: this.quizDetail['image'],
              title: this.quizDetail['title'],
              // courseRecommendation: this.quizDetail['courseRecommendation'],
              quizContent: this.quizDetail['quizContent'],
            });
            this.attemptedDetails.forEach((attempt: any) => {
              if (
                attempt['totalMark'] == null ||
                attempt['totalMark'] == undefined
              ) {
                let totalMark = 0;
                let earnedMark = 0;
                attempt['quizContent'].forEach((quiz: any) => {
                  totalMark = totalMark + 1;
                  quiz['options'].forEach((option: any) => {
                    if (option['isSelected'] && option['isCorrect']) {
                      earnedMark = earnedMark + 1;
                    }
                  });
                });
                attempt['totalMark'] = totalMark;
                attempt['earnedMark'] = earnedMark;
              }
            });
            //console.log('Activity Logged Successfully');
            this.showSuccessToaster('Quiz Submitted successfully.', 'Success');
          } else {
            this.showWarningToaster(
              'Unable to submit quiz at this moment.Try again',
              'Alert'
            );
          }
        });
    } catch (error) {
      this.showWarningToaster(
        'Unable to submit quiz at this moment.Try again',
        'Alert'
      );
    }
  }
  startQuizHandler() {
    if (this.appService.userDetail == null) {
      let paramTosend = {...this.paramOnUrl};
      if (paramTosend['quizid'] != null && paramTosend['quizid'] != undefined) {
        paramTosend['quizid']=undefined;
      }
      this.router.navigate(['/login'], { queryParams: paramTosend });
    } else {
      this.quizDetail['quizContent'].forEach((question: any, index: number) => {
        question['isValid'] = true;
        if (index == 0) {
          this.selectedQuestionIndex = index;
        }
        question['options'].forEach((option: any) => {
          option['isSelected'] = false;
        });
      });
      this.showResult = false;
    }
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
