<div *ngIf="isLoading" class="loader-div">
    <div class="loader">
    </div>
</div>
<div *ngIf="!isLoading" class="coursecarts">

    <div class="padding-left-right display-flex-space-between">
        <div class="course-type-div">
            Paid Course
        </div>
        <div class="see-all-div" (click)="paidCourseSeeMore()">
            See {{isPaidCourseSeeMore ? 'Less':'More'}}
        </div>
    </div>
    <div class="coursecarts-list" [ngClass]="{'flex-start-wrap':isPaidCourseSeeMore}">
        <div>
            <div [ngClass]="{'spacer-paid-course-card':!isPaidCourseSeeMore}">
            </div>
        </div>
        <div *ngFor="let course of getCourseList('Paid')">

            <app-card (callBackFunction)="gotoCourseDetail(course)" cardType="course" [title]="course['courseName']"
                [subTitle]="course['createdAt'] | date" [showPositionTopRightButton]="course['isEnrolled']?true:false"
                [img]="course['courseImageUrl']" [imgStyle]="imgStyle" [cardStyle]="cardStyle">
                <app-button [matMenuTriggerFor]="menu" *ngIf="course['isEnrolled']" positionTopRightButtonCourse
                    [onlyIcon]="true" mainIcon="../../../assets/imgs/more.png" bgColor="rgb(218, 218, 218)"
                    size="medium" shape="circular" type="solid"></app-button>

                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="courseDetail(course)">View Detail</button>
                </mat-menu>
                <div descriptionCourse (click)="gotoCourseDetail(course)" style="cursor: pointer;">
                    <div class="display-flex-space-between duration">
                        <span> {{course['authorId']['name']}}</span>
                        <span> {{course['levels']}}</span>
                    </div>
                    <div class="display-flex-space-between">
                        <span [ngStyle]="{'display':course['courseType']=='Free'?'none':'block'}">{{'\u20b9\
                            '+course['coursePrice']}}</span>
                        <span *ngIf="!course['isEnrolled']"
                            [ngClass]="{'courseType-paid':course['courseType']=='Paid','courseType-free':course['courseType']=='Free'}">{{course['courseType']}}</span>
                        <span *ngIf="course['isEnrolled']" class="course-enrolled">Enrolled</span>
                    </div>
                    <div class="display-flex-space-between duration" *ngIf="!course['isEnrolled']">
                        <span>Duration: {{getDuration(course['totalHour'])}}</span> <span>Lessons:
                            {{course['totalLesson']}}</span>
                    </div>
                    <div class="display-flex-space-between duration" >
                        <span >Total Enrolled: <b>{{course['totalEnrolled']}}</b> Student</span> 
                    </div>
                    <div *ngIf="!course['isEnrolled']" style="height: 1.5em;"></div>
                    <div class="display-flex-space-between duration" *ngIf="course['isEnrolled']">
                        <div  class="continue-learning">
                            Continue Learning
                        </div>
                    </div>
                </div>
            </app-card>
        </div>
        <div>
            <div [ngClass]="{'spacer-paid-course-card':!isPaidCourseSeeMore}">
            </div>
        </div>
    </div>
    <div class="padding-left-right display-flex-space-between">
        <div class="course-type-div">
            Free Course
        </div>
        <div class="see-all-div" (click)="freeCourseSeeMore()">
            See {{isFreeCourseSeeMore ? 'Less':'More'}}
        </div>
    </div>
    <div class="coursecarts-list" [ngClass]="{'flex-start-wrap':isFreeCourseSeeMore}">
        <div>
            <div [ngClass]="{'spacer-free-course-card':!isFreeCourseSeeMore}">
            </div>
        </div>

        <div *ngFor="let course of getCourseList('Free')">

            <app-card (callBackFunction)="gotoCourseDetail(course)" cardType="course" [title]="course['courseName']"
                [subTitle]="course['createdAt'] | date" [showPositionTopRightButton]="course['isEnrolled']?true:false"
                [img]="course['courseImageUrl']" [imgStyle]="imgStyle" [cardStyle]="cardStyle">
                <app-button [matMenuTriggerFor]="menu" *ngIf="course['isEnrolled']" positionTopRightButtonCourse
                    [onlyIcon]="true" mainIcon="../../../assets/imgs/more.png" bgColor="rgb(218, 218, 218)"
                    size="medium" shape="circular" type="solid"></app-button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="courseDetail(course)">View Detail</button>
                </mat-menu>
                <div descriptionCourse (click)="gotoCourseDetail(course)" style="cursor: pointer;">
                    <div class="display-flex-space-between duration">
                        <span> {{course['authorId']['name']}}</span>
                        <span> {{course['levels']}}</span>
                    </div>
                    <div class="display-flex-space-between">
                        <span [ngStyle]="{'display':course['courseType']=='Free'?'none':'block'}">{{'\u20b9\
                            '+course['coursePrice']}}</span>
                        <span *ngIf="!course['isEnrolled']"
                            [ngClass]="{'courseType-paid':course['courseType']=='Paid','courseType-free':course['courseType']=='Free'}">{{course['courseType']}}</span>
                        <span *ngIf="course['isEnrolled']" class="course-enrolled">Enrolled</span>
                    </div>
                    <div class="display-flex-space-between duration" *ngIf="!course['isEnrolled']">
                        <span>Duration: {{getDuration(course['totalHour'])}}</span> <span>Lessons:
                            {{course['totalLesson']}}</span>
                    </div>
                    <div class="display-flex-space-between duration" >
                        <span >Total Enrolled: <b>{{course['totalEnrolled']}}</b> Student</span> 
                    </div>
                    <div *ngIf="!course['isEnrolled']" style="height: 1.5em;"></div>
                    <div class="display-flex-space-between duration" *ngIf="course['isEnrolled']">
                        <div  class="continue-learning">
                            Continue Learning
                        </div>
                    </div>
                </div>
            </app-card>
        </div>
        <div>
            <div [ngClass]="{'spacer-free-course-card':!isPaidCourseSeeMore}">
            </div>
        </div>
    </div>
    <div class="padding-left-right display-flex-space-between">
        <div class="course-type-div">
            Quiz
        </div>
        <div class="see-all-div" (click)="quizSeeMore()">
            See {{isQuizSeeMore ? 'Less':'More'}}
        </div>
    </div>
    <div class="coursecarts-list" [ngClass]="{'flex-start-wrap':isQuizSeeMore}">
        <div>
            <div [ngClass]="{'spacer-quiz-card':!isQuizSeeMore}">
            </div>
        </div>
        <div *ngFor="let quiz of listOfQuiz" (click)="gotoQuizDetail(quiz)">
            <app-card cardType="course" [title]="quiz['name']" [subTitle]="quiz['createdAt'] | date"
                [img]="quiz['image']=='' ||quiz['image']==null ||quiz['image']==undefined ?'../../../assets/imgs/placeholder.jpg':quiz['image']"
                [imgStyle]="imgStyle" [cardStyle]="cardStyle">
            </app-card>
        </div>
        <div>
            <div [ngClass]="{'spacer-quiz-card':!isQuizSeeMore}">
            </div>
        </div>

    </div>
    <div style="height: 10em;">

    </div>
</div>