<div class="instructor">
    <h3>{{authorDetail['name']}}</h3>
    <p>{{authorDetail['profession']}}</p>
    <div class="author-detail">
        <img class="author-img" [src]="authorDetail['image']" alt="" />
        <div>
            <ul style="list-style: none;padding-left: 10px;">
                <li>
                    <img style="height: 15px;width: 15px;margin-right: 15px;" src="../../../assets/imgs/filled-star.png" alt=""> {{authorDetail['rating']}} Instructor Rating
                </li>
                <li>
                    <img style="height: 15px;width: 15px;margin-right: 15px;" src="../../../assets/imgs/student.png" alt=""> {{authorDetail['totalStudents']}} Total Students
                </li>
                <li>
                    <img style="height: 15px;width: 15px;margin-right: 15px;" src="../../../assets/imgs/course-play.png" alt=""> {{authorDetail['totalCourse']}} Courses
                </li>

            </ul>
        </div>
    </div>
    <div class="author-description" [ngClass]="{'author-desc-show-all':showMore}">
        {{authorDetail['description']}}
    </div>
    <div (click)="toggleShowMore()" style="color: #5624d0;font-size: 14px; margin-top: 1em;cursor: pointer;">
        {{showMore? 'Show Less': 'Show more'}}
    </div>
</div>