import { DOCUMENT } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Component, Inject, NgZone, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-payment-success-dialog',
  templateUrl: './payment-success-dialog.component.html',
  styleUrls: ['./payment-success-dialog.component.scss'],
})
export class PaymentSuccessDialogComponent implements OnInit {
  constructor(
    private ngZone: NgZone,
    // public dialogRef: MatDialogRef<PaymentSuccessDialogComponent>,
    private toastr: ToastrService,
    public appService: AppService,
    protected _sanitizer: DomSanitizer,
    public routes: ActivatedRoute,
    private router: Router,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document) {
    // this.dialogRef.disableClose = true;
    this.fetchAllCourse();
    if (this.appService.loadPaymantPage) {
      this.appService.loadPaymantPage = false;
      window.location.reload();
    } else { }

    // setTimeout(() => {
    //   // this.dialogRef.close();
    //   this.ngZone.run(() => this.router.navigate(['/my-course'])).then();
    // }, 3000);
  }
  ngOnInit(): void {
    let script = this._renderer2.createElement('script');
    script.type = `application/ld+json`;
    script.text = `
    <!-- Global site tag (gtag.js) - Google Ads: 594651714 -->
    <script async src="https://www.googletagmanager.com/gtag/js?id=AW-594651714"></script>
    <script>
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
    
      gtag('config', 'AW-594651714');
    </script>
    
    <script>
    gtag('event', 'conversion', {
        'send_to': 'AW-594651714/l-yPCNfh19MDEMLUxpsC',
        'transaction_id': ''
    });
  </script>

<!-- Meta Pixel Code -->
<script>
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '2573207076118516');
fbq('track', 'PageView');
</script>
<noscript><img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=2573207076118516&ev=PageView&noscript=1"
/></noscript>
<!-- End Meta Pixel Code -->
  `;

    this._renderer2.appendChild(this._document.body, script);

  }
  async fetchAllCourse() {
    let param = {
      // userManualId: this.appService.userDetail['uid'],
    };
    try {
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.appService.userDetail['accessToken']}`,
      });
      let URL = `${webApi.domain + webApi.v2EndPoint.fetchEnrolledCourseForUserDashboard
        }`;
      await this.appService
        .postMethodWithToken(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.appService.listOfMyCourse = data['result'];
            this.appService.listOfMyCourse.forEach((course: any) => {
              course['isEnrolled'] = true;
            });
            //console.log(this.appService.listOfMyCourse);
          } else {
            this.appService.listOfMyCourse = [];
          }
        });
    } catch (error) {
      this.appService.listOfMyCourse = [];
    }
  }
  async gotoMyCourse() {
    await this.fetchAllCourse();

    // this.dialogRef.close();
    this.ngZone.run(() => this.router.navigate(['/my-course'],{queryParamsHandling: 'preserve'})).then();
  }
  gotoLogin() {
    // this.dialogRef.close();
    this.ngZone.run(() => this.router.navigate(['/login'],{queryParamsHandling: 'preserve'})).then();

  }
}
