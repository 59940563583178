<div>
    <app-header>

        <!-- Main Content Start-->

        <router-outlet mainContent></router-outlet>


        <!-- router-outlet -->
        <!-- Main Content End-->


        <!-- Footer Start -->

        <!-- <div>
            <app-footer footer></app-footer>
        </div> -->

        <!-- Footer End -->


    </app-header>
</div>