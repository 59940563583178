import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CourseContentComponent } from './layout/course-content/course-content.component';
import { CourseDetailComponent } from './layout/course-detail/course-detail.component';
import { CourseListComponent } from './layout/course-list/course-list.component';
import { LayoutComponent } from './layout/layout.component';
import { LoginComponent } from './layout/login/login.component';
import { MyCourseComponent } from './layout/my-course/my-course.component';
import { NotificationComponent } from './layout/notification/notification.component';
import { ProfileComponent } from './layout/profile/profile.component';
import { QuizDetailComponent } from './layout/quiz-detail/quiz-detail.component';
import { SignupComponent } from './layout/signup/signup.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';

const routes: Routes = [
  // {
  //   path: 'layout',
  //   component: LayoutComponent,
  //   children: [
  //     {
  //       path: '',
  //       loadChildren: './layout/layout.module#LayoutModule',
  //     },
  //   ],
  // },
  {
    path: 'course',
    component: CourseListComponent,
  },
  {
    path: 'course-detail',
    component: CourseDetailComponent,
  },
  {
    path: 'quiz-detail',
    component: QuizDetailComponent,
  },
  {
    path: 'course-content',
    component: CourseContentComponent,
  },
  {
    path: 'my-course',
    component: MyCourseComponent,
  },
  {
    path: 'profile',
    component: ProfileComponent,
  },
  {
    path: 'notification',
    component: NotificationComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'signup',
    component: SignupComponent,
  },
  {
    path: 'payment-success',
    component: PaymentSuccessComponent,
  },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent,
  },
  { path: '', pathMatch: 'full', redirectTo: '/course' },
  { path: '**',  redirectTo: '/course' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
