import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { DropDownItem } from '../component/dropdown/dropdown.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  _opened: boolean = false;

  dropDownItem: Array<DropDownItem> = [
    {
      label: 'Profile',
      value: 'Profile',
      prefixIcon: '../../../assets/imgs/user.png',
      suffixText: '',
      defaultSelected: false,
    },

    {
      label: 'Notification',
      value: 'Notification',
      prefixIcon: '../../../assets/imgs/setting.png',
      suffixText: '',
      defaultSelected: false,
    },

    {
      label: 'Logout',
      value: 'Logout',
      prefixIcon: '../../../assets/imgs/logout.png',
      suffixText: '',
      defaultSelected: false,
      isSeperator: true,
    },
  ];
  dropDownStyle: any = {
    width: '200px',
  };
  suffixStyle: any = {
    'background-color': '#1cbb8c',
    color: '#00000',
  };
  paramOnUrl: any;
  constructor(
    public routes: ActivatedRoute,
    private router: Router,
    public appService: AppService
  ) {
    if (this.appService.userDetail != null) {
      // this.as.setSideBarState(window.location.href.split('/'));
    } else {
      // this.router.navigate(['/layout/course']);
    }
  }

  ngOnInit(): void {
    this.routes.queryParamMap.subscribe((params) => {
      let allParam: any = { ...params.keys, ...params };
      this.paramOnUrl = allParam['params'];
    });

    console.log(this.paramOnUrl['params']);
  }
  gotoHome() {
    let paramTosend = { ...this.paramOnUrl };
    if (paramTosend['name'] != null && paramTosend['name'] != undefined) {
      delete paramTosend.name;
    }
    if (paramTosend['quizId'] != null && paramTosend['quizId'] != undefined) {
      delete paramTosend.quizId;
    }
    if (paramTosend['countryCode'] != null && paramTosend['countryCode'] != undefined) {
      delete paramTosend.countryCode;
    }
    if (paramTosend['mobileNo'] != null && paramTosend['mobileNo'] != undefined) {
      delete paramTosend.mobileNo;
    }
    this.router.navigate(['/course'], { queryParams: paramTosend });
  }
  gotoAllCourse() {
    let paramTosend = { ...this.paramOnUrl };
    if (paramTosend['name'] != null && paramTosend['name'] != undefined) {
      delete paramTosend.name;
    } if (paramTosend['quizId'] != null && paramTosend['quizId'] != undefined) {
      delete paramTosend.quizId;
    }
    if (paramTosend['countryCode'] != null && paramTosend['countryCode'] != undefined) {
      delete paramTosend.countryCode;
    }
    if (paramTosend['mobileNo'] != null && paramTosend['mobileNo'] != undefined) {
      delete paramTosend.mobileNo;
    }
    this.router.navigate(['/course'], { queryParams: paramTosend });
  }
  gotoMyLearning() {
    let paramTosend = { ...this.paramOnUrl };
    if (paramTosend['name'] != null && paramTosend['name'] != undefined) {
      delete paramTosend.name;
    } if (paramTosend['quizId'] != null && paramTosend['quizId'] != undefined) {
      delete paramTosend.quizId;
    }
    if (paramTosend['countryCode'] != null && paramTosend['countryCode'] != undefined) {
      delete paramTosend.countryCode;
    }
    if (paramTosend['mobileNo'] != null && paramTosend['mobileNo'] != undefined) {
      delete paramTosend.mobileNo;
    }
    this.router.navigate(['/my-course'], { queryParams: paramTosend });
  }
  gotoMyProfile() {
    let paramTosend = { ...this.paramOnUrl };
    if (paramTosend['name'] != null && paramTosend['name'] != undefined) {
      delete paramTosend.name;
    } if (paramTosend['quizId'] != null && paramTosend['quizId'] != undefined) {
      delete paramTosend.quizId;
    }
    if (paramTosend['countryCode'] != null && paramTosend['countryCode'] != undefined) {
      delete paramTosend.countryCode;
    }
    if (paramTosend['mobileNo'] != null && paramTosend['mobileNo'] != undefined) {
      delete paramTosend.mobileNo;
    }
    this.router.navigate(['/profile'], { queryParams: paramTosend });
  }
  gotoMyNotification() {
    let paramTosend = { ...this.paramOnUrl };
    if (paramTosend['name'] != null && paramTosend['name'] != undefined) {
      delete paramTosend.name;
    } if (paramTosend['quizId'] != null && paramTosend['quizId'] != undefined) {
      delete paramTosend.quizId;
    }
    if (paramTosend['countryCode'] != null && paramTosend['countryCode'] != undefined) {
      delete paramTosend.countryCode;
    }
    if (paramTosend['mobileNo'] != null && paramTosend['mobileNo'] != undefined) {
      delete paramTosend.mobileNo;
    }
    this.router.navigate(['/notification'], { queryParams: paramTosend });
  }
  loginButtonHandler(event: any) {
    //console.log('Login Clicked');
    let paramTosend = { ...this.paramOnUrl };
    if (paramTosend['name'] != null && paramTosend['name'] != undefined) {
      delete paramTosend.name;
    } if (paramTosend['quizId'] != null && paramTosend['quizId'] != undefined) {
      delete paramTosend.quizId;
    }
    if (paramTosend['countryCode'] != null && paramTosend['countryCode'] != undefined) {
      delete paramTosend.countryCode;
    }
    if (paramTosend['mobileNo'] != null && paramTosend['mobileNo'] != undefined) {
      delete paramTosend.mobileNo;
    }
    this.router.navigate(['/login'], { queryParams: paramTosend });
  }
  signUpButtonHandler(event: any) {
    //console.log('Sign Clicked');
    let paramTosend = { ...this.paramOnUrl };
    if (paramTosend['name'] != null && paramTosend['name'] != undefined) {
      delete paramTosend.name;
    } if (paramTosend['quizId'] != null && paramTosend['quizId'] != undefined) {
      delete paramTosend.quizId;
    }
    if (paramTosend['countryCode'] != null && paramTosend['countryCode'] != undefined) {
      delete paramTosend.countryCode;
    }
    if (paramTosend['mobileNo'] != null && paramTosend['mobileNo'] != undefined) {
      delete paramTosend.mobileNo;
    }
    this.router.navigate(['/signup'], { queryParams: paramTosend });
  }
  _toggleOpened(): void {
    this._opened = !this._opened;
  }
  callBackHandler(event: any) {
    //console.log(event);
    switch (event) {
      case 'Profile':
        this.gotoMyProfile();
        break;
      case 'Notification':
        this.gotoMyNotification();
        break;
      case 'Logout':
        //console.log('Logout Clicked');
        localStorage.removeItem('TG-USER-DASHBOARD');
        this.appService.userDetail = null;
        this.appService.listOfMyCourse = [];
        this.appService.listOfCourse.forEach((element: any) => {
          element['isEnrolled'] = false;
        });
        let paramTosend = { ...this.paramOnUrl };
        if (paramTosend['name'] != null && paramTosend['name'] != undefined) {
          delete paramTosend.name;
        }
        if (paramTosend['quizId'] != null && paramTosend['quizId'] != undefined) {
          delete paramTosend.quizId;
        }
        if (paramTosend['countryCode'] != null && paramTosend['countryCode'] != undefined) {
          delete paramTosend.countryCode;
        }
        if (paramTosend['mobileNo'] != null && paramTosend['mobileNo'] != undefined) {
          delete paramTosend.mobileNo;
        }
        this.router.navigate(['/course'], { queryParams: paramTosend });
        break;

      default:
        break;
    }
  }
}
