<div class="course-content">
    <input type="checkbox" id="{{moduleDetail['_id']}}" class="content-checkbox" [(ngModel)]="isChecked" />
    <label for="{{moduleDetail['_id']}}">
        <div class="course-content-wrap">
            <div class="course-topic">
                <img style="height: 15px;width: 15px;margin-right: 1em"
                    [src]="!isChecked?'../../../assets/imgs/down-arrow.png':'../../../assets/imgs/up-arrow.png'"
                    alt="" />
                <div>{{moduleDetail['name']}}</div>
            </div>
            <div class="duration">
                <span style="font-size: 12px;">{{getLectureCount()}} lectures</span>
                <span style="font-size: 12px;">{{getLectureDuration()}}</span>
            </div>
        </div>
    </label>
    <div class="main-course-content-list" [ngStyle]="{'display':isChecked?'block':'none'}">
        <div class="main-course-content" *ngFor="let activity of moduleDetail['activity'];let i=index" [ngStyle]="{'cursor':moduleDetail['position']==1 && i==0 && activity['type']=='Video'?'pointer':'inherit'}">
            <div class="course-topic" (click)="moduleDetail['position']==1 &&i==0? openIntroVideo(activity):null">
                <label>
                    <img class="icon-style" [src]="activity['type']=='Video'?'../../../assets/imgs/play-button.png':'../../../assets/imgs/file.png'"
                        alt="" />
                </label>
                <div style="font-size: 13px;" [ngStyle]="{'color':moduleDetail['position']==1 && i==0 && activity['type']=='Video'?'blue':'inherit'}">
                    {{activity['name']}}</div>
            </div>
            <div class="duration" *ngIf="activity['type']=='Video'">
                <span style="font-size: 12px;">{{getDuration(activity['videoContent']['duration'])}}</span>
            </div>
        </div>


    </div>
</div>