<ng-sidebar-container style="height: 100vh;background-color: #f8fcff;">
    <ng-sidebar [(opened)]="_opened" mode="over" [keyClose]="false" position="left" [dock]="false" [dockedSize]="'50px'"
        [autoCollapseHeight]="500" [autoCollapseWidth]="500" [closeOnClickOutside]="false" [closeOnClickBackdrop]="true"
        [showBackdrop]="true" [animate]="true" [trapFocus]="false" [autoFocus]="true" [ariaLabel]="'Menu'">
        <app-left-sidebar (closeSideForm)="_toggleOpened()"></app-left-sidebar>
    </ng-sidebar>
    <div ng-sidebar-content>
        <div class="Header">
            <div class="header-wrap">
                <div class="menu-field" (click)="_toggleOpened()">
                    <img src="../../../assets/imgs/menu.png" alt="" class="navlogo" />
                </div>

                <div class="header-logo" (click)="gotoHome()">
                    <img src="../../../assets/logo/tg-logo.png" alt="" class="logo" />
                </div>
                <div class="login-mobile-div" *ngIf="appService.userDetail==null">

                    <app-button (callBackFunction)="loginButtonHandler($event)" margin="0px 0px 0px 0px" size="medium"
                        fontSize="12px" width="100px" shape="rectangular" type="outline" text="Login / Signup"
                        bgColor="#3754DB">
                    </app-button>

                </div>
                <div class="header-nav" *ngIf="appService.userDetail!=null">
                    <span style="margin-right: 1em;">Customer Care:- <a href="https://wa.me/+919152615121">9152615121</a> </span>
                    <div (click)="gotoAllCourse()">All Course</div>
                    <div (click)="gotoMyLearning()">My Courses </div>

                    <div (click)="gotoMyNotification()">
                        <img src="../../../assets/imgs/bell.png" alt="" class="noti-log navlogo" />
                    </div>
                    <div class="profile-logo-container">
                        <app-dropdown (callBackFunction)="callBackHandler($event)" dropDownType="Menu"
                            [dropDownItem]="dropDownItem" dropDownLabel="Select Item" [dropDownStyle]="dropDownStyle"
                            [suffixStyle]="suffixStyle">
                            <div toggleContent style="display: flex;align-items: center;">
                                <div style="width: 2.6em;">
                                    <app-avatar size="extra-small" [showStatusIndicator]="true" shape="circular"
                                        [url]="appService.userDetail['image']==null||appService.userDetail['image']==undefined||appService.userDetail['image']==''? '../../../assets/imgs/avatar-1.jpg':appService.userDetail['image']">
                                    </app-avatar>
                                </div>
                                <span style="font-size: 12px;font-weight: 500;">{{appService.userDetail['name']==null
                                    ||appService.userDetail['name']==undefined ? '':
                                    appService.userDetail['name'].split('
                                    ')[0]}}</span> <img src="../../../assets/imgs/next.png"
                                    style="height: 15px;width: 15px;" alt="" srcset="">
                            </div>
                        </app-dropdown>
                    </div>

                </div>

                <div class="header-right-search-icon">
                    <!-- Customer Care:- 9152615121 -->
                </div>

                <div *ngIf="appService.userDetail==null" class="login">
                    <span style="margin-right: 1em;">Customer Care:- <a href="https://wa.me/+919152615121">9152615121</a> </span>
                    <app-button (callBackFunction)="loginButtonHandler($event)" margin="0px 30px 0px 0px" size="medium"
                        fontSize="15px" width="117px" shape="rectangular" type="outline" text="Login / Signup"
                        bgColor="#3754DB">
                    </app-button>
                    <!-- <app-button (callBackFunction)="signUpButtonHandler($event)" margin="0px 10px 0px 0px" size="medium" fontSize="15px" shape="rectangular" type="solid" text="Sign up" bgColor="#3754DB">
                    </app-button> -->
                </div>

            </div>
        </div>
        <ng-content select="[mainContent]"></ng-content>
        <ng-content select="[footer]"></ng-content>
    </div>
</ng-sidebar-container>