<div *ngIf="isLoading" class="loader-div">
    <div class="loader">
    </div>
</div>
<div *ngIf="!isLoading" class="course-detail">

    <div class="course-detail-wrap">
        <div class="course-detail-main">
            <h1 class="lecture-name data">{{courseDetail['courseName']}}</h1>
            <div class="lecturer-name data">Author: {{courseDetail['authorId']['name']}}</div>
            <div class="lecture-description data">Language: {{courseDetail['langauge']}}</div>
            <div class="date data">Access On: {{courseDetail['accessOn'][0]}}, {{courseDetail['accessOn'][1]}}</div>
            <div class="date data">Total Enrolled Student: <b>{{courseDetail['totalEnrolled']}}</b></div>
            <div class="rating data">{{courseDetail['updatedAt'] | date}}</div>
        </div>
        <div class="course-detail-wrap-card">
            <div class="course-detail-left">
                <div class="Cart-wrap-field">
                    <img [src]="courseDetail['courseImageUrl']" alt="" class="leccture-img" />
                </div>
            </div>
            <div class="course-detail-right">
                <span>{{courseDetail['courseType']=='Free'? 'Free':'\u20b9\ '+courseDetail['coursePrice']}}</span>
                <button *ngIf="!courseDetail['isEnrolled']" (click)="buyCourse(courseDetail)" class="buynow-btn">{{courseDetail['courseType']=='Free'? 'Enroll Now':'Buy now @ \u20b9\ ' +courseDetail['coursePrice']+''}}</button>
                <button *ngIf="courseDetail['isEnrolled']" (click)="gotoCourse(courseDetail)" class="gotoCourse-btn">{{'Go to Course'}}</button>
                <div class="cart-info">
                    <h3>This course includes:</h3>
                    <div class="cart-info-list">
                        <div class="">
                            <img src="../../../assets/imgs/camera-video.png" alt="" class="icon-style" />
                            <span class="">{{getDuration(courseDetail['totalHour'])}} on-demand video</span>
                        </div>

                        <div class="">
                            <img src="../../../assets/imgs/file.png" alt="" class="icon-style" />
                            <span class="">{{courseDetail['totalLesson']}} lesson</span>
                        </div>
                        <div class="">
                            <img src="../../../assets/imgs/language.png" alt="" class="icon-style" />
                            <span class="">{{courseDetail['langauge']}}</span>
                        </div>
                        <div class="">
                            <img src="../../../assets/imgs/camera-video.png" alt="" class="icon-style" />
                            <span class="">{{courseDetail['activities'].length}} activity</span>
                        </div>
                        <!-- <div class=""><img src="../../../assets/imgs/Vector 191 (Stroke).png" alt="" class=""/><span class="">Access on mobile and TV</span></div>
                        <div class=""><img src="../../../assets/imgs/camera-video.png" alt="" class=""/><span class="">Certificate of completion</span></div> -->
                    </div>

                </div>

            </div>
        </div>
    </div>
    <button *ngIf="!courseDetail['isEnrolled']" (click)="buyCourse(courseDetail)" class="buynow-main-btn">{{courseDetail['courseType']=='Free'? 'Enroll Now':'Buy now @ \u20b9\ ' +courseDetail['coursePrice']+''}}</button>
    <button *ngIf="courseDetail['isEnrolled']" (click)="gotoCourse(courseDetail)" class="gotoCourse-main-btn">{{'Go to\
        Course'}}</button>

    <div class="downward-content">

        <div class="learning">
            <h2>What you'll learn</h2>
            <div class="learning-wrap">
                <div class="learning-topics">
                    {{courseDetail['shortDescription']}}

                </div>
            </div>

        </div>
        <div class="content">
            <h2>Course content</h2>
            <div>
                <app-content *ngFor="let module of courseDetail['modules']" [moduleDetail]="module"></app-content>
            </div>

        </div>
        <div class="requirement">
            <h2>Requirements</h2>
            <ul>
                <li *ngFor="let data of courseDetail['requirement']">{{data}}</li>
            </ul>
        </div>
        <div class="description">
            <h2>Description</h2>
            <p style="white-space: pre-wrap;">{{courseDetail['courseDescription']}}
            </p>
        </div>
        <!-- <div class="requirement">
            <h2>Who this course is for:</h2>
            <ul>
                <li>Developers who want to be considered in the top 10% of JavaScript programmers</li>
                <li>Bootcamp or online tutorial graduates that want to go beyond the basics</li>
                <li>Junior JavaScript Developers</li>
                <li>Junior Web Developers</li>
                <li>Programmers who don't want to waste time on basics and want to dive deep</li>
            </ul>
        </div> -->
        <div class="instructor-container">
            <h2>Instructor</h2>
            <div>
                <app-instructor [authorDetail]="courseDetail['authorId']"></app-instructor>

            </div>
        </div>
    </div>
</div>