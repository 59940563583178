import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-side-course-content',
  templateUrl: './side-course-content.component.html',
  styleUrls: ['./side-course-content.component.scss'],
})
export class SideCourseContentComponent implements OnInit {
  @Output() moduleTapHandler: EventEmitter<any> = new EventEmitter();
  @Output() activityTapHandler: EventEmitter<any> = new EventEmitter();
  @Output() activityCompleteTapHandler: EventEmitter<any> = new EventEmitter();
  @Input() postion: any;
  @Input() selectedModule: any;
  @Input() selectedActivity: any;
  @Input() moduleDetail: any;
  isChecked: boolean = false;

  constructor(
    private appService: AppService,

  ) { }

  ngOnInit(): void {
    if (this.selectedModule == this.moduleDetail['_id']) {
      this.isChecked = true;
    } else {
      this.isChecked = false;
    }
  }

  onModuleTap(moduleId: any) {
    this.moduleTapHandler.emit({ moduleId: moduleId });
  }
  onActivityTap(moduleId: any, activityId: any) {
    this.activityTapHandler.emit({
      activityId: activityId,
      moduleId: moduleId,
    });
  }
  async onActivityCompleteTap(
    event: any,
    activity: any,
  ) {
    // console.log(event);
    // let toEmit = {
    //   activityId: activityId,
    //   moduleId: moduleId,
    //   isCompleteChecked: event.target.checked
    // };
    // this.activityCompleteTapHandler.emit(toEmit);
    try {
      let param = {
        quizActivity: null,
        activity: activity['_id'],
        module: activity['module'],
        course: activity['course'],
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.appService.userDetail['accessToken']}`,
      });
      let URL = `${webApi.domain + webApi.v2EndPoint.insertCourseActivityLog}`;
      await this.appService
        .postMethodWithToken(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
          } else {
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
  getLectureCount() {
    let lctrCount: number = 0;
    this.moduleDetail['activity'].forEach((act: any) => {
      if (act['type'] == 'Video') {
        lctrCount++;
      }
    });
    return lctrCount;
  }
  getLectureDuration() {
    let lctrDuration: number = 0;

    this.moduleDetail['activity'].forEach((act: any) => {
      if (act['type'] == 'Video') {
        lctrDuration =
          lctrDuration + parseInt(act['videoContent']['duration']);
      }
    });
    let hour = 0;
    while (lctrDuration >= 60) {
      hour++;
      lctrDuration = lctrDuration - 60;
    }
    if (hour > 0) {
      return `${hour}H ${lctrDuration}M in`;
    } else {
      return `${lctrDuration} Min`;
    }
  }
  getDuration(minutes: number) {
    let hour = 0;
    while (minutes >= 60) {
      hour++;
      minutes = minutes - 60;
    }
    if (hour > 0) {
      return `${hour}H ${minutes.toFixed(2)} Min`;
    } else {
      return `${minutes.toFixed(2)} Min`;
    }
  }
}
