<div *ngIf="!isLoading">
    <h2 mat-dialog-title style="font-weight: 500;">{{data['activity']['name']}}</h2>
    <div mat-dialog-content>
        <!-- <iframe style="height: 25em;width: 100%;border: none;" title="YouToube Video" [src]="getUrl()" allowfullscreen></iframe> -->
        <video class="video-style" oncontextmenu="return false;" controls controlsList="nodownload" [src]="getUrl()" autoplay>
            <!-- <source [src]="selectedActivityLink" type="video/mp4"> -->
            Your browser does not support the video tag.
        </video>
    </div>

</div>