import { DOCUMENT } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Component, Inject, NgZone, OnInit, Renderer2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-payment-success-for-free',
  templateUrl: './payment-success-for-free.component.html',
  styleUrls: ['./payment-success-for-free.component.scss']
})
export class PaymentSuccessForFreeComponent implements OnInit {

  constructor(
    private ngZone: NgZone,
    // public dialogRef: MatDialogRef<PaymentSuccessDialogComponent>,
    private toastr: ToastrService,
    public appService: AppService,
    protected _sanitizer: DomSanitizer,
    public routes: ActivatedRoute,
    private router: Router,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document) {
    // this.dialogRef.disableClose = true;
    this.fetchAllCourse();
    if (this.appService.loadPaymantPage) {
      this.appService.loadPaymantPage = false;
      window.location.reload();
    } else { }

    // setTimeout(() => {
    //   // this.dialogRef.close();
    //   this.ngZone.run(() => this.router.navigate(['/my-course'])).then();
    // }, 3000);
  }
  ngOnInit(): void {
    
  }
  async fetchAllCourse() {
    let param = {
      // userManualId: this.appService.userDetail['uid'],
    };
    try {
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.appService.userDetail['accessToken']}`,
      });
      let URL = `${webApi.domain + webApi.v2EndPoint.fetchEnrolledCourseForUserDashboard
        }`;
      await this.appService
        .postMethodWithToken(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.appService.listOfMyCourse = data['result'];
            this.appService.listOfMyCourse.forEach((course: any) => {
              course['isEnrolled'] = true;
            });
            //console.log(this.appService.listOfMyCourse);
          } else {
            this.appService.listOfMyCourse = [];
          }
        });
    } catch (error) {
      this.appService.listOfMyCourse = [];
    }
  }
  async gotoMyCourse() {
    await this.fetchAllCourse();

    // this.dialogRef.close();
    this.ngZone.run(() => this.router.navigate(['/my-course'],{queryParamsHandling: 'preserve'})).then();
  }
  gotoLogin() {
    // this.dialogRef.close();
    this.ngZone.run(() => this.router.navigate(['/login'],{queryParamsHandling: 'preserve'})).then();

  }

}
