import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-my-course',
  templateUrl: './my-course.component.html',
  styleUrls: ['./my-course.component.scss'],
})
export class MyCourseComponent implements OnInit {
  listOfMyCourse: any = [];
  listOfMyCourseToDisplay: any = [];
  isLoading: boolean = true;
  imgStyle: any = {
    padding: '0.5rem',
    'border-radius': '16px',
    height: '170px',
    cursor: 'pointer',
  };
  cardStyle: any = {
    'border-radius': '16px',
    'max-width': '290px',
    // cursor: 'pointer',
  };
  userDetail: any;
  constructor(
    public routes: ActivatedRoute,
    private router: Router,
    private appService: AppService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.userDetail = JSON.parse(localStorage.getItem('TG-USER-DASHBOARD')!);
    if (this.userDetail != null) {
      if (this.appService.listOfMyCourse.length == 0) {
        this.fetchAllCourse();
      } else {
        let isValid = true;
        this.appService.listOfMyCourse.forEach((course: any) => {
          if (
            course['isEnrolled'] != null &&
            course['isEnrolled'] != undefined
          ) {
            if (!course['isEnrolled']) {
              isValid = false;
            }
          } else {
            isValid = false;
          }
        });
        if (!isValid) {
          this.fetchAllCourse();
        } else {
          this.listOfMyCourse = this.appService.listOfMyCourse;
          this.listOfMyCourseToDisplay = this.listOfMyCourse;
          this.isLoading = false;
        }
      }
    } else {
      this.router.navigate(['/course'],{queryParamsHandling: 'preserve'});
    }
  }
  async fetchAllCourse() {
    let param = {
      // userManualId: this.userDetail['uid'],
    };
    try {
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.appService.userDetail['accessToken']}`,
      });
      let URL = `${webApi.domain + webApi.v2EndPoint.fetchEnrolledCourseForUserDashboard
        }`;
      await this.appService
        .postMethodWithToken(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.listOfMyCourse = data['result'];
            this.listOfMyCourse.forEach((element: any) => {
              element['isEnrolled'] = true;
            });
            //console.log(this.listOfMyCourse);
            this.listOfMyCourseToDisplay = this.listOfMyCourse;
            this.appService.listOfMyCourse = this.listOfMyCourse;
            this.isLoading = false;
          } else {
            this.listOfMyCourse = [];
            this.listOfMyCourseToDisplay = this.listOfMyCourse;
            this.isLoading = false;
          }
        });
    } catch (error) {
      this.listOfMyCourse = [];
      this.listOfMyCourseToDisplay = this.listOfMyCourse;
      this.isLoading = false;
    }
  }
  getDuration(minutes: number) {
    // let hour = 0;
    // while (minutes >= 60) {
    //   hour++;
    //   minutes = minutes - 60;
    // }
    // return `${hour}H ${minutes.toFixed(2)}M`;
    let hour = 0;
    while (minutes >= 60) {
      hour++;
      minutes = minutes - 60;
    }
    if (hour > 0) {
      return `${hour}H ${minutes.toFixed(2)} Min`;
    } else {
      return `${minutes.toFixed(2)} Min`;
    }
  }

  getCourseList(type: string) {
    let courseList: any = [];
    if (type == 'Free') {
      this.listOfMyCourse.forEach((course: any) => {
        if (course['courseType'] == 'Free') {
          courseList.push(course);
        }
      });
    } else {
      this.listOfMyCourse.forEach((course: any) => {
        if (course['courseType'] == 'Paid') {
          courseList.push(course);
        }
      });
    }
    return courseList;
  }
  gotoExploreCourse() {
    this.router.navigate(['/course'], { replaceUrl: true,queryParamsHandling: 'preserve' });
  }
  gotoCourseDetail(course: any) {
    if (course['isEnrolled']) {
      this.appService.selectedCourseToView = course;
      this.router.navigate(['/course-content'], {
        queryParams: { name: course['courseSlug'] },queryParamsHandling: 'merge'
      });
    } else {
      this.appService.selectedCourse = course;
      this.router.navigate(['/course-detail'], {
        queryParams: { name: course['courseSlug'] },queryParamsHandling: 'merge'
      });
    }
  }
  courseDetail(course: any) {
    this.appService.selectedCourse = course;
    this.router.navigate(['/course-detail'], {
      queryParams: { name: course['courseSlug'] },queryParamsHandling: 'merge'
    });
  }
}
